import React from 'react'
import Grid from '@material-ui/core/Grid'
import WelcomePostDialog from 'Event/Dashboard/components/BlogPosts/WelcomePostDialog'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {
  BlogSectionProps,
  TownhallBlogPost,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import Image from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPost/Image'
import Date from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPost/Date'
import Buttons from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPost/Buttons'
import Title from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPost/Title'
import Content from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPost/Content'
import PostForm from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPost/PostForm'

export type BlogPostProps = {
  post: TownhallBlogPost
  postId: string
  sectionId: string
}

export default function BlogPost(
  props: BlogPostProps & {
    postStyles?: BlogSectionProps['postStyles']
    postFormStyles?: BlogSectionProps['postFormStyles']
    onOpen: () => void
    textColor: string
  },
) {
  const {
    post,
    postId,
    onOpen,
    sectionId,
    postStyles,
    postFormStyles,
    textColor,
  } = props

  const itemSize = post.level === 1 && post.image ? 6 : 12

  const isEditMode = useEditMode()

  const handleOpen = () => {
    if (isEditMode) {
      return
    }

    onOpen()
  }

  return (
    <>
      <WelcomePostDialog post={post} postId={postId} />
      <Container
        background={postStyles?.background}
        borderColor={postStyles?.borderColor}
        borderWidth={postStyles?.borderWidth}
        borderRadius={postStyles?.borderRadius}
        onClick={handleOpen}
      >
        <Grid container>
          <Grid item xs={itemSize}>
            <Image post={post} />
          </Grid>
          <Grid item xs={itemSize}>
            <ContentBox>
              <Title
                post={post}
                postStyles={postStyles}
                textColor={textColor}
              />
              <Content
                post={post}
                postStyles={postStyles}
                textColor={textColor}
              />
              <Date post={post} postStyles={postStyles} textColor={textColor} />
              <PostForm
                post={post}
                postStyles={postStyles}
                postFormStyles={postFormStyles}
              />
              <Buttons post={post} postId={postId} sectionId={sectionId} />
            </ContentBox>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const Container = styled.div<{
  background?: string
  borderColor?: string
  borderRadius?: number
  borderWidth?: number
}>`
  border: solid 1px #edf1f7;
  height: 100%;
  overflow: hidden;
  background: ${(props) => props.background};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: ${(props) => `${props.borderRadius}px`};
  border-width: ${(props) => `${props.borderWidth}px`};
  border-color: ${(props) => props.borderColor};
`

const ContentBox = styled.div`
  padding: ${(props) => `${props.theme.spacing[4]} ${props.theme.spacing[4]}`};
`
