import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import {ValidationError} from 'lib/ui/api-client'
import {spacing} from 'lib/ui/theme'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {ObvioEvent} from 'Event'
import {fieldError} from 'lib/form'
import moment from 'moment'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Subheading from 'lib/ui/typography/Subheading'
import Header from './Header'
import {TextFieldProps} from '@material-ui/core/TextField'
import DescribedTextField from 'lib/ui/TextField/DescribedTextField'
import {FileSelect} from 'lib/ui/form/file'
import ImageUpload from 'lib/ui/form/ImageUpload'
import Label from 'lib/ui/form/ImageUpload/Label'
import RemoveButton from 'lib/ui/form/ImageUpload/RemoveButton'
import UploadButton from 'lib/ui/form/ImageUpload/UploadButton'
import Image from 'lib/ui/form/ImageUpload/Image'
import Cropper from 'lib/ui/form/ImageUpload/Cropper'
import TimezonePicker from 'lib/ui/TimezonePicker'
import UrlField from 'lib/ui/TextField/UrlField'
import {getSystemDomain} from 'lib/url'
import {onChangeDate} from 'lib/dom'

export type CreateEventData = Pick<
  ObvioEvent,
  | 'name'
  | 'slug'
  | 'system_domain_id'
  | 'start'
  | 'end'
  | 'num_expected_attendees'
  | 'default_attendee_password'
  | 'timezone'
>

export default function Form(props: {
  onSubmit: () => void
  formErrors: UseFormMethods['errors']
  submitting: boolean
  canSave?: boolean
  responseError: ValidationError<CreateEventData>
  slug?: string
  systemDomainId?: number
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  favicon: FileSelect
  logo: FileSelect
}) {
  const {
    submitting,
    formErrors,
    responseError,
    slug,
    onSubmit,
    register,
    control,
    systemDomainId,
  } = props
  const inThreeDays = moment().add(3, 'days').toISOString()
  const inSixDays = moment().add(6, 'days').toISOString()

  const canSave = props.canSave === undefined ? true : props.canSave

  const error = (key: keyof CreateEventData) =>
    fieldError(key, {
      form: formErrors,
      response: responseError,
    })

  const errors = {
    name: error('name'),
    slug: error('slug'),
    system_domain_id: error('system_domain_id'),
    start: error('start'),
    end: error('end'),
    numAttendees: error('num_expected_attendees'),
    attendeeDefaultPassword: error('default_attendee_password'),
  }

  return (
    <form onSubmit={onSubmit}>
      <Header disabled={submitting || !canSave} />
      <Controller
        name="name"
        defaultValue=""
        control={props.control}
        rules={{
          required: 'Name is required',
        }}
        render={({onChange, value}) => (
          <DescribedTextField
            title="Event Name*"
            aria-label="event name"
            description={'Your event name'}
            name="name"
            required
            placeholder="Name"
            fullWidth
            disabled={submitting}
            value={value}
            onChange={onChange}
            helperText={errors.name}
            error={Boolean(errors.name)}
          />
        )}
      />
      <UrlField
        title="Unique URL"
        description={slugHelperText(errors, slug, systemDomainId)}
        register={register}
        errors={errors}
        submitting={submitting}
        control={control}
        fullWidth
      />
      <DateHeaderBox>
        <Subheading>Event date/time</Subheading>
      </DateHeaderBox>
      <Controller
        name="timezone"
        control={props.control}
        defaultValue={null}
        render={({onChange, value}) => (
          <DescribedTextField
            title="Event Time Zone"
            aria-label="pick event time zone"
            placeholder="Select Event Time Zone"
            description="Select Event Time Zone"
            fullWidth
            disabled={submitting}
            textField={
              <StyledTimezonePicker
                value={value}
                aria-label="pick event time zone"
                onChange={onChange}
                disabled={submitting}
              />
            }
          />
        )}
      />
      <Controller
        name="start"
        control={props.control}
        defaultValue={inThreeDays}
        rules={{
          required: 'Start is required',
        }}
        render={({onChange, value}) => (
          <LocalizedDateTimePicker
            disabled={submitting}
            value={value}
            labelFunc={formatDate}
            onChange={onChangeDate(onChange)}
            fullWidth
            label="Start"
            TextFieldComponent={(props: TextFieldProps) => (
              <DescribedTextField
                aria-label="start"
                description="Enter start date/time of event"
                name="start"
                placeholder="Start date/time"
                fullWidth
                disabled={submitting}
                onChange={onChange}
                value={props.value as string}
                onClick={props.onClick}
              />
            )}
          />
        )}
      />
      <Controller
        name="end"
        control={props.control}
        defaultValue={inSixDays}
        rules={{
          required: 'End is required',
        }}
        render={({onChange, value}) => (
          <LocalizedDateTimePicker
            disabled={submitting}
            value={value}
            labelFunc={formatDate}
            onChange={onChangeDate(onChange)}
            fullWidth
            label="End"
            TextFieldComponent={(props: TextFieldProps) => (
              <DescribedTextField
                aria-label="end"
                description="Enter end date/time of event"
                name="end"
                placeholder="End date/time"
                fullWidth
                disabled={submitting}
                onChange={onChange}
                value={props.value as string}
                onClick={props.onClick}
              />
            )}
          />
        )}
      />
      <Controller
        name="num_expected_attendees"
        defaultValue=""
        control={props.control}
        rules={{
          required: 'Expected Number of Attendees is required',
        }}
        render={({onChange, value}) => (
          <DescribedTextField
            title="Expected Number of Attendees"
            description="Expected Number of Attendees"
            aria-label="expected number of attendees"
            error={Boolean(errors.numAttendees)}
            helperText={errors.numAttendees}
            type="number"
            name="num_expected_attendees"
            placeholder="Number of attendees"
            fullWidth
            disabled={submitting}
            value={value}
            onChange={onChange}
            required
          />
        )}
      />
      <Controller
        name="default_attendee_password"
        defaultValue=""
        control={props.control}
        render={({onChange, value}) => (
          <DescribedTextField
            title="Attendee default password"
            aria-label="attendee default password"
            description={'Attendee default password'}
            name="default_attendee_password"
            placeholder="Attendee default password"
            fullWidth
            disabled={submitting}
            value={value}
            onChange={onChange}
            helperText={errors.attendeeDefaultPassword}
            error={Boolean(errors.attendeeDefaultPassword)}
          />
        )}
      />
      <Box mb={2}>
        <ImageUpload file={props.favicon} disabled={submitting}>
          <Cropper width={32} height={32} />
          <Label>Favicon</Label>
          <Image alt="favicon" width={32} />
          <UploadButton
            inputProps={{
              'aria-label': 'favicon input',
            }}
          />
          <RemoveButton aria-label="remove favicon" />
        </ImageUpload>
      </Box>
      <Box mb={2}>
        <ImageUpload file={props.logo} disabled={submitting}>
          <Label>Logo</Label>
          <Image alt="logo" width={80} />
          <UploadButton
            inputProps={{
              'aria-label': 'logo input',
            }}
          />
          <RemoveButton aria-label="remove logo" />
        </ImageUpload>
      </Box>
      <ErrorMessage>{responseError && responseError.message}</ErrorMessage>
    </form>
  )
}

function ErrorMessage(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Typography)

export function slugHelperText(
  errors: Record<string, any>,
  slug?: string,
  systemDomainId?: number,
) {
  if (errors.slug) {
    return errors.slug
  }

  const systemDomain = getSystemDomain({system_domain_id: systemDomainId ?? 0})

  if (!slug || !systemDomain) {
    return 'Your event slug will be a part of your domain'
  }

  return (
    <span>
      Your event will be accessible at{' '}
      <EventSlugText>
        {slug}.{systemDomain}
      </EventSlugText>
    </span>
  )
}

export const formatDate = (date: MaterialUiPickersDate) =>
  date?.format('MMMM Do YYYY hh:mm a') ?? ''

const DateHeaderBox = styled(Box)`
  margin-top: ${(props) => props.theme.spacing[10]};
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
  }
`

const EventSlugText = styled.strong`
  color: ${(props) => props.theme.colors.black};
`

const StyledTimezonePicker = styled(TimezonePicker)`
  width: 100%;
  max-width: 640px;
`
