import {useEvent} from 'Event/EventProvider'
import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useQuery} from 'react-query'

export function useListCustomPurchasePages() {
  const {event} = useEvent()

  return useQuery({
    queryKey: ['events', event.id, 'custom_purchase_pages'],
    queryFn: () =>
      client.get<CustomPurchasePage[]>(
        marketplaceApi(`/events/${event.id}/purchase_pages?custom_only=true`),
      ),
  })
}
