import React, {useCallback, useEffect, useState} from 'react'
import {browserTimezone} from 'lib/date-time'
import {api} from 'lib/url'
import {ProgramAtAGlance, Session} from 'organization/Obie/PagBuilder/types'
import {useOrganization} from 'organization/OrganizationProvider'
import {useObieService} from 'organization/Obie/ObieServiceProvider'

interface PagProviderProps {
  children: React.ReactElement
}

interface PagContextProps {
  loading: boolean
  addSession: (dayIndex: number, session: Session) => void
  dayIndex: number
  categoryId: number
  deletePagBuilder: (pagId: number) => Promise<void>
  deleteSession: (target: Session) => Promise<void>
  moveSession: (params: {
    sourceDayIndex: number
    sourceSessionIndex: number
    destinationDayIndex: number
    destinationSessionIndex: number
  }) => void
  name: string
  setName: (name: string) => void
  pagId: number
  sessionIndex: number
  sessions: Session[][]
  setDayIndex: (dayIndex: number) => void
  setPagId: (pagId: number) => void
  setSessionIndex: (sessionIndex: number) => void
  setSessions: (sessions: Session[][]) => void
  updatePagBuilder: (
    pagId: number,
    name: string,
    sessions?: Session[][],
  ) => Promise<ProgramAtAGlance | void>
  updateSession: (
    sessionId: number,
    type: string,
    duration: number,
    name?: string,
    startTime?: string,
    overview?: string,
    details?: string,
  ) => Promise<Session>
}

export const PagContext = React.createContext<PagContextProps | undefined>(
  undefined,
)

export default function PagProvider(props: PagProviderProps) {
  const {fetchBlocks, profileId} = useObieService()

  const fetcherPagBuilder = usePagBuilderFetch()
  const deleterPagBuilder = usePagBuilderDelete()
  const updaterPagBuilder = usePagBuilderUpdate()
  const createrPagBuilderSession = usePagBuilderSessionCreate()
  const deleterPagBuilderSession = usePagBuilderSessionDelete()
  const updaterPagBuilderSession = usePagBuilderSessionUpdate()

  const [dayIndex, setDayIndex] = useState<number>(0)
  const [name, setName] = useState<string>('')
  const [pagId, setPagId] = useState<number>(0)
  const [categoryId, setCategoryId] = useState<number>(0)
  const [sessionIndex, setSessionIndex] = useState<number>(0)
  const [sessions, setSessions] = useState<Session[][]>([])
  const [loading, setLoading] = useState(true)

  const fetchPagBuilder = useCallback(
    (pagId: number, profileId: number | undefined) => {
      if (profileId === undefined) {
        console.error('fetchPagBuider:: invalid profileId')

        return new Promise<void>((resolve) => resolve())
      }

      return fetcherPagBuilder(pagId, profileId).then((response) => {
        setName(response.name)
        setCategoryId(response.answer_set.block.block_category_id)
        setSessions(response.sessions)

        return response
      })
    },
    [fetcherPagBuilder],
  )

  const deletePagBuilder = useCallback(
    (pagId: number) => {
      if (profileId === undefined) {
        console.error('deletePagBuider : invalid profileId')

        return new Promise<void>((resolve) => resolve())
      }

      return deleterPagBuilder(pagId, profileId).then((response) => {
        fetchBlocks(categoryId, profileId)

        return response
      })
    },
    [categoryId, deleterPagBuilder, fetchBlocks, profileId],
  )

  const updatePagBuilder = useCallback(
    (pagId: number, name: string, sessions?: Session[][]) => {
      if (profileId === undefined) {
        console.error('updatePagBuider : invalid profileId')

        return new Promise<void>((resolve) => resolve())
      }

      return updaterPagBuilder(pagId, profileId, name, sessions).then(
        (response) => {
          fetchBlocks(categoryId, profileId)

          return response
        },
      )
    },
    [categoryId, fetchBlocks, profileId, updaterPagBuilder],
  )

  const addSession = (dayIndex: number, session: Session) => {
    return createrPagBuilderSession(pagId, dayIndex, session).then(() => {
      fetchPagBuilder(pagId, profileId)
    })
  }

  const deleteSession = (target: Session) => {
    return deleterPagBuilderSession(pagId, target.id).then(() => {
      fetchPagBuilder(pagId, profileId)
    })
  }

  const updateSession = (
    sessionId: number,
    type: string,
    duration: number,
    name?: string,
    startTime?: string,
    overview?: string,
    details?: string,
  ) => {
    const data = {
      name,
      start_time: startTime,
      type,
      duration,
      overview,
      details,
      browserTimezone: browserTimezone(),
    }

    return updaterPagBuilderSession(pagId, sessionId, data)
  }

  const moveSession = (params: {
    sourceDayIndex: number
    sourceSessionIndex: number
    destinationDayIndex: number
    destinationSessionIndex: number
  }) => {
    const updated = [...sessions]
    const sourceDay = [...updated[params.sourceDayIndex]]
    const [removed] = sourceDay.splice(params.sourceSessionIndex, 1)
    updated[params.sourceDayIndex] = sourceDay

    const destinationDay = [...updated[params.destinationDayIndex]]

    // If moving to first position, copy start time from the session being pushed
    // back.
    if (params.destinationSessionIndex === 0 && destinationDay.length > 0) {
      removed.startTime = destinationDay[0].startTime
    }

    destinationDay.splice(params.destinationSessionIndex, 0, removed)
    updated[params.destinationDayIndex] = destinationDay

    // Updating state so the new order is maintained will the request to the
    // backend is made - which then updates the state with what is in the
    // database, including re-calculated start times.
    setSessions(updated)

    updatePagBuilder(pagId, name, updated).then((response) => {
      if (!response) {
        return
      }

      setSessions(response.sessions || [])
    })
  }

  useEffect(() => {
    if (!pagId || profileId === undefined) {
      return
    }

    fetchPagBuilder(pagId, profileId).finally(() => setLoading(false))
  }, [pagId, profileId, fetchPagBuilder, setSessions])

  return (
    <PagContext.Provider
      value={{
        addSession,
        categoryId,
        dayIndex,
        deleteSession,
        deletePagBuilder,
        moveSession,
        name,
        setName,
        pagId,
        sessionIndex,
        sessions,
        setDayIndex,
        setPagId,
        setSessionIndex,
        setSessions,
        updatePagBuilder,
        updateSession,
        loading,
      }}
    >
      {props.children}
    </PagContext.Provider>
  )
}

export function usePag() {
  const context = React.useContext(PagContext)
  if (context === undefined) {
    throw new Error('usePag must be used within a PagProvider')
  }
  return context
}

export function usePagBuilderFetch() {
  const {client, organization} = useOrganization()

  return useCallback(
    (pagId: number, profileId: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/program-at-a-glances/${pagId}?profile_id=${profileId}`,
      )

      return client.get<ProgramAtAGlance>(url)
    },
    [client, organization.id],
  )
}

export function usePagBuilderDelete() {
  const {client, organization} = useOrganization()

  return useCallback(
    (pagId: number, profileId: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/program-at-a-glances/${pagId}?profile_id=${profileId}`,
      )

      return client.delete<void>(url)
    },
    [client, organization.id],
  )
}

export function usePagBuilderUpdate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (
      pagId: number,
      profileId: number,
      name: string,
      sessions?: Session[][],
    ) => {
      const url = api(
        `/organizations/${organization.id}/obie/program-at-a-glances/${pagId}?profile_id=${profileId}`,
      )

      return client.put<ProgramAtAGlance>(url, {
        name: name,
        sessions: sessions,
      })
    },
    [client, organization.id],
  )
}

export function usePagBuilderSessionCreate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (pagId: number, dayIndex: number, session: Session) => {
      const url = api(
        `/organizations/${organization.id}/obie/program-at-a-glances/${pagId}/sessions`,
      )

      const data = {
        ...session,
        dayIndex,
        browserTimezone: browserTimezone(),
      }

      return client.post<Session>(url, data)
    },
    [client, organization.id],
  )
}

export function usePagBuilderSessionDelete() {
  const {client, organization} = useOrganization()

  return useCallback(
    (pagId: number, sessionId: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/program-at-a-glances/${pagId}/sessions/${sessionId}`,
      )

      return client.delete<void>(url)
    },
    [client, organization.id],
  )
}

export function usePagBuilderSessionUpdate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (pagId: number, sessionId: number, data: {}) => {
      const url = api(
        `/organizations/${organization.id}/obie/program-at-a-glances/${pagId}/sessions/${sessionId}`,
      )

      return client.put<Session>(url, data)
    },
    [client, organization.id],
  )
}
