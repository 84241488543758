import ImageConfig from 'organization/Marketplace/config/BlockConfig/ImageConfig'
import TitleConfig from 'organization/Marketplace/config/BlockConfig/TitleConfig'
import TextConfig from 'organization/Marketplace/config/BlockConfig/TextConfig'
import TicketSelectorConfig from 'organization/Marketplace/config/BlockConfig/TicketSelectorConfig'
import React from 'react'
import VideoConfig from 'organization/Marketplace/config/BlockConfig/VideoConfig'
import PurchaseFormConfig from 'organization/Marketplace/config/BlockConfig/PurchaseFormConfig'
import SeparatorConfig from 'organization/Marketplace/config/BlockConfig/SeparatorConfig'
import CountdownTimerConfig from 'organization/Marketplace/config/BlockConfig/CountdownTimerConfig'
import BulletedListConfig from 'organization/Marketplace/config/BlockConfig/BulletedListConfig'
import NumberedListConfig from 'organization/Marketplace/config/BlockConfig/NumberedListConfig'
import IconConfig from 'organization/Marketplace/config/BlockConfig/IconConfig'
import FaqListConfig from 'organization/Marketplace/config/BlockConfig/FaqListConfig'
import SpacerConfig from 'organization/Marketplace/config/BlockConfig/SpacerConfig'
import PurchasePageButtonConfig from 'organization/Marketplace/config/BlockConfig/PurchasePageButtonConfig'
import UpsellPurchaseButtonConfig from 'organization/Marketplace/config/BlockConfig/UpsellPurchaseButtonConfig'
import UpsellDeclineButtonConfig from 'organization/Marketplace/config/BlockConfig/UpsellDeclineButtonConfig'
import UpsellDeclineTextConfig from 'organization/Marketplace/config/BlockConfig/UpsellDeclineTextConfig'
import {NLPurchasePageBlock} from 'Event/Marketplace/nl-purchase-page'
import NLSectionConfig from '../NLSectionConfig'
import {DeepPartialSubstitute} from 'lib/type-utils'
import {REMOVE} from 'lib/JsonUpdateProvider'

export interface TargetBlockConfigBaseProps {
  id: string
  onSave: (
    data:
      | DeepPartialSubstitute<NLPurchasePageBlock, typeof REMOVE>
      | typeof REMOVE,
  ) => void
}

interface BlockConfigProps extends TargetBlockConfigBaseProps {
  block: NLPurchasePageBlock
  ButtonConfig: React.FC<any>
}

export default function NLBlockConfig(props: BlockConfigProps) {
  const {block, id, ButtonConfig, onSave} = props

  if (block.type === 'Button') {
    return <ButtonConfig {...(block as any)} id={id} />
  }

  const ConfigComponent = ComponentConfigs[block.type] ?? null

  if (!ConfigComponent) {
    return null
  }

  return <ConfigComponent {...(block as any)} id={id} onSave={onSave} />
}

export type ConfigDefinitions = {
  [T in NLPurchasePageBlock as T['type']]: React.FC<
    T & {
      id: string
      sectionId: string
      onSave: (
        data:
          | DeepPartialSubstitute<NLPurchasePageBlock, typeof REMOVE>
          | typeof REMOVE,
      ) => void
    }
  >
}

export const ComponentConfigs: ConfigDefinitions = {
  Title: TitleConfig,
  Text: TextConfig,
  Image: ImageConfig,
  TicketSelector: TicketSelectorConfig,
  Button: PurchasePageButtonConfig,
  Video: VideoConfig,
  PurchaseForm: PurchaseFormConfig,
  Separator: SeparatorConfig,
  BulletedList: BulletedListConfig,
  CountdownTimer: CountdownTimerConfig,
  NumberedList: NumberedListConfig,
  Icon: IconConfig,
  FaqList: FaqListConfig,
  Spacer: SpacerConfig,
  UpsellPurchaseButton: UpsellPurchaseButtonConfig,
  UpsellDeclineButton: UpsellDeclineButtonConfig,
  UpsellDeclineText: UpsellDeclineTextConfig,
  Section: NLSectionConfig,
}
