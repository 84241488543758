import {MarketplaceTicket} from './lib/marketplace-ticket'
import React from 'react'

export interface PurchaseData {
  ticket_id: number
  customer_id?: string | null
  first_name: string
  last_name: string
  email: string
  phone_number: string
  billing_address?: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  shipping_address?: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  payment_method_id: string | null
}

export interface PurchaseFormData {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  billing_address?: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  shipping_address?: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  payment_method_id: string | null
}

export interface PurchaseContextProps {
  selectedTicket: MarketplaceTicket | null
  setSelectedTicket: (ticket: MarketplaceTicket | null) => void
  tickets: MarketplaceTicket[]
  isProcessing: boolean
  submit: (data?: PurchaseFormData) => void
  upsellDecline: () => void
  isShowingUpsell: boolean
  isShowingThankYou: boolean
  hasSavedCard: boolean
  stripeAccountId: string | null
  stripePublicKey: string
  error: string | null
  shouldValidateEmail: boolean
  eventId: number
  mainPurchaseData: PurchaseFormData | null
  loginUrl?: string
  orderTotal?: number
}

export const PurchaseContext = React.createContext<
  PurchaseContextProps | undefined
>(undefined)

export function usePurchase() {
  const context = React.useContext(PurchaseContext)
  if (context === undefined) {
    throw new Error('usePurchase must be used within a PurchaseProvider')
  }

  return context
}
