import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {useLocalTime} from 'lib/date-time'
import {MoreActionsMenu} from 'lib/ui/Menu'
import {colors} from 'lib/ui/theme'
import {TableHeader} from 'lib/ui/typography'
import {
  Broadcast,
  Segment,
  useBroadcasts,
} from 'organization/Event/Broadcasts/BroadcastsProvider'
import DeleteMenuItem from 'organization/Event/Broadcasts/BroadcastsTable/DeleteMenuItem'
import EditMenuItem from 'organization/Event/Broadcasts/BroadcastsTable/EditMenuItem'
import React, {useState} from 'react'
import styled from 'styled-components'
import DuplicateMenuItem from 'organization/Event/Broadcasts/BroadcastsTable/DuplicateMenuItem'
import TestMenuItem from 'organization/Event/Broadcasts/BroadcastsTable/TestMenuItem'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'
import {communicationsApi} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import {
  BroadcastEmailStatistic,
  StatisticsForEmailBroadcast,
} from 'organization/Event/Broadcasts/StatisticsForEmailBroadcast'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import PollOutlinedIcon from '@material-ui/icons/PollOutlined'

export default function BroadcastsTable() {
  const {broadcasts} = useBroadcasts()
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const localTime = useLocalTime()

  const hasBroadcasts = broadcasts.length > 0
  if (!hasBroadcasts) {
    return <p>No broadcasts have been added.</p>
  }

  return (
    <>
      <SuccessAlert onClose={() => setSuccessMessage(null)}>
        {successMessage}
      </SuccessAlert>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableHeader>Date</TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>Name</TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>Channel</TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>Segments</TableHeader>
            </TableCell>
            <CenteredTableCell>
              <TableHeader>Send Status</TableHeader>
            </CenteredTableCell>
            <CenteredTableCell>
              <TableHeader>Published</TableHeader>
            </CenteredTableCell>
            <TableCell>{/* Action Column */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {broadcasts.map((b) => (
            <TableRow key={b.id}>
              <DateTableCell>{localTime(b.send_at)}</DateTableCell>
              <TableCell>{b.name}</TableCell>
              <ChannelTableCell>{b.channel?.name || 'N/A'}</ChannelTableCell>
              <SegmentTableCell>
                <Segments broadcast={b} />
              </SegmentTableCell>
              <CenteredTableCell>
                <SendingStatus broadcast={b} />
              </CenteredTableCell>
              <CenteredTableCell>
                <PublishedStatus broadcast={b} />
              </CenteredTableCell>
              <ActionTableCell align="right">
                <BroadcastStatistics broadcast={b} />
                <BroadcastActions broadcast={b} onSuccess={setSuccessMessage} />
              </ActionTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

const DateTableCell = styled(TableCell)`
  width: 14%;
`
const ChannelTableCell = styled(TableCell)`
  width: 12%;
`
const SegmentTableCell = styled(TableCell)`
  width: 12%;
`
const CenteredTableCell = styled(TableCell)`
  text-align: center;
  width: 9%;
`
const ActionTableCell = styled(TableCell)`
  width: 15%;
`

function Segments(props: {broadcast: Broadcast}) {
  const {broadcast} = props
  const segments = [broadcast.attendee_segment] as Segment[]

  return (
    <>
      {segments.map((s) => (
        <div key={s}>{segmentLabel(s)}</div>
      ))}
    </>
  )
}

function BroadcastStatistics(props: {broadcast: Broadcast}) {
  const {broadcast} = props

  if (broadcast.channel.name === 'sms') {
    return null
  }

  if (broadcast.channel.name === 'email') {
    return <EmailStatitics broadcast={broadcast} />
  }

  return null
}

function EmailStatitics(props: {broadcast: Broadcast}) {
  const {broadcast} = props
  const {client} = useEvent()
  const [
    emailStatistics,
    setEmailStatistics,
  ] = useState<BroadcastEmailStatistic | null>(null)

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const DEFAULT_EMAIL_STATISTICS: BroadcastEmailStatistic = {
    num_sent: 0,
    num_opened: 0,
    num_clicked: 0,
    num_complaints: 0,
    num_bounced: 0,
  }

  const open = (event: React.MouseEvent) => {
    setEmailStatistics(null)
    loadStatistics()
    setAnchorEl(event.currentTarget)
  }

  const loadStatistics = () => {
    const url = communicationsApi(`/broadcasts/${broadcast.id}/statistics`)

    client
      .get<{
        email: BroadcastEmailStatistic
      }>(url)
      .then(({email}) => {
        setEmailStatistics(email || DEFAULT_EMAIL_STATISTICS)
      })
  }

  const close = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)
  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-label="show statistics"
        onClick={open}
      >
        <PollOutlinedIcon />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StatisticsForEmailBroadcast statistics={emailStatistics} />
      </Popover>
    </>
  )
}

function segmentLabel(segment: Segment) {
  switch (segment) {
    case 'all':
      return 'All Attendees'
    case 'checked_in':
      return 'Checked In'
    case 'not_checked_in':
      return 'Not Checked In'
    case 'filtered':
      return 'Filtered'
    default:
      throw new Error(`Missing segment label for segment: ${segment}`)
  }
}

function BroadcastActions(props: {
  broadcast: Broadcast
  onSuccess: (message: string) => void
}) {
  const {broadcast, onSuccess} = props

  return (
    <MoreActionsMenu>
      <TestMenuItem broadcast={broadcast} onSuccess={onSuccess} />
      <EditMenuItem broadcast={broadcast} />
      <DuplicateMenuItem broadcast={broadcast} />
      <DeleteMenuItem broadcast={broadcast} />
    </MoreActionsMenu>
  )
}

function SendingStatus(props: {broadcast: Broadcast}) {
  const {broadcast} = props
  const iconClass = broadcast.sent ? 'fas' : 'fal'
  const title = broadcast.sent ? 'has completed' : 'is pending'

  return (
    <StyledSendingIcon
      aria-label={`broadcast send status ${
        broadcast.sent ? 'sent' : 'pending'
      }`}
      className={`${iconClass} fa-circle`}
      title={`Broadcast send ${title}`}
    />
  )
}

function PublishedStatus(props: {broadcast: Broadcast}) {
  const {broadcast} = props
  const iconClass = broadcast.is_draft ? 'fal' : 'fas'
  const title = broadcast.is_draft ? 'draft' : 'published'

  return (
    <StyledSendingIcon
      aria-label={`broadcast status ${
        broadcast.is_draft ? 'draft' : 'published'
      }`}
      className={`${iconClass} fa-circle`}
      title={`Broadcast is ${title}`}
    />
  )
}

const StyledSendingIcon = styled.i`
  color: ${colors.primary};
`
