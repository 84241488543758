import React from 'react'
import EditButton from 'lib/ui/Button/CustomButton'
import {MenuIcon} from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import Menu from 'lib/ui/Menu'
import MenuItem from 'lib/ui/Menu/MenuItem'
import {colors} from 'lib/ui/theme'
import {routesWithValue} from 'lib/url'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {
  TableCell,
  TableRow,
  MenuTableCell,
} from 'organization/Marketplace/Tickets/TicketListing'
import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'
import {useDeleteCustomPurchasePage} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-delete-custom-purchase-page'
import {Divider} from '@material-ui/core'
import {useHistory} from 'react-router'

export default function CustomPurchasePageItem(props: {
  index: number
  customPurchasePage: CustomPurchasePage
}) {
  const {index, customPurchasePage} = props
  const eventRoutes = useEventRoutes()
  const history = useHistory()

  const {
    mutate: deleteCustomPurchasePage,
    isLoading: isDeletingCustomPurchasePage,
  } = useDeleteCustomPurchasePage({
    customPurchasePage,
  })

  const isProcessing = isDeletingCustomPurchasePage

  const CustomPurchasePageRoute = (customPurchasePage: CustomPurchasePage) =>
    routesWithValue(
      ':custom_purchase_pages',
      String(customPurchasePage.id),
      eventRoutes.marketplace.custom_purchase_pages[':custom_purchase_pages'],
    )

  const goEdit = () => {
    history.push(CustomPurchasePageRoute(customPurchasePage).root)
  }

  const goEditTemplate = () => {
    history.push(CustomPurchasePageRoute(customPurchasePage).edit_template)
  }

  return (
    <TableRow key={index} aria-label="custom_purchase_pages-item">
      <TableCell>
        <RelativeLink
          to={CustomPurchasePageRoute(customPurchasePage).edit_template}
        >
          <EditButton
            variant="text"
            textColor={colors.primary}
            aria-label="edit"
          >
            {customPurchasePage.name}
          </EditButton>
        </RelativeLink>
      </TableCell>
      <TableCell>{customPurchasePage.slug}</TableCell>
      <MenuTableCell>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          button={({open}) => (
            <IconButton
              onClick={open}
              aria-label="view custom_purchase_pages actions"
            >
              <MenuIcon iconSize={18} />
            </IconButton>
          )}
          disabled={isProcessing}
        >
          <MenuItem children="Edit Template" onClick={goEditTemplate} />
          <Divider />
          <MenuItem children="Edit" onClick={goEdit} />
          <Divider />
          <MenuItem
            children="Delete"
            color="danger"
            onClick={deleteCustomPurchasePage}
          />
        </Menu>
      </MenuTableCell>
    </TableRow>
  )
}
