import {createBaseTemplate} from 'Event/template/base'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {BaseTemplate, Details} from 'Event/template'
import {DeepRequired} from 'lib/type-utils'
import {Emoji} from 'Event/Dashboard/components/EmojiList/emoji'
import {createNavButtonWithSize} from 'Event/Dashboard/components/NavButton/create'
import {InputStyleProps} from 'Event/auth/TextField'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {Font} from 'lib/FontSelect'
import {HashMap, Ordered} from 'lib/list'
import {TownhallSpeaker} from 'Event/template/Townhall/Speakers'
import {TownhallSponsor} from 'Event/template/Townhall/Sponsors/SponsorList'
import {DashboardSectionProps} from 'Event/template/Townhall/Dashboard/Main/DashboardSection'
import {BackgroundPosition} from 'lib/ui/BackgroundPositionSelector'
import {colors} from 'lib/ui/theme'
import {ENGLISH} from 'Event/LanguageProvider/language'
export {convert} from 'Event/template/Townhall/converter'

/**
 * Template Name (Required)
 * --------------------------------------------------
 * Must be unique, and match the template directory
 * for dynamic imports to work.
 */
export const TOWNHALL = 'Townhall'

/**
 * Template Details (Required)
 * --------------------------------------------------
 * App-level details shared by every template instance.
 */
export const details: Details = {
  label: 'Town Hall',
  previewUrl:
    'https://townhall-demo.obv.io/login?token=06f4cf31a75fcfafd7d1b3e1a8580cf8',
}

export function useTownhallTemplate() {
  const template = useTemplate()

  if (template.name !== TOWNHALL) {
    throw new Error('useTownhallTemplate called with wrong template')
  }

  return template
}

export function useTownhallUpdate() {
  return useSaveTemplate<Townhall>()
}

export type Townhall = BaseTemplate & {
  name: typeof TOWNHALL
  title: string
  mainNav: {
    buttons: HashMap<NavButtonWithSize>
  }
  actionNav: {
    buttons: HashMap<NavButtonWithSize>
    separator: {
      color: string
      width: number
    }
    padding: {
      top: number
      right: number
      bottom: number
      left: number
    }
    backgroundColor: string
    backgroundOpacity: number
    width: number
    border: {
      color: string
      width: number
      radius: number
    }
    enabled: boolean
    align: 'flex-start' | 'center' | 'flex-end'
    position: 'left' | 'right'
    buttonHeight: number
    vertical_alignment: 'start' | 'center' | 'end'
  }
  hero: {
    body: string
    alignment: 'left' | 'center' | 'right'
    vertical_alignment: 'start' | 'center' | 'end'
    image: string | null
    imageSize: number
  }
  checkIn: {
    title: string
    titleColor: string
    stepLabelColor: string
    stepIconColor: string
    inActiveColor: string
    step1Label: string
    step1Icon: string
    step2Label: string
    step2Icon: string
    step3Label: string
    step3Icon: string
    contentPanel: {
      backgroundColor: string
      backgroundOpacity: number
      textColor: string | null
    }
    page: {
      backgroundImage: string | null
      backgroundColor: string
      backgroundOpacity: number
    }
  }
  pageBackground: {
    image: string | null
    color: string
    opacity: number
  }
  pageBackgroundPosition: 'fixed' | 'bottom'
  emojiList: {
    emojis: Emoji['name'][]
    emojiWidth?: number | null
  }
  leaderboard?: {
    title?: string
    description?: string
    isVisible?: boolean
    backToDashboardText?: string
    backToDashboardTextColor?: string
  }
  setPasswordForm: {
    title: string
    description: string
    passwordLabel: string
    confirmPasswordLabel: string
    button: NavButtonWithSize
    input: InputStyleProps
  }

  faq?: {
    secondaryTitle?: string
    backgroundColor?: string
    titleColor?: string
    questionColor?: string
  }
  speakers?: {
    secondaryTitle?: string
    spacing?: number
    showCarousel?: boolean
    otherSpeakersTitle?: string
    carouselArrowColor?: string
    alignment?: 'left' | 'center' | 'right'
    items: HashMap<TownhallSpeaker>
    menuTitle?: string
  }
  sponsors?: {
    spacing?: number
    hasDivider?: boolean
    items: HashMap<TownhallSponsor>
    background: {
      color: string
      opacity: number
    }
    text: {
      color: string | null
    }
    border: {
      color: string
      width: number
      radius: number
    }
    separator: {
      color: string
      width: number
    }
    secondaryTitle?: string
    menuTitle?: string
  }
  textColor?: string
  linkColor?: string
  linkUnderline?: boolean
  featuredSpeakers: {
    items: HashMap<TownhallSpeaker>
  }
  header: {
    backgroundColor: string
    backgroundPosition: BackgroundPosition
    backgroundOpacity: number
    backgroundImage: string | null
    minHeight: number
  }
  logoSize?: number
  login: {
    welcome: {
      text: string
      color: string | null
      fontSize: number
      font: Font | null
    }
    passwordReset: {
      forgotPasswordTitle: string
    }
    leftPanel: {
      backgroundOpacity: number
      backgroundColor: string
    }
    rightPanel: {
      backgroundOpacity: number
      backgroundColor: string
    }
  }
  zoomBackgrounds?: {
    menuTitle?: string
    secondaryTitle?: string
  }
  dashboardSections: HashMap<DashboardSectionProps>
  navBar: {
    height: number
    color: string
    backgroundColor: string
    indicatorColor: string
    font: Font | null
    fontSize: number
    mainMenuItemTitle: string
    menuItems: HashMap<NavMenuItemProps>
  }
  topBar: {
    text: string
    logoSpacing: number
    color: string | null
    font: Font | null
    fontSize: number
    backgroundColor: string
    backgroundOpacity: number
    padding: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  footer: {
    image: string | null
    background: string
    textColor: string | null
    termsLink: string | null
    privacyLink: string | null
    copyrightText: string | null
    imageSize?: number
  }
}

export const createBlank = (): DeepRequired<Townhall> => ({
  ...createBaseTemplate(),
  version: 1,
  name: TOWNHALL,
  title: '',
  hero: {
    body: '{{hero_body}}',
    alignment: 'left',
    vertical_alignment: 'center',
    image: null,
    imageSize: 100,
  },
  logoSize: 100,
  header: {
    backgroundPosition: 'center center',
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 1.0,
    backgroundImage: null,
    minHeight: 300,
  },
  mainNav: {
    buttons: {},
  },
  actionNav: {
    buttons: {},
    separator: {
      color: '#ffffff',
      width: 1,
    },
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    backgroundColor: '#131D34',
    backgroundOpacity: 100,
    width: 70,
    border: {
      color: '#ffffff',
      width: 1,
      radius: 10,
    },
    enabled: true,
    position: 'right',
    align: 'center',
    buttonHeight: 60,
    vertical_alignment: 'center',
  },
  checkIn: {
    title: '{{check_in_title}}',
    titleColor: '#000000',
    stepLabelColor: '#07BAB5',
    stepIconColor: '#07BAB5',
    inActiveColor: '#C7C7C7',
    step1Label: '{{check_in_step_1_label}}',
    step1Icon: 'far fa-lock',
    step2Label: '{{check_in_step_2_label}}',
    step2Icon: 'far fa-clipboard',
    step3Label: '{{check_in_step_3_label}}',
    step3Icon: 'far fa-desktop',
    contentPanel: {
      backgroundColor: '#C7C7C7',
      textColor: null,
      backgroundOpacity: 0,
    },
    page: {
      backgroundImage: null,
      backgroundColor: '#FFFFFF',
      backgroundOpacity: 100,
    },
  },
  pageBackground: {
    image: null,
    color: '#FFFFFF',
    opacity: 0,
  },
  pageBackgroundPosition: 'fixed',
  leaderboard: {
    title: '{{leaderboard_title}}',
    description: '{{leaderboard_description}}',
    isVisible: false,
    backToDashboardText: '{{leaderboard_back_to_dashboard_text}}',
    backToDashboardTextColor: '#000000',
  },
  emojiList: {
    emojis: [],
    emojiWidth: 0,
  },
  setPasswordForm: {
    title: '{{set_password_title}}',
    description: '{{set_password_description}}',
    passwordLabel: '{{set_password_password_label}}',
    confirmPasswordLabel: '{{set_password_confirm_password_label}}',
    button: createNavButtonWithSize({text: 'Submit'}),
    input: {
      labelColor: '#3490DC',
      textColor: '#000000',
      borderColor: '#3490DC',
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
  },
  faq: {
    title: '{{faq_title}}',
    description: '{{faq_description}}',
    // backToDashboardText: 'Back to Dashboard',
    // backToDashboardTextColor: '#000000',
    isEnabled: false,
    items: {},
    secondaryTitle: '{{faq_secondary_title}}',
    backgroundColor: colors.gray,
    titleColor: '',
    questionColor: '',
  },
  speakers: {
    ...createBaseTemplate().speakers,
    title: '{{speakers_title}}',
    secondaryTitle: '{{speakers_secondary_title}}',
    items: {},
    // backToDashboardText: 'Back to Dashboard',
    // backToDashboardTextColor: '#000000',
    spacing: 0,
    showCarousel: false,
    otherSpeakersTitle: '{{speakers_other_speakers_title}}',
    carouselArrowColor: '#3490DC',
    alignment: 'left',
    menuTitle: '{{speakers_menu_title}}',
    description: '{{speakers_description}}',
  },
  textColor: '#000000',
  linkColor: '#ffffff',
  linkUnderline: true,
  sponsors: {
    ...createBaseTemplate().sponsors,
    spacing: 0,
    hasDivider: true,
    items: {},
    background: {
      color: '#FFFFFF',
      opacity: 0,
    },
    text: {
      color: null,
    },
    border: {
      color: '#edf1f7',
      width: 1,
      radius: 4,
    },
    separator: {
      color: '#edf1f7',
      width: 1,
    },
    title: '{{sponsors_title}}',
    description: '{{sponsors_description}}',
    secondaryTitle: '{{sponsors_secondary_title}}',
    menuTitle: '{{sponsors_menu_title}}',
  },
  featuredSpeakers: {
    items: {},
  },
  login: {
    ...createBaseTemplate().login,
    backgroundColor: '#131E34',
    backgroundOpacity: 0,
    welcome: {
      text: '{{login_welcome_text}}',
      color: null,
      fontSize: 24,
      font: null,
    },
    passwordReset: {
      ...createBaseTemplate().login.passwordReset,
      forgotPasswordTitle: '{{login_password_reset_forgot_password_title}}',
    },
    leftPanel: {
      backgroundOpacity: 100,
      backgroundColor: '#FFFFFF',
    },
    rightPanel: {
      backgroundOpacity: 100,
      backgroundColor: '#FFFFFF',
    },
  },
  zoomBackgrounds: {
    ...createBaseTemplate().zoomBackgrounds,
    menuTitle: '{{zoom_backgrounds_menu_title}}',
    secondaryTitle: '{{zoom_backgrounds_secondary_title}}',
  },
  dashboardSections: {},
  navBar: {
    height: 64,
    color: '#ffffff',
    backgroundColor: '#1A202E',
    indicatorColor: '#3CA9DF',
    font: null,
    fontSize: 24,
    mainMenuItemTitle: '{{navbar_main_menu_item_title}}',
    menuItems: {},
  },
  topBar: {
    text: '{{topbar_text}}',
    color: '#FFFFFF',
    font: null,
    fontSize: 48,
    logoSpacing: 15,
    backgroundColor: '#131D34',
    backgroundOpacity: 100,
    padding: {
      top: 5,
      right: 0,
      bottom: 5,
      left: 0,
    },
  },
  footer: {
    image: null,
    background: '#131D34',
    textColor: '#FFFFFF',
    termsLink: null,
    privacyLink: null,
    copyrightText: '{{footer_copyright_text}}',
    imageSize: 100,
  },
  localization: {
    languages: [{name: ENGLISH, rules: []}],
    translations: {
      English: {
        hero_body:
          'Overcome any fear, break through any limitation and create the life you’ve always wanted — but haven’t yet reached — your journey starts here.',
        check_in_title: 'Check In:',
        check_in_step_1_label: 'Step 1',
        check_in_step_2_label: 'Step 2',
        check_in_step_3_label: 'Step 3',
        leaderboard_title: 'Leaderboard',
        leaderboard_description:
          '<p>{{first name}}, you have earned {{leaderboard_points}} {{points_unit}}, and you are currently {{leaderboard_position}}. Great Job!</p><p><i>The list below is the top 200 point earners! If you don’t see your name listed, there’s still time!</i></p><p><br>&nbsp;</p>',
        leaderboard_back_to_dashboard_text: 'Back to Dashboard',
        set_password_title: 'Please set a password to continue',
        set_password_description: '',
        set_password_password_label: 'Password',
        set_password_confirm_password_label: 'Confirm Password',
        faq_title: 'FAQ',
        faq_description: '',
        faq_secondary_title: '',
        speakers_title: 'Speakers',
        speakers_menu_title: 'Our Speakers',
        speakers_description: '',
        speakers_secondary_title: 'Sample Speaker Page',
        speakers_other_speakers_title: 'Check other speakers',
        sponsors_title: 'Sponsors',
        sponsors_menu_title: 'Our Sponsors',
        sponsors_secondary_title: '',
        sponsors_description: '',
        login_welcome_text: 'Welcome',
        login_password_reset_forgot_password_title: 'Password Help',
        zoom_backgrounds_secondary_title: '',
        zoom_backgrounds_menu_title: 'Backgrounds',
        navbar_main_menu_item_title: 'Main',
        topbar_text: 'Townhall Demo',
        footer_copyright_text: '',
      },
    },
    isAdvancedMode: false,
    defaultLanguage: ENGLISH,
    translationsEnabled: false,
  },
})

export const DEFAULTS = {...createBlank(), localization: null}

export const sampleEventURL = 'https://v1.obv.io/events/townhall-demo'

export type NavMenuItemProps = Ordered & {
  title: string
  link?: string
  isEnabled: boolean
}

export type FeaturedNavMenuItemProps = NavMenuItemProps & {
  id: string
}
