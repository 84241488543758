import React, {useEffect, useImperativeHandle, useState} from 'react'
import {Box} from 'Event/Marketplace/Block'
import {JsonSave, REMOVE} from 'lib/JsonUpdateProvider'
import {ConfigContext} from 'organization/Event/Configurable'
import {useToggleArray} from 'lib/toggle'
import {useForm} from 'react-hook-form'
import {useEditMode} from 'Event/EditModeProvider'
import Title from 'Event/Marketplace/Block/Title'
import Text from 'Event/Marketplace/Block/Text'
import Image from 'Event/Marketplace/Block/Image'
import TicketSelector from 'Event/Marketplace/Block/TicketSelector'
import Button from 'Event/Marketplace/Block/Button'
import Video from 'Event/Marketplace/Block/Video'
import Separator from 'Event/Marketplace/Block/Separator'
import CountdownTimer from 'Event/Marketplace/Block/CountdownTimer'
import ConfigurableBulletedList from 'organization/Marketplace/config/ConfigurableBulletedList'
import ConfigurableNumberedList from 'organization/Marketplace/config/ConfigurableNumberedList'
import Icon from 'Event/Marketplace/Block/Icon'
import Spacer from 'Event/Marketplace/Block/Spacer'
import ConfigurablePurchaseForm from 'organization/Marketplace/config/ConfigurablePurchaseForm'
import ConfigurableFaqList from 'organization/Marketplace/config/ConfigurableFaqList'
import {useDuplicateAssets} from 'organization/Event/SelectTemplatePage'
import {
  createNumberItem,
  NumberItem,
} from 'Event/Marketplace/Block/NumberedList'
import {
  BulletItem,
  createBulletItem,
} from 'Event/Marketplace/Block/BulletedList'
import {FaqItem} from 'Event/Marketplace/Block/FaqList'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import {useConfigPage} from 'organization/Marketplace/config/ConfigPageProvider'
import UpsellDeclineButton from 'Event/Marketplace/Block/UpsellDeclineButton'
import {PageTemplate} from 'Event/Marketplace/page-template'
import ConfigurableUpsellPurchaseButton from 'organization/Marketplace/config/ConfigurableUpsellPurchaseButton'
import UpsellDeclineText from 'Event/Marketplace/Block/UpsellDeclineText'
import {
  NLPurchasePageBlock,
  NLPurchasePageTemplate,
} from 'Event/Marketplace/nl-purchase-page'
import {NLBlockProps} from 'Event/Marketplace/Block/NLBlock'
import NLBlockConfig from 'organization/Marketplace/config/BlockConfig/NLBlockConfig'
import NLConfigurableSection from 'organization/Marketplace/config/NLConfigurableSection'
import {DeepPartialSubstitute} from 'lib/type-utils'
import {useHoverContext} from 'organization/Marketplace/config/NLConfigPage'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/PurchasePageTemplateUpdateProvider'
import set from 'lodash/set'
import get from 'lodash/get'
import {createFaqItem} from 'Event/Marketplace/Block/FaqList/create-faq-list-block'
import {generateHashId} from 'lib/crypto/hash'
import {PurchasePageTemplate} from 'Event/Marketplace/purchase-page'
import EditableOverlay from 'organization/Marketplace/config/NLConfigurableBlock/EditableOverlay'

export const BLOCK_DRAG_HANDLE = 'block-drag-handle'

export const Blocks: ConfigComponentDefinitions = {
  Title,
  Text,
  Image,
  TicketSelector,
  Button,
  Video,
  PurchaseForm: ConfigurablePurchaseForm,
  Separator,
  BulletedList: ConfigurableBulletedList,
  CountdownTimer,
  NumberedList: ConfigurableNumberedList,
  Icon,
  FaqList: ConfigurableFaqList,
  Spacer,
  UpsellPurchaseButton: ConfigurableUpsellPurchaseButton,
  UpsellDeclineButton,
  UpsellDeclineText,
  Section: NLConfigurableSection,
}

export type ConfigComponentDefinitions = {
  [T in NLPurchasePageBlock as T['type']]: React.FC<
    T & {id: string} & ConfigurableBlockTargetProps & {
        template: NLPurchasePageTemplate
        updateTemplate: UpdateTemplateFunction
        availableBlocks: Array<NLPurchasePageBlock['type']>
        ButtonConfig: React.FC<any>
        blockPath: string
      }
  >
}

export type ConfigurableBlockProps = NLBlockProps & {
  getPreviewData: (data: Record<string, any>) => Record<string, any>
  availableBlocks: Array<NLPurchasePageBlock['type']>
  ButtonConfig: React.FC<any>
  blockPath: string
  onClickAddBlock?: () => void
  onSave: (
    data:
      | DeepPartialSubstitute<NLPurchasePageBlock, typeof REMOVE>
      | typeof REMOVE,
  ) => void
  layout?: 'row' | 'column'
  disableResize?: boolean
}

export type ConfigurableBlockTargetProps = NLBlockProps & {
  availableBlocks: Array<NLPurchasePageBlock['type']>
  onSave: (
    data: DeepPartialSubstitute<NLPurchasePageBlock, typeof REMOVE>,
  ) => void
  blockPath: string
  getPreviewData: (data: Record<string, any>) => Record<string, any>
}

const NLConfigurableBlock = React.forwardRef<
  HTMLDivElement,
  ConfigurableBlockProps
>((props, ref) => {
  const {
    block,
    id,
    template,
    getPreviewData,
    availableBlocks,
    ButtonConfig,
    onSave,
    blockPath,
    onClickAddBlock,
    layout = 'column',
    disableResize,
  } = props
  const {width, height} = block
  // const {sectionId} = useConfigurableSection()
  const withCopiedAssets = useDuplicateAssets<NLPurchasePageBlock>()
  const [copying, setCopying] = useState<boolean>(false)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const updateTemplate = useUpdateTemplate()

  // Add state for tracking if mouse is near border
  const [isNearBorder, setIsNearBorder] = useState(false)

  // Add state for resizing - convert to refs
  const isResizingRef = React.useRef(false)
  const sizeRef = React.useRef({width: 0, height: 0})
  const startPosRef = React.useRef({x: 0, y: 0})

  // Use the hover context instead of local state
  const {hoveredBlockId, setHoveredBlockId} = useHoverContext()
  const isHovering = hoveredBlockId === id

  // Access ref while forwarding it to outer
  useImperativeHandle(ref, () => container!, [container])

  const [showingConfig, toggleConfig] = useToggleArray()
  const form = useForm()
  const isEditMode = useEditMode()
  const {isMobileMode} = useConfigPage()
  const blockType = block.type

  const parentSectionPath = blockPath.substring(
    0,
    blockPath.lastIndexOf('.blocks'),
  )

  const Component = Blocks[blockType] ?? null
  const hasItems =
    blockType === 'FaqList' ||
    blockType === 'NumberedList' ||
    blockType === 'BulletedList'

  // Remove blocks with missing components. ie. deprecated blocks.
  useEffect(() => {
    // If missing block type, we'll assumed its already being removed (set to `__REMOVE__`), so
    // we'll avoid deleting it again. Fixes an occasionally ocurring inf-loop bug.
    if (!blockType) {
      return
    }

    if (Component === null) {
      updateTemplate(set({}, `${blockPath}.blocks.${id}`, REMOVE))
    }
  }, [Component, id, updateTemplate, blockType, blockPath])

  const copy = () => {
    if (copying) {
      return
    }

    setCopying(true)

    generateHashId([
      'marketplace_copied_block',
      blockType,
      Date.now().toString(),
      Math.random().toString(),
    ]).then((newId) => {
      withCopiedAssets(block)
        .then((blockWithCopiedAssets) => {
          updateTemplate(
            set(
              {},
              `${parentSectionPath}.blocks.${newId}`,
              blockWithCopiedAssets,
            ),
          )
        })
        .finally(() => {
          setCopying(false)
        })
    })
  }

  const [showingAddBlockDialog, toggleAddBlockDialog] = useToggleArray()

  const addItem = () => {
    switch (blockType) {
      case 'FaqList':
        addNewListItem({
          newItem: createFaqItem(),
          id,
          blockPath,
          template,
          updateTemplate,
        })
        break
      case 'NumberedList':
        addNewListItem({
          newItem: createNumberItem(),
          id,
          blockPath,
          template,
          updateTemplate,
        })
        break
      case 'BulletedList':
        addNewListItem({
          newItem: createBulletItem(),
          id,
          blockPath,
          template,
          updateTemplate,
        })
        break
    }
  }

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (!isEditMode) return
    setHoveredBlockId(id)
    e.stopPropagation()
  }

  const handleMouseLeave = (e: React.MouseEvent) => {
    if (!isEditMode) return
    if (hoveredBlockId === id) {
      setHoveredBlockId(null)
    }
    setIsNearBorder(false)
    e.stopPropagation()
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isEditMode || !container) return

    const rect = container.getBoundingClientRect()
    const borderThreshold = 20 // pixels from border to show add button

    // Check if mouse is near the border based on layout
    if (layout === 'row') {
      const distanceFromRight = rect.right - e.clientX
      setIsNearBorder(distanceFromRight <= borderThreshold)
    } else {
      const distanceFromBottom = rect.bottom - e.clientY
      setIsNearBorder(distanceFromBottom <= borderThreshold)
    }
  }

  const handleAddBlockClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClickAddBlock?.()
  }

  const handleResizeStart = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    if (disableResize) return
    if (!container) return

    isResizingRef.current = true

    // Store initial size and mouse position
    const rect = container.getBoundingClientRect()
    sizeRef.current = {width: rect.width, height: rect.height}
    startPosRef.current = {x: e.clientX, y: e.clientY}

    // Add document-level event listeners
    document.addEventListener('mousemove', handleResizeMove)
    document.addEventListener('mouseup', handleResizeEnd)
  }

  const handleResizeMove = (e: MouseEvent) => {
    if (!isResizingRef.current || !container) return

    // Calculate the delta from the start position
    const deltaX = e.clientX - startPosRef.current.x
    const deltaY = e.clientY - startPosRef.current.y

    // Apply the new size to the container
    container.style.width = `${sizeRef.current.width + deltaX}px`
    container.style.height = `${sizeRef.current.height + deltaY}px`
  }

  const handleResizeEnd = () => {
    isResizingRef.current = false

    if (!container) return
    const rect = container.getBoundingClientRect()
    onSave({
      width: rect.width,
      height: rect.height,
    })

    // Remove document-level event listeners
    document.removeEventListener('mousemove', handleResizeMove)
    document.removeEventListener('mouseup', handleResizeEnd)
  }

  if (!Component) {
    return null
  }

  // Need to use ConfigContext directly to separate out the config & editable component. If we used
  // Configurable, any clicks (ie. opening the config) would trigger a layout drag.
  return (
    <ConfigContext.Provider
      value={{
        showing: showingConfig,
        toggle: toggleConfig,
        form,
        getPreviewData,
      }}
    >
      <NLBlockConfig
        block={block}
        id={id}
        ButtonConfig={ButtonConfig}
        onSave={onSave}
      />
      <Box
        style={{
          position: 'relative',
          cursor: isEditMode ? 'pointer' : 'default',
          width: width ? `${width}px` : '100%',
          height: height ? `${height}px` : '100%',
          overflow: 'visible',
        }}
        ref={setContainer}
        visibility={block.visibility}
        isMobileMode={isMobileMode}
        className="block-configurable"
        onClick={(e) => {
          e.stopPropagation()
          toggleConfig()
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        {isEditMode && isHovering && (
          <EditableOverlay
            onResizeStart={handleResizeStart}
            canAddSibling={blockType !== 'Section' && isNearBorder}
            onAddSiblingBlock={handleAddBlockClick}
            onAddItem={hasItems ? addItem : undefined}
            onCopy={copy}
            onClickSettings={toggleConfig}
            {...props}
          />
        )}
        {/*
         * Setting 'any' type for props is ok here, because we're
         * already verifying the correct component receives the
         * correct type in ComponentDefinitions.
         */}
        <>
          <Component
            {...(block as any)}
            id={id}
            isMobileMode={isMobileMode}
            template={template}
            updateTemplate={() => {}}
            showingAddBlockDialog={showingAddBlockDialog}
            toggleAddBlockDialog={toggleAddBlockDialog}
            availableBlocks={availableBlocks}
            isEditMode={props.isEditMode}
            ButtonConfig={ButtonConfig}
            onSave={onSave}
            blockPath={blockPath}
            getPreviewData={getPreviewData}
          />
        </>
      </Box>
    </ConfigContext.Provider>
  )
})

export default NLConfigurableBlock

interface AddNewListItemParams {
  newItem: FaqItem | NumberItem | BulletItem
  blockPath: string
  id: string
  template: PageTemplate
  updateTemplate: JsonSave<PurchasePageTemplate>
}

export const addNewListItem = (params: AddNewListItemParams) => {
  const {newItem, blockPath, id: blockId, template, updateTemplate} = params

  const getNewPosition = () => {
    const block = get(template, `${blockPath}.blocks.${blockId}`)
    if (!block) {
      return 0
    }

    if ('items' in block) {
      return Object.keys(block.items).length + 1
    }

    return 0
  }

  generateHashId([
    'marketplace_new_list_item',
    Date.now().toString(),
    Math.random().toString(),
  ]).then((newId) => {
    updateTemplate(
      set({}, `${blockPath}.items.${newId}`, {
        ...newItem,
        position: getNewPosition(),
      }),
    )
  })
}
