import React from 'react'
import styled from 'styled-components'

interface AddSiblingButtonProps {
  layout?: 'row' | 'column'
  onClick: (e: React.MouseEvent) => void
}

export default function AddSiblingButton(props: AddSiblingButtonProps) {
  const {layout, onClick} = props

  const ContainerComponent = layout === 'row' ? RowContainer : ColumnContainer
  const ButtonComponent = layout === 'row' ? RowButton : ColumnButton
  const content = layout === 'row' ? '+' : 'Add Block'

  return (
    <ContainerComponent onClick={onClick}>
      <ButtonComponent>{content}</ButtonComponent>
    </ContainerComponent>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  position: absolute;
`

const RowContainer = styled(Container)`
  right: -12px;
  top: 0;
  bottom: 0;
`

const ColumnContainer = styled(Container)`
  bottom: -8px;
  left: 0;
  right: 0;
`

const ButtonBase = styled.button`
  color: white;
  border: none;
  cursor: pointer;
  background: ${(props) => props.theme.colors.primary};
`

const RowButton = styled(ButtonBase)`
  border-radius: 50%;
  width: 24px;
  hreight: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 0;
`
const ColumnButton = styled(ButtonBase)`
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
`
