import {useEvent} from 'Event/EventProvider'
import {PurchaseContext} from 'Event/Marketplace/PurchaseContext'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'
import {StripeAccountIdResponse} from 'lib/marketplace-api/purchase-setup/use-stripe-account-id'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useQueryParams} from 'lib/url'
import React, {useEffect, useMemo, useState} from 'react'

const stripePublicKey = process.env.REACT_APP_STRIPE_PK as string

interface PurchaseProviderProps {
  children: JSX.Element

  /**
   * Optionally limit which tickets can be selected. This is useful
   * for Custom Purchase Pages that might be limited to a subset
   * of tickets.
   */
  availableTicketIds?: number[]
}

export default function PurchaseProvider(props: PurchaseProviderProps) {
  const {children, availableTicketIds} = props
  const [
    selectedTicket,
    setSelectedTicket,
  ] = useState<MarketplaceTicket | null>(null)

  const {ticket: urlTicketSlug} = useQueryParams()

  const [stripeAccountId, setStripeAccountId] = useState<string | null>(null)

  const {data: tickets} = useEventTickets()
  const {event} = useEvent()
  const {id: eventId} = event

  const numTickets = tickets?.length ?? 0

  const selectedTicketSlug = selectedTicket?.slug

  // Pre-select ticket via URL
  useEffect(() => {
    if (!urlTicketSlug) {
      return
    }

    const urlTicket = tickets?.find((ticket) => ticket.slug === urlTicketSlug)
    if (!urlTicket) {
      return
    }

    if (urlTicket.slug === selectedTicketSlug) {
      return
    }

    setSelectedTicket(urlTicket)
  }, [urlTicketSlug, selectedTicketSlug, tickets, setSelectedTicket])

  useEffect(() => {
    if (stripeAccountId) {
      return
    }

    if (numTickets === 0) {
      return
    }

    const url = marketplaceApi(`/events/${eventId}/stripe_account_id`)
    client
      .get<StripeAccountIdResponse>(url)
      .then(({stripe_account_id}) => setStripeAccountId(stripe_account_id))
  }, [numTickets, stripeAccountId, eventId])

  const visibleTickets = useMemo(() => {
    if (!tickets) {
      return []
    }

    return tickets.filter((ticket) => {
      const isActive = ticket.active && ticket.is_available
      const isHidden = ticket.hidden

      if (availableTicketIds && availableTicketIds.length !== 0) {
        return availableTicketIds.includes(ticket.id) && isActive
      }

      if (urlTicketSlug) {
        return ticket.slug === urlTicketSlug && isActive
      }

      return isActive && !isHidden
    })
  }, [tickets, urlTicketSlug, availableTicketIds])

  // Auto-select a ticket if its the only one.
  useEffect(() => {
    if (!tickets) {
      return
    }

    if (selectedTicket) {
      return
    }

    if (visibleTickets.length === 1) {
      setSelectedTicket(tickets[0])
    }
  }, [tickets, selectedTicket, visibleTickets])

  return (
    <PurchaseContext.Provider
      value={{
        selectedTicket,
        setSelectedTicket,
        tickets: visibleTickets,
        submit: () => {},
        hasSavedCard: false,
        stripeAccountId,
        stripePublicKey,
        isProcessing: false,
        error: null,
        shouldValidateEmail: false, // Only validate on store site
        eventId,
        isShowingUpsell: false,
        isShowingThankYou: false,
        upsellDecline: () => {},
        mainPurchaseData: null,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  )
}
