import React, {useState} from 'react'

export type ConfigPage =
  | 'purchase_page'
  | 'upsell_page'
  | 'thank_you_page'
  | 'custom_purchase_page'

interface ConfigPageProviderProps {
  children: JSX.Element
  page: ConfigPage
}

interface ConfigPageContextProps {
  isMobileMode: boolean
  setMobileMode: (isMobileMode: boolean) => void
  page: ConfigPage
}

const ConfigPageContext = React.createContext<
  ConfigPageContextProps | undefined
>(undefined)

export default function ConfigPageProvider(props: ConfigPageProviderProps) {
  const {children, page} = props

  const [isMobileMode, setMobileMode] = useState(false)

  return (
    <ConfigPageContext.Provider
      value={{
        isMobileMode,
        setMobileMode,
        page,
      }}
    >
      {children}
    </ConfigPageContext.Provider>
  )
}

export function useConfigPage() {
  const context = React.useContext(ConfigPageContext)
  if (context === undefined) {
    throw new Error('useConfigPage must be used within a ConfigPageProvider')
  }

  return context
}
