import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {withStyles} from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {Room} from 'Event/room'
import {
  onChangeCheckedHandler,
  onChangeNumberHandler,
  onChangeStringHandler,
} from 'lib/dom'
import {spacing} from 'lib/ui/theme'
import Page from 'organization/Event/Page'
import {useRoom} from 'organization/Event/Room/RoomProvider'
import {useRoomRoutes} from 'organization/Event/Room/RoomRoutes'
import React, {useState, useEffect, useCallback} from 'react'
import TechCheckAttendees from 'organization/Event/Room/TechCheckAttendees'
import StartButton from 'organization/Event/Room/StartButton'
import Box from '@material-ui/core/Box'
import HasPermission from 'organization/HasPermission'
import {
  CHECK_IN_ATTENDEES,
  EVENT_SETTINGS,
  useCanStartRooms,
  VIEW_RECORDINGS,
} from 'organization/PermissionsProvider'
import OnlineSwitch from 'organization/Event/Room/OnlineSwitch'
import ExportRoomAttendees from 'organization/Event/Room/ExportRoomAttendees'
import RegistrationSwitch from 'organization/Event/Room/RegistrationSwitch'
import recordingIcon from 'assets/images/recording-icon.png'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import DeleteRoomButton from 'organization/Event/Room/DeleteRoomButton'
import PauseButton from 'organization/Event/Room/PauseButton'
import EndButton from 'organization/Event/Room/EndButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import {useHasOrganizationFeatureToggle} from 'organization/OrganizationFeatureToggle'
import {ROOM_LIMIT_MAX_ATTENDEES} from 'auth/user/flags'
import PageBreadcrumbs, {
  PageBreadcrumbProps,
} from 'organization/Event/Page/PageBreadcrumbs'
import {AreaBreadcrumbs} from 'organization/Event/Area'
import MeetingDetails from 'organization/Event/Room/MeetingDetails'
import {Meeting, useFetchMeeting} from 'organization/Event/Room/meeting'
import {useOrganization} from 'organization/OrganizationProvider'
import {useAsync} from 'lib/async'
import {useArea} from 'organization/Event/Area/AreaProvider'
import Visible from 'lib/ui/layout/Visible'
import {OVERFLOW} from 'organization/Event/AreasProvider'
import RoomRegistrationURL from 'organization/Event/Room/RoomRegistrationURL'

export const DEFAULT_MAX_NUM_ATTENDEES = 500

export default function RoomConfig() {
  const {room, update, processing} = useRoom()
  const [description, setDescription] = useState(room.description)
  const [maxNumAttendees, setMaxNumAttendees] = useState(room.max_num_attendees)
  const hasUpdatedName = description !== room.description
  const hasUpdatedMaxNumAttendees = maxNumAttendees !== room.max_num_attendees
  const hasUpdates = hasUpdatedName || hasUpdatedMaxNumAttendees
  const roomRoutes = useRoomRoutes()
  const {area} = useArea()
  const {data: meeting} = useMeetingDetails(room)

  const numAttendees = room.attendee_count || 0

  // Update form values if the room was updated, ie., via socket.
  useEffect(() => {
    setDescription(room.description)
    setMaxNumAttendees(room.max_num_attendees)
  }, [room])

  const canSave = hasUpdates && !processing

  const save = () => {
    const data: Room = {
      ...room,
      description,
      max_num_attendees: maxNumAttendees,
    }

    update(data)
  }

  const handleToggleMaxNumAttendees = (hasLimit: boolean) => {
    const shouldSetDefault = hasLimit && maxNumAttendees === null
    if (shouldSetDefault) {
      setMaxNumAttendees(DEFAULT_MAX_NUM_ATTENDEES)
      return
    }

    const shouldSetNull = !hasLimit
    if (shouldSetNull) {
      setMaxNumAttendees(null)
    }
  }

  const hasMaxNumAttendees = maxNumAttendees !== null
  const canSetMaxNumAttendees = hasMaxNumAttendees && !processing

  const showingMaxAttendeeFields = useShowingMaxAttendeeFields()

  return (
    <AreaBreadcrumbs page={'Room ' + String(room.number)}>
      <Page>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <HasPermission required={EVENT_SETTINGS}>
            <ExportRoomAttendees room={room} />
          </HasPermission>
          <HasPermission required={VIEW_RECORDINGS}>
            <RelativeLink to={roomRoutes.recordings} disableStyles>
              <Button variant="outlined">
                <RecordingIconImage src={recordingIcon} alt="Record icon" />
                Recordings
              </Button>
            </RelativeLink>
          </HasPermission>
        </Box>
        <Title variant="h5">{room.number}</Title>
        <DeleteRoomButton />
        <Typography>Attendees: {numAttendees}</Typography>
        <FormControl>
          <FormControlLabel control={<OnlineSwitch />} label="Open Room" />
        </FormControl>

        <StyledButtonGroup
          color="primary"
          aria-label="outlined primary button group"
        >
          <StartRoom processing={processing} meeting={meeting}>
            {startLabel(numAttendees)}
          </StartRoom>

          <PauseRoom processing={processing} numAttendees={numAttendees} />

          <EndRoom processing={processing} numAttendees={numAttendees} />
        </StyledButtonGroup>

        <HasPermission required={EVENT_SETTINGS}>
          <FormControl>
            <FormControlLabel
              control={<RegistrationSwitch />}
              label="Registration Enabled"
            />
          </FormControl>
        </HasPermission>
        <HasPermission required={EVENT_SETTINGS}>
          <RoomRegistrationURL target={`${area.name} ${room.number}`} />
        </HasPermission>
        <HasPermission required={EVENT_SETTINGS}>
          <>
            <TextField
              label="Description"
              fullWidth
              variant="outlined"
              inputProps={{
                'aria-label': 'room description',
              }}
              disabled={processing}
              value={description || ''}
              onChange={onChangeStringHandler(setDescription)}
            />
            <Visible when={showingMaxAttendeeFields}>
              <FormControl
                required
                component="fieldset"
                fullWidth
                disabled={processing}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={hasMaxNumAttendees}
                      onChange={onChangeCheckedHandler(
                        handleToggleMaxNumAttendees,
                      )}
                      color="primary"
                      inputProps={{
                        'aria-label': 'toggle has max num attendees',
                      }}
                    />
                  }
                  label="Limit maximum number of attendees in room?"
                />
              </FormControl>
              <TextField
                label="Maximum number of attendees"
                type="number"
                name="max_num_attendees"
                fullWidth
                variant="outlined"
                value={maxNumAttendees || ''}
                inputProps={{
                  'aria-label': 'set max number of attendees',
                  min: 0,
                  max: 1000,
                }}
                onChange={onChangeNumberHandler(setMaxNumAttendees)}
                disabled={!canSetMaxNumAttendees}
              />
            </Visible>
            <Button
              color="primary"
              disabled={!canSave}
              variant="contained"
              onClick={save}
              aria-label="update room"
            >
              Save
            </Button>
          </>
        </HasPermission>
        <MeetingDetails details={meeting} />
        <HasPermission required={CHECK_IN_ATTENDEES}>
          <TechCheckAttendees />
        </HasPermission>
      </Page>
    </AreaBreadcrumbs>
  )
}

function useMeetingDetails(room: Room) {
  const {id} = room
  const fetch = useFetchMeeting()
  const {client} = useOrganization()

  const request = useCallback(() => {
    return fetch({client, room: {id}})
  }, [fetch, client, id])

  return useAsync(request)
}

export function RoomBreadcrumbs(props: PageBreadcrumbProps) {
  const {room} = useRoom()
  const roomRoutes = useRoomRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Room ' + String(room.number),
        url: roomRoutes.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
`

export const startLabel = (numAttendees: number | null) => {
  if (numAttendees && numAttendees > 0) {
    return 'Join'
  }

  return 'Start'
}

function StartRoom(props: {
  processing: boolean
  children?: string
  meeting: Meeting | null
}) {
  const canStartRooms = useCanStartRooms()
  if (!canStartRooms) {
    return null
  }

  return (
    <Box mb={3}>
      <StartButton disabled={props.processing}>{props.children}</StartButton>
    </Box>
  )
}

function PauseRoom(props: {processing: boolean; numAttendees?: number}) {
  const canStartRoom = useCanStartRooms()
  if (!canStartRoom) {
    return null
  }

  return (
    <Box mb={3}>
      <PauseButton
        numAttendees={props.numAttendees}
        processing={props.processing}
      />
    </Box>
  )
}

function EndRoom(props: {processing: boolean; numAttendees?: number}) {
  const canStartRoom = useCanStartRooms()
  if (!canStartRoom) {
    return null
  }

  return (
    <Box mb={3}>
      <EndButton
        numAttendees={props.numAttendees}
        processing={props.processing}
      />
    </Box>
  )
}

export function useShowingMaxAttendeeFields() {
  const hasFlag = useHasOrganizationFeatureToggle(ROOM_LIMIT_MAX_ATTENDEES)
  const {area} = useArea()
  const hasOverflowStrategy = area.load_balance_strategy === OVERFLOW

  return hasOverflowStrategy || hasFlag
}

const Title = withStyles({
  root: {
    marginBottom: spacing[4],
  },
})(Typography)

export const RecordingIconImage = styled.img`
  width: 24px;
  margin-right: ${(props) => props.theme.spacing[1]};
`
