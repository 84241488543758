import styled from 'styled-components'
import React, {HTMLAttributes} from 'react'

export default function IconButton(props: HTMLAttributes<HTMLButtonElement>) {
  return (
    <StyledButton
      {...props}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = 'transparent'
      }}
    />
  )
}

const StyledButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  margin: 0 2px;
  border-radius: 3px;
  transition: background-color 0.2s ease;
`
