import {useEditMode} from 'Event/EditModeProvider'
import {useLanguage} from 'Event/LanguageProvider'
import {useTemplate} from '../TemplateProvider'

export interface HasTargetLanguages {
  targetLanguages?: string[]
}

interface VisibleForCurrentLanguageProps {
  children: JSX.Element
  targetLanguages?: string[]
}

export default function VisibleForCurrentLanguage(
  props: VisibleForCurrentLanguageProps,
) {
  const {targetLanguages, children} = props

  const isEditMode = useEditMode()
  const {localization} = useTemplate()
  const {current: currentLanguage, defaultLanguage} = useLanguage()

  const language = currentLanguage ?? defaultLanguage

  if (!localization?.translationsEnabled) {
    return children
  }

  if (isEditMode) {
    return children
  }

  if (!targetLanguages) {
    return children
  }

  // The assumption here is that target languages filter is enabled
  // if any languages have been set, otherwise it is disabled.
  if (targetLanguages.length === 0) {
    return children
  }

  if (!language) {
    return null
  }

  if (targetLanguages.includes(language)) {
    return children
  }

  return null
}
