import styled from 'styled-components'
import React from 'react'
import {useTownhallTemplate} from 'Event/template/Townhall'
import BaseLogo from 'Event/auth/Login/Logo'
import Grid from '@material-ui/core/Grid'
import {rgba} from 'lib/color'
import Configurable from 'organization/Event/Configurable'
import LeftPanelConfig from 'Event/template/Townhall/Login/LeftPanel/LeftPanelConfig'

export default function LeftPanel() {
  const {login} = useTownhallTemplate()

  const backgroundColor = rgba(
    login.leftPanel.backgroundColor,
    login.leftPanel.backgroundOpacity / 100,
  )

  return (
    <LeftGrid item xs={12} md={6} backgroundColor={backgroundColor}>
      <Configurable aria-label="login left panel config">
        <LeftPanelConfig />
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Configurable>
    </LeftGrid>
  )
}

export const Logo = styled(BaseLogo)`
  width: 100%;
  text-align: center;
  padding: ${(props) => props.theme.spacing[4]};
  margin: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 70%;
  }
`

const LogoContainer = styled.div`
  width: 45%;
  margin: auto;
`

const LeftGrid = styled((props) => {
  const {backgroundColor: _, ...otherProps} = props

  return <Grid {...otherProps} />
})<{backgroundColor?: string}>`
  display: flex;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ffffff'};
`
