import React, {useState, useRef, useEffect} from 'react'
import AddBlockButton from 'organization/Marketplace/config/AddBlockButton'
import {grey} from '@material-ui/core/colors'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {BlockBase} from 'Event/Marketplace/Block/base'
import {useConfigPage} from 'organization/Marketplace/config/ConfigPageProvider'
import NLSection, {NLSectionBlock} from 'Event/Marketplace/Block/NLSectionBlock'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import NLConfigurableBlock, {
  ConfigurableBlockTargetProps,
} from 'organization/Marketplace/config/NLConfigurableBlock'
import NLAddBlockDialog from 'organization/Marketplace/config/NLAddBlockDialog'
import ButtonConfig from 'organization/Marketplace/config/BlockConfig/ButtonConfig'
import {ConfigurableSectionContext} from 'organization/Marketplace/config/ConfigurableSection'
import {orderedIdsByPosition} from 'Event/Marketplace/lib/list'
import {createPositions} from 'lib/list'
import {useDrag} from 'organization/Marketplace/config/DragContext'

export const movedBlockBetweenSectionEvent = 'block_moved_between_section'

type ConfigurableSectionProps = NLSectionBlock &
  BlockComponentProps &
  ConfigurableBlockTargetProps

export default function NLConfigurableSection(props: ConfigurableSectionProps) {
  const {
    id: sectionId,
    template,
    blocks,
    visibility,
    availableBlocks,
    onSave,
    blockPath,
    getPreviewData,
  } = props
  const isEditMode = useEditMode()
  const {isMobileMode} = useConfigPage()
  const sectionRef = useRef<HTMLDivElement>(null)
  // Add state to track the target block for drop indicator
  const [dropTargetId, setDropTargetId] = useState<string | null>(null)
  // Add state to track if mouse is over the section
  const [isMouseOverSection, setIsMouseOverSection] = useState(false)
  const [insertTargetBlockId, setInsertTargetBlockId] = useState<string | null>(
    null,
  )

  // Use the global drag context
  const {
    draggedBlockId,
    draggedSectionId,
    draggedBlockPath,
    isDragging,
    setDraggedBlock,
    setIsDragging,
    resetDrag,
  } = useDrag()

  const blockIds = orderedIdsByPosition(blocks)

  // Handle drag start
  const handleDragStart = (e: React.DragEvent, id: string) => {
    setIsDragging(true)
    setDraggedBlock(id, sectionId, `${blockPath}.blocks.${id}`)

    // Set the drag image to be the block element
    const blockElement = document.getElementById(`block-${id}`)
    if (blockElement) {
      // Create a ghost image with reduced opacity
      const rect = blockElement.getBoundingClientRect()
      const ghostElement = blockElement.cloneNode(true) as HTMLElement
      ghostElement.style.position = 'absolute'
      ghostElement.style.top = '-1000px'
      ghostElement.style.opacity = '0.7'
      ghostElement.style.width = `${rect.width}px`
      document.body.appendChild(ghostElement)

      e.dataTransfer.setDragImage(ghostElement, 10, 10)

      // Clean up ghost element after drag starts
      setTimeout(() => {
        document.body.removeChild(ghostElement)
      }, 0)
    }

    // Store both section and block IDs in the data transfer
    e.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        blockId: id,
        sectionId: sectionId,
        blockPath: blockPath,
      }),
    )
    e.dataTransfer.effectAllowed = 'move'
  }

  // Handle drag over
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'

    // Set mouse over section state to true
    setIsMouseOverSection(true)

    if (!isDragging || !draggedBlockId) {
      return
    }

    const mouseX = e.clientX
    const mouseY = e.clientY

    // Check if mouse is within the section boundaries
    const sectionRect = sectionRef.current?.getBoundingClientRect()
    if (
      !sectionRect ||
      mouseX < sectionRect.left ||
      mouseX > sectionRect.right ||
      mouseY < sectionRect.top ||
      mouseY > sectionRect.bottom
    ) {
      // Mouse is outside section, clear drop target
      setDropTargetId(null)
      setIsMouseOverSection(false)
      return
    }

    const numBlocks = blockIds.length
    const isRowLayout = props.layoutDirection === 'row'

    if (numBlocks === 0) {
      setDropTargetId('end')
      return
    }

    // Calculate distances to each block
    const blockDistances = blockIds.map((id) => {
      const blockElement = document.getElementById(`block-${id}`)
      if (blockElement) {
        const blockRect = blockElement.getBoundingClientRect()
        const blockCenterX = blockRect.left + blockRect.width / 2
        const blockCenterY = blockRect.top + blockRect.height / 2

        let distance

        if (isRowLayout) {
          // For row layout, prioritize horizontal distance
          distance = Math.abs(mouseX - blockCenterX)
        } else {
          // For column layout, use Euclidean distance
          const distanceX = mouseX - blockCenterX
          const distanceY = mouseY - blockCenterY
          distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
        }

        return {id, distance, rect: blockRect}
      }
      return {id, distance: Infinity, rect: null}
    })

    // Sort blocks by distance
    blockDistances.sort((a, b) => a.distance - b.distance)

    // Find the closest block
    const closestBlock = blockDistances[0]
    const closestBlockIndex = blockIds.indexOf(closestBlock.id)
    const isFirstBlock = closestBlockIndex === 0
    const isLastBlock = closestBlockIndex === numBlocks - 1
    const isDifferentSection = draggedSectionId !== sectionId

    if (!closestBlock || !closestBlock.rect) {
      setDropTargetId(null)
      return
    }

    // Determine if we should insert before or after the closest block
    // For row layout, check if mouse is to the left or right of the block center
    // For column layout, check if mouse is above or below the block center
    const isBeforeClosestBlock = isRowLayout
      ? mouseX < closestBlock.rect.left + closestBlock.rect.width / 2
      : mouseY < closestBlock.rect.top + closestBlock.rect.height / 2

    if (isLastBlock && !isDifferentSection) {
      setDropTargetId('end')
      return
    }

    if (isLastBlock && isDifferentSection && !isBeforeClosestBlock) {
      setDropTargetId('end')
      return
    }

    if (isBeforeClosestBlock) {
      // Show indicator before this block
      setDropTargetId(closestBlock.id)
      return
    }

    if (isFirstBlock && !isDifferentSection) {
      setDropTargetId(closestBlock.id)
      return
    }

    const nextBlockId = blockIds[closestBlockIndex + 1]
    if (nextBlockId === draggedBlockId) {
      setDropTargetId(closestBlock.id)
      return
    }

    if (
      closestBlock.id === draggedBlockId &&
      nextBlockId === blockIds[blockIds.length - 1]
    ) {
      setDropTargetId('end')
      return
    }

    setDropTargetId(blockIds[closestBlockIndex + 1])
  }

  // Handle drop
  const handleDrop = (e: React.DragEvent, targetId: string) => {
    e.preventDefault()

    if (!draggedBlockId) {
      resetDrag()
      return
    }

    // Handle drop within the same section
    if (draggedSectionId === sectionId) {
      if (draggedBlockId === targetId) {
        resetDrag()
        return
      }

      const moved = Array.from(blockIds)
      const draggedIndex = moved.indexOf(draggedBlockId)
      const targetIndex = moved.indexOf(targetId)
      const [removed] = moved.splice(draggedIndex, 1)
      moved.splice(targetIndex, 0, removed)

      onSave({
        blocks: createPositions(moved),
      })
    }

    resetDrag()
    // Reset drop target
    setDropTargetId(null)
  }

  // Handle section drop zone (for dropping at the end of a section)
  const handleSectionDrop = (e: React.DragEvent) => {
    e.preventDefault()

    if (!draggedBlockId || !draggedSectionId) {
      resetDrag()
      setDropTargetId(null)
      return
    }

    // If dropping in the same section, do nothing
    if (draggedSectionId === sectionId) {
      resetDrag()
      setDropTargetId(null)
      return
    }

    // Check if mouse is within the section boundaries
    const sectionRect = sectionRef.current?.getBoundingClientRect()
    const mouseX = e.clientX
    const mouseY = e.clientY
    const isRowLayout = props.layoutDirection === 'row'

    if (
      !sectionRect ||
      mouseX < sectionRect.left ||
      mouseX > sectionRect.right ||
      mouseY < sectionRect.top ||
      mouseY > sectionRect.bottom
    ) {
      // Mouse is outside section, don't process the drop
      resetDrag()
      setDropTargetId(null)
      return
    }

    // Get the source block using the path

    // Find the closest block to drop position
    let insertIndex = blockIds.length // Default to end of section

    if (sectionRect && blockIds.length > 0) {
      // Calculate distances to each block
      const blockDistances = blockIds.map((id, index) => {
        const blockElement = document.getElementById(`block-${id}`)
        if (blockElement) {
          const blockRect = blockElement.getBoundingClientRect()
          const blockCenterX = blockRect.left + blockRect.width / 2
          const blockCenterY = blockRect.top + blockRect.height / 2

          let distance

          if (isRowLayout) {
            // For row layout, prioritize horizontal distance
            distance = Math.abs(mouseX - blockCenterX)
          } else {
            // For column layout, use Euclidean distance
            const distanceX = mouseX - blockCenterX
            const distanceY = mouseY - blockCenterY
            distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
          }

          return {index, distance, rect: blockRect}
        }
        return {index, distance: Infinity, rect: null}
      })

      // Sort blocks by distance
      blockDistances.sort((a, b) => a.distance - b.distance)

      // Find the closest block
      const closestBlock = blockDistances[0]

      if (closestBlock && closestBlock.rect) {
        // Determine if we should insert before or after the closest block
        if (isRowLayout) {
          // For row layout, check horizontal position
          if (mouseX < closestBlock.rect.left + closestBlock.rect.width / 2) {
            // Insert before the closest block
            insertIndex = closestBlock.index
          } else {
            // Insert after the closest block
            insertIndex = closestBlock.index + 1
          }
        } else {
          // For column layout, check vertical position
          if (mouseY < closestBlock.rect.top + closestBlock.rect.height / 2) {
            // Insert before the closest block
            insertIndex = closestBlock.index
          } else {
            // Insert after the closest block
            insertIndex = closestBlock.index + 1
          }
        }
      }
    }

    if (draggedBlockPath === blockPath) {
      return
    }

    // Insert the block at the determined position
    // Create an event to notify the parent component about the cross-section move
    const customEvent = new CustomEvent(movedBlockBetweenSectionEvent, {
      detail: {
        sourceBlockPath: draggedBlockPath,
        targetSectionPath: blockPath,
        insertIndex,
      },
      bubbles: true,
    })

    if (sectionRef.current) {
      sectionRef.current.dispatchEvent(customEvent)
    }

    resetDrag()
    setDropTargetId(null)
  }

  // Handle drag end
  const handleDragEnd = () => {
    resetDrag()
    setDropTargetId(null)
    setIsMouseOverSection(false)
  }

  // Clear drop target when drag state changes
  useEffect(() => {
    if (!isDragging) {
      setDropTargetId(null)
      setIsMouseOverSection(false)
    }
  }, [isDragging])

  // Add handler for when mouse leaves the section
  const handleDragLeave = (e: React.DragEvent) => {
    // Check if the mouse has actually left the section (not just entered a child element)
    const relatedTarget = e.relatedTarget as Node
    if (sectionRef.current && !sectionRef.current.contains(relatedTarget)) {
      setIsMouseOverSection(false)
    }
  }

  const isEmpty = blockIds.length === 0

  return (
    <ConfigurableSectionContext.Provider
      value={{
        update: onSave,
        sectionId,
      }}
    >
      <Box
        hasBorder={isEditMode}
        isMobileMode={isMobileMode}
        visibility={visibility}
        className="section-configurable"
        onDragOver={handleDragOver}
      >
        <NLAddBlockDialog
          open={Boolean(insertTargetBlockId)}
          onClose={() => setInsertTargetBlockId(null)}
          availableBlocks={availableBlocks}
          onAdd={onSave}
          insertTargetBlockId={insertTargetBlockId}
          blockIds={blockIds}
        />
        <DragContainer
          onDrop={handleSectionDrop}
          ref={sectionRef}
          isOver={isDragging && dropTargetId === 'end' && isMouseOverSection}
          isRowLayout={props.layoutDirection === 'row'}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <NLSection {...props} template={template}>
            {blockIds.map((id) => {
              const block = blocks[id]
              if (!block) return null

              return (
                <DraggableBlockWrapper
                  key={id}
                  id={`block-${id}`}
                  draggable={isEditMode}
                  onDragStart={(e) => handleDragStart(e, id)}
                  onDrop={(e) => handleDrop(e, id)}
                  onDragEnd={handleDragEnd}
                  isDragging={draggedBlockId === id}
                  isOver={
                    isDragging &&
                    draggedBlockId !== id &&
                    dropTargetId === id &&
                    isMouseOverSection
                  }
                  isRowLayout={props.layoutDirection === 'row'}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <NLConfigurableBlock
                    block={block}
                    key={id}
                    id={id}
                    template={template}
                    ButtonConfig={ButtonConfig}
                    isEditMode
                    availableBlocks={availableBlocks}
                    onSave={(data) => {
                      onSave({
                        blocks: {
                          [id]: data,
                        },
                      })
                    }}
                    getPreviewData={(data) =>
                      getPreviewData({
                        blocks: {
                          [id]: data,
                        },
                      })
                    }
                    blockPath={`${blockPath}.blocks.${id}`}
                    onClickAddBlock={() => setInsertTargetBlockId(id)}
                    layout={props.layoutDirection}
                  />
                </DraggableBlockWrapper>
              )
            })}
          </NLSection>
        </DragContainer>
        {isEmpty && (
          <AddBlockButton onClick={() => setInsertTargetBlockId('end')} />
        )}
      </Box>
    </ConfigurableSectionContext.Provider>
  )
}

// Update the DragContainer to show a drop indicator when dragging over it
const DragContainer = styled.div<{
  isOver: boolean
  isRowLayout?: boolean
}>`
  width: 100%;
  min-height: 50px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    ${(props) =>
      props.isRowLayout
        ? `
        right: 0;
        top: 0;
        bottom: 0;
        width: ${props.isOver ? '4px' : '0'};
      `
        : `
        bottom: 0;
        left: 0;
        right: 0;
        height: ${props.isOver ? '4px' : '0'};
      `}
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.2s ease;
    z-index: 10;
  }
`

const Box = styled.div<{
  hasBorder: boolean
  visibility: BlockBase['visibility']
  isMobileMode: boolean
}>`
  width: 100%;
  padding-top: 10px;
  border-bottom: ${(props) =>
    props.hasBorder ? `2px dashed ${grey[600]}` : 'none'};

  display: ${(props) =>
    props.visibility === 'desktop_only' ? 'none' : 'block'};
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: ${(props) =>
      (props.visibility === 'mobile_only' && !props.isMobileMode) ||
      (props.visibility === 'desktop_only' && props.isMobileMode)
        ? 'none'
        : 'block'};
  }
`

const DraggableBlockWrapper = styled.div<{
  isDragging: boolean
  isOver: boolean
  isRowLayout?: boolean
}>`
  position: relative;
  cursor: ${(props) => (props.isDragging ? 'grabbing' : 'grab')};
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  margin-bottom: 10px;

  &:before {
    content: '';
    position: absolute;
    ${(props) =>
      props.isRowLayout
        ? `
        left: ${props.isOver ? '-10px' : '0'};
        top: 0;
        bottom: 0;
        width: ${props.isOver ? '4px' : '0'};
      `
        : `
        top: ${props.isOver ? '-10px' : '0'};
        left: 0;
        right: 0;
        height: ${props.isOver ? '4px' : '0'};
      `}
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.2s ease;
    z-index: 10;
  }
`
