import {CustomPurchasePageTemplate} from 'Event/Marketplace/custom-purchase-page'
import {withDefaults} from 'lib/object'

export function fillCustomPurchasePageTemplate(
  template: CustomPurchasePageTemplate,
) {
  const backgroundDefaults: CustomPurchasePageTemplate['background'] = {
    image: '',
    color: '',
    opacity: 0,
    position: 'center center',
    repeat: 'none',
  }
  const favicon: CustomPurchasePageTemplate['favicon'] = null as string | null
  const typography: CustomPurchasePageTemplate['typography'] = {
    font: null,
    fontSize: 20,
    textColor: '#000000',
  }

  return withDefaults(
    {
      sections: {},
      background: backgroundDefaults,
      padding: {top: 0, right: 0, bottom: 0, left: 0},
      title: '',
      description: '',
      headHtml: '',
      footerHtml: '',
      favicon,
      typography,
      contentMaxWidth: 1280,
      available_tickets: [] as number[],
    },
    template,
  )
}
