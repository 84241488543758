import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'
import {useEvent} from 'Event/EventProvider'

interface UseUpdateCustomPurchasePageParams {
  customPurchasePage: CustomPurchasePage
}

export interface UpdateCustomPurchasePageData {
  name: string
  slug: string
}

export function useUpdateCustomPurchasePage(
  params: UseUpdateCustomPurchasePageParams,
) {
  const {customPurchasePage} = params
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: Partial<CustomPurchasePage>) => {
      const url = marketplaceApi(`/purchase_pages/${customPurchasePage.id}`)
      return client!.put<CustomPurchasePage>(url, data)
    },
    onSuccess: (updated) => {
      qc.setQueryData<CustomPurchasePage[]>(
        ['events', event.id, 'custom_purchase_pages'],
        (purchasePages) => {
          if (!purchasePages) {
            return []
          }

          return purchasePages.map((page) =>
            page.id === updated.id ? updated : page,
          )
        },
      )
    },
  })
}
