import React from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/PurchasePageTemplateUpdateProvider'
import PreviewButton from 'organization/Marketplace/PurchasePageConfig/PreviewButton'
import PurchasePageButtonConfig from 'organization/Marketplace/config/BlockConfig/PurchasePageButtonConfig'
import {useEvent} from 'Event/EventProvider'
import {Client} from 'lib/ui/api-client'
import {useUpdatePurchasePage} from 'lib/marketplace-api/purchase-page/use-update-purchase-page'
import MissingRequiredBlocksAlert from 'organization/Marketplace/PurchasePageConfig/MissingRequiredBlocksAlert'
import ClearTemplateButton from 'organization/Marketplace/PurchasePageConfig/ClearTemplateButton'
import NLConfigPage from 'organization/Marketplace/config/NLConfigPage'
import {NLPurchasePageTemplate} from 'Event/Marketplace/nl-purchase-page'
import {JsonSave} from 'lib/JsonUpdateProvider'

interface ContentProps {
  purchasePage: PurchasePage
  template: NLPurchasePageTemplate
  client: Client
}

export default function NLPurchasePageConfigContent(props: ContentProps) {
  const {template, purchasePage, client} = props
  const {event} = useEvent()
  const updateTemplate = useUpdateTemplate()

  const updatePurchasePage = useUpdatePurchasePage({client, purchasePage})

  const setEnabled = (enabled: boolean) => {
    updatePurchasePage.mutateAsync({is_enabled: enabled})
  }

  const marketplaceStoreUrl = process.env.REACT_APP_MARKETPLACE_STORE_APP_URL

  const copyButton = {
    value: `${marketplaceStoreUrl}/${event.slug}`,
    tooltip: 'Copy Purchase Page URL',
  }

  return (
    <>
      <NLConfigPage
        template={template}
        updateTemplate={
          // TODO: Remove this cast once we switch to the New Layout (NL) template
          (updateTemplate as unknown) as JsonSave<NLPurchasePageTemplate>
        }
        availableBlocks={[
          'Title',
          'Text',
          'Image',
          'Video',
          'Button',
          'TicketSelector',
          'PurchaseForm',
          'BulletedList',
          'NumberedList',
          'FaqList',
          'CountdownTimer',
          'Icon',
          'Separator',
          'Section',
          'Spacer',
        ]}
        rightToolbarItems={
          <>
            <ClearTemplateButton updateTemplate={updateTemplate} />
            <PreviewButton />
          </>
        }
        ButtonConfig={PurchasePageButtonConfig}
        copyButton={copyButton}
        enabled={purchasePage.is_enabled}
        setEnabled={setEnabled}
        disableEnableToggle={updatePurchasePage.isLoading}
      />
      <MissingRequiredBlocksAlert template={template} />
    </>
  )
}
