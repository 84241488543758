import styled from 'styled-components'
import React from 'react'
import CollapseButton from 'organization/Event/Layout/Sidebar/CollapseButton'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {
  BoothIcon,
  CalendarIcon,
  ClipboardIcon,
  CogIcon,
  CropIcon,
  MegaphoneIcon,
  PeopleIcon,
  MarketplaceIcon,
  EmojiIcon,
  ImageWaterfallIcon,
  TowerCellIcon,
} from 'lib/ui/Icon'
import Section from 'organization/Event/Layout/Sidebar/Section'
import PageLink from 'organization/Event/Layout/Sidebar/PageLink'
import {useLayout} from 'organization/Event/Layout'
import {
  SCHEDULE_BROADCASTS,
  CHECK_IN_ATTENDEES,
  EVENT_SETTINGS,
  EVENT_DESIGN,
  EMOJI_PAGE,
  MANAGE_MARKETPLACE,
  START_AREAS,
  START_ROOMS,
  UPDATE_ATTENDEES,
  VIEW_STATISTICS,
  usePermissions,
  ZOOM_ATTENDANCE,
} from 'organization/PermissionsProvider'
import {ENTERPRISE} from 'obvio/Billing/plans'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import HasPermission from 'organization/HasPermission'
import CloseButton from 'organization/Event/Layout/Sidebar/CloseButton'
import {APPBAR_HEIGHT} from 'lib/ui/AppBar'
import OrganizationFeatureToggle, {
  useHasOrganizationFeatureToggle,
} from 'organization/OrganizationFeatureToggle'
import {
  BROADCASTS,
  MARKETPLACE,
  MARKETPLACE_CUSTOM_PAGE,
  MARKETPLACE_UPSELL,
} from 'auth/user/flags'

export default function Sidebar(props: {className?: string}) {
  const routes = useEventRoutes()
  const {sidebarExpanded} = useLayout()

  const hasMarketplaceFeature = useHasOrganizationFeatureToggle(MARKETPLACE)
  const hasBroadcasts = useHasOrganizationFeatureToggle(BROADCASTS)

  const showMarketplaceRoutes = hasMarketplaceFeature

  return (
    <Box expanded={sidebarExpanded} className={props.className}>
      <Links>
        <HasPermission required={[EVENT_SETTINGS, VIEW_STATISTICS]}>
          <Section title="Event" icon={<CalendarIcon />}>
            <HasPermission required={EVENT_SETTINGS}>
              <PageLink route={routes.root}>Settings</PageLink>
            </HasPermission>
            <HasPermission required={[VIEW_STATISTICS]}>
              <PageLink route={routes.statistics}>Statistics</PageLink>
            </HasPermission>
            <HasPermission required={EVENT_SETTINGS}>
              <PageLink route={routes.change_template}>Template</PageLink>
            </HasPermission>
          </Section>
        </HasPermission>
        <HasPermission required={EVENT_DESIGN}>
          <Section title="Check In" icon={<ClipboardIcon />}>
            <PageLink route={routes.password_create}>Password</PageLink>
            <PageLink route={routes.waiver.root} hasSubPage>
              Waiver
            </PageLink>
            <PageLink route={routes.tech_check}>Tech Check</PageLink>
          </Section>
          <Section title="Design" icon={<CropIcon />}>
            <PageLink route={routes.dashboard}>Dashboard</PageLink>
            <PageLink route={routes.global_styles}>Global Styles</PageLink>
            <PageLink route={routes.login}>Login</PageLink>
            <PageLink route={routes.sign_up}>Sign Up</PageLink>
            <PageLink route={routes.forgot_password}>Forgot Password</PageLink>
            <PageLink route={routes.change_password}>Change Password</PageLink>
            <PageLink route={routes.event_offline}>Offline Page</PageLink>
            <PageLink route={routes.reports}>Form Report</PageLink>
            <PageLink route={routes.scripts}>Scripts</PageLink>
          </Section>
          <Section title="Features" icon={<MegaphoneIcon />}>
            <PageLink route={routes.forms.root} hasSubPage>
              Forms
            </PageLink>
            <PageLink route={routes.speakers}>Speakers</PageLink>
            <PageLink route={routes.sponsors}>Sponsors</PageLink>
            <PageLink route={routes.faqs}>FAQ</PageLink>
            <PageLink route={routes.backgrounds}>Zoom Backgrounds</PageLink>
            <PageLink route={routes.image_entries}>Image Entries</PageLink>
            <PageLink route={routes.certificates.root} hasSubPage>
              Certificates
            </PageLink>
            <PageLink route={routes.localization}>Translations</PageLink>
          </Section>
        </HasPermission>
        <Section title="Attendees" icon={<PeopleIcon />}>
          <HasPermission required={[EVENT_SETTINGS, UPDATE_ATTENDEES]}>
            <PageLink route={routes.attendees}>Attendees</PageLink>
          </HasPermission>
          <HasPermission required={EVENT_SETTINGS}>
            <PageLink route={routes.services.root} hasSubPage>
              Services
            </PageLink>
          </HasPermission>
          <HasPermission required={EVENT_DESIGN}>
            <PageLink route={routes.points}>Points</PageLink>
          </HasPermission>
          <HasPermission required={EVENT_SETTINGS}>
            <PageLink route={routes.name_appendage}>Labels</PageLink>
          </HasPermission>
          <HasPermission required={EVENT_DESIGN}>
            <PageLink route={routes.leaderboard}>Leaderboard</PageLink>
          </HasPermission>
        </Section>
        {showMarketplaceRoutes && (
          <HasPermission required={MANAGE_MARKETPLACE}>
            <Section title="Marketplace" icon={<MarketplaceIcon />}>
              <PageLink route={routes.marketplace.tickets.root} hasSubPage>
                Tickets
              </PageLink>
              <PageLink route={routes.marketplace.purchase_page}>
                Purchase Page
              </PageLink>
              <OrganizationFeatureToggle flags={MARKETPLACE_CUSTOM_PAGE}>
                <PageLink
                  route={routes.marketplace.custom_purchase_pages.root}
                  hasSubPage
                >
                  Custom Pages
                </PageLink>
              </OrganizationFeatureToggle>
              <OrganizationFeatureToggle flags={MARKETPLACE_UPSELL}>
                <PageLink route={routes.marketplace.upsell_page}>
                  Upsell Page
                </PageLink>
              </OrganizationFeatureToggle>
              <PageLink route={routes.marketplace.thank_you_page}>
                Thank You Page
              </PageLink>
            </Section>
          </HasPermission>
        )}
        {(showMarketplaceRoutes || hasBroadcasts) && (
          <Section title="Communication" icon={<TowerCellIcon />}>
            <OrganizationFeatureToggle flags={BROADCASTS}>
              <HasPermission required={SCHEDULE_BROADCASTS}>
                <PageLink route={routes.broadcasts}>Broadcasts</PageLink>
              </HasPermission>
            </OrganizationFeatureToggle>
            <OrganizationFeatureToggle flags={BROADCASTS}>
              <HasPermission required={SCHEDULE_BROADCASTS}>
                <PageLink route={routes.welcome_email}>Welcome Email</PageLink>
              </HasPermission>
            </OrganizationFeatureToggle>
            <OrganizationFeatureToggle flags={BROADCASTS}>
              <HasPermission required={SCHEDULE_BROADCASTS}>
                <PageLink route={routes.forgot_password_email}>
                  Forgot Password Email
                </PageLink>
              </HasPermission>
            </OrganizationFeatureToggle>
            {showMarketplaceRoutes && (
              <HasPermission required={MANAGE_MARKETPLACE}>
                <PageLink route={routes.purchase_confirmations}>
                  Purchase Confirmations
                </PageLink>
              </HasPermission>
            )}
          </Section>
        )}

        <Section title="Zoom Areas" icon={<BoothIcon />}>
          <HasPermission
            required={[
              START_ROOMS,
              CHECK_IN_ATTENDEES,
              EVENT_SETTINGS,
              START_AREAS,
            ]}
          >
            <PageLink route={routes.areas.root} hasSubPage>
              Zoom Areas
            </PageLink>
          </HasPermission>
          <HasPermission required={ZOOM_ATTENDANCE}>
            <PageLink route={routes.zoom_attendance}>Attendance</PageLink>
          </HasPermission>
        </Section>
        <Section title="Production" icon={<CogIcon />}>
          <HasPermission required={EVENT_SETTINGS}>
            <IfOwnerHasPlan plan={ENTERPRISE}>
              <PageLink route={routes.webhooks}>Webhooks</PageLink>
            </IfOwnerHasPlan>
          </HasPermission>
          <HasPermission required={EVENT_SETTINGS}>
            <PageLink route={routes.emoji.settings}>Emoji Page</PageLink>
          </HasPermission>
          <HasPermission required={EVENT_SETTINGS}>
            <IfOwnerHasPlan plan={ENTERPRISE}>
              <PageLink route={routes.redirects}>Redirect Links</PageLink>
            </IfOwnerHasPlan>
            <PageLink route={routes.image_waterfall.settings}>
              Image Waterfall
            </PageLink>
          </HasPermission>
        </Section>

        <ProductionPageSection />
      </Links>
      <Bottom expanded={sidebarExpanded}>
        <CollapseButton />
        <CloseButton />
      </Bottom>
    </Box>
  )
}

function ProductionPageSection() {
  const {can} = usePermissions()
  const routes = useEventRoutes()

  // If a user has event settings we want to show them the emoji config/settings page, and NOT the
  // black emoji page directly.
  if (can(EVENT_SETTINGS)) {
    return null
  }

  // Has permission to view emoji page...
  if (!can(EMOJI_PAGE)) {
    return null
  }

  return (
    <>
      <Section
        title="Emoji Page"
        icon={<EmojiIcon />}
        route={routes.emoji.root}
        newTab
      />
      <Section
        title="Image Waterfall Page"
        icon={<ImageWaterfallIcon />}
        route={routes.image_waterfall.root}
        newTab
      />
    </>
  )
}

const Box = styled.div<{expanded: boolean}>`
  background: ${(props) => props.theme.colors.sidebar.background};
  color: #ffffff;
  flex-direction: column;
  z-index: 2;
  display: none;
  position: relative;

  width: ${(props) =>
    props.expanded ? props.theme.spacing[58] : props.theme.spacing[12]};

  // Drawer has flex column, so we need flex 1 to take full height.
  flex: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex: 0 0
      ${(props) =>
        props.expanded ? props.theme.spacing[58] : props.theme.spacing[12]};

    display: flex;
    height: calc(100vh - ${APPBAR_HEIGHT}px);
  }
`

const Links = styled.div`
  position: sticky;
  top: 0;
  font-size: 14px;
  line-height: 14px;
  color: #e7e8eb;
  max-height: 100vh;
  overflow-y: auto;

  padding-top: ${(props) => props.theme.spacing[5]};
  padding-bottom: ${(props) => props.theme.spacing[5]};
  padding-left: ${(props) => props.theme.spacing[2]};
  padding-right: ${(props) => props.theme.spacing[2]};
`

const Bottom = styled.div<{expanded: boolean}>`
  position: fixed;
  bottom: 0;

  width: ${(props) =>
    props.expanded ? props.theme.spacing[58] : props.theme.spacing[12]};
  * {
    width: 100%;
  }
`
