import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {Agenda} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList'
import {onChangeCheckedHandler, onChangeDate} from 'lib/dom'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  agenda: Agenda
  watch: UseFormMethods['watch']
}) {
  const {agenda, watch, control} = props

  const v = useAttendeeVariables()
  const startDate = watch('startDate', agenda.startDate)

  return (
    <>
      <Controller
        name="isVisible"
        defaultValue={agenda.isVisible}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
          />
        )}
      />
      <Label>Text</Label>
      <Controller
        name="text"
        defaultValue={v(agenda.text)}
        control={control}
        render={({value, onChange}) => (
          <TextField
            aria-label="agenda text"
            fullWidth
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="startDate"
        control={control}
        defaultValue={agenda.startDate}
        render={({value, onChange}) => {
          return (
            <LocalizedDateTimePicker
              value={value}
              onChange={onChangeDate(onChange)}
              fullWidth
              label="Start"
              minDate={new Date()}
              inputProps={{
                'aria-label': 'agenda start date',
              }}
            />
          )
        }}
      />
      <Controller
        name="endDate"
        control={control}
        defaultValue={agenda.endDate}
        render={({value, onChange}) => (
          <LocalizedDateTimePicker
            value={value}
            onChange={onChangeDate(onChange)}
            fullWidth
            label="End"
            minDate={startDate}
            inputProps={{
              'aria-label': 'agenda end date',
            }}
          />
        )}
      />
      <Label>Agenda Link</Label>
      <Controller
        name="link"
        defaultValue={v(agenda.link ?? '')}
        control={control}
        render={({value, onChange}) => (
          <TextField
            aria-label="agenda link"
            fullWidth
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  )
}
