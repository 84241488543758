import React from 'react'
import {HashMap} from '../../lib/list'
import {BackgroundRepeatSetting, BackgroundPosition} from '../../lib/background'
import {BlockBase} from '../base'
import {NLPurchasePageBlock} from '../../nl-purchase-page'
import {BlockComponentProps} from '../../Block'
import {SectionContext} from '../../Section'
import NLBlock from '../NLBlock'

export interface NLSectionBlock extends BlockBase {
  type: 'Section'
  background: {
    image: string
    color: string
    opacity: number
    position: BackgroundPosition
    repeat: BackgroundRepeatSetting
  }
  content: {
    background: {
      image: string
      color: string
      opacity: number
      position: BackgroundPosition
    }
    border: {
      width: number
      color: string
      radius: number
    }
  }
  margin: {
    top: number
    right: number
    bottom: number
    left: number
  }
  minHeight: number
  blocks: HashMap<NLPurchasePageBlock>
  fullWidth?: boolean
  layoutDirection?: 'row' | 'column'
  visibility: 'all' | 'mobile_only' | 'desktop_only'
  children?: React.ReactNode
}

export default function NLSection(props: NLSectionBlock & BlockComponentProps) {
  const {template, children, layoutDirection = 'column'} = props

  return (
    <SectionContext.Provider value={{calculateVisibility: () => {}}}>
      <div style={{display: 'flex', flexDirection: layoutDirection}}>
        {children ||
          Object.entries(props.blocks).map(([id, block]) => (
            <NLBlock block={block} key={id} id={id} template={template} />
          ))}
      </div>
    </SectionContext.Provider>
  )
}
