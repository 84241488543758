import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

import {
	addListToDropdown,
	createDropdown,
} from "@ckeditor/ckeditor5-ui/src/dropdown/utils";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";
import { ELEMENT_NAME, INSERT_COMMAND } from "./Value";
import {
	ViewModel,
	type ListDropdownItemDefinition,
} from 'ckeditor5/src/ui.js';
import InsertVariableCommand from "./InsertVariableCommand";

type Variable = {
	label: string,
	value: string
}
export default class EditorComponents extends Plugin {
	init() {

		const editor = this.editor;
		const t = editor.t;
		const variables = editor.config.get("obvioVariables.items") as Variable[];
		// If no merge code from the config, no need to show this.
		if (variables === null || variables.length === 0) {
			return;
		}

		// The dropdown must be registered among the UI components of the editor
		// to be displayed in the toolbar.
		editor.ui.componentFactory.add(ELEMENT_NAME, (locale) => {
			const dropdownView = createDropdown(locale);

			// Populate the list in the dropdown with items.
			addListToDropdown(dropdownView, createDropdownItems(variables));

			dropdownView.buttonView.set({
				// The t() function helps localize the editor. All strings enclosed in t() can be
				// translated and change when the language of the editor changes.
				label: t("{{ Variables }}"),
				tooltip: true,
				withText: true,
			});

			dropdownView.class = "obvio-variables-select";
			// Disable the button when the command is disabled.
			const command = editor.commands.get(INSERT_COMMAND) as InsertVariableCommand;
			dropdownView.bind("isEnabled").to(command);

			// Execute the command when the dropdown item is clicked (executed).
			this.listenTo(dropdownView, "execute", (evt) => {
				editor.execute(INSERT_COMMAND, (evt.source as any).commandParam);
				editor.editing.view.focus();
			});

			dropdownView.on('change:isOpen', (eventInfo, name, openState, oldValue) => {
				const dropdownListElement = dropdownView.listView?.element?.parentElement;
					const editorElement = editor.ui.view.element;
					if(dropdownListElement && editorElement){
						const dropdownListElementPosition = dropdownListElement.getBoundingClientRect();
						const editorElementPosition = editorElement.getBoundingClientRect();
						const listLeft = dropdownListElementPosition.left;
						const listRight = dropdownListElementPosition.right;
						const editorLeft = editorElementPosition.left;
						const editorRight = editorElementPosition.right;
				
						if (openState === false) {
							//always clear on close 
							dropdownListElement.style.left = ``;
							dropdownListElement.style.right = ``;
						} else if(listLeft < editorLeft){
							dropdownListElement.style.left = `0`;
							dropdownListElement.style.right = `auto`;
						}else if(listRight > editorRight){
							dropdownListElement.style.left = `auto`;
							dropdownListElement.style.right = `0`;
						}else{
							dropdownListElement.style.left = ``;
							dropdownListElement.style.right = ``;
						}

					}
			});

			return dropdownView;
		});
	}
}

function createDropdownItems(variables: Variable[]): Collection<ListDropdownItemDefinition> {
	const itemDefinitions: Collection<ListDropdownItemDefinition> = new Collection();

	for (const { label, value } of variables) {
		const definition: ListDropdownItemDefinition = {
			type: "button",
			model: new ViewModel({
				commandParam: value,
				label,
				withText: true,
			}),
		};

		// Add the item definition to the collection.
		itemDefinitions.add(definition);
	}

	return itemDefinitions;
}
