import {Template} from 'Event/template'
import {CARDS} from 'Event/template/Cards'
import {createBlank, DEFAULTS, Townhall} from '.'
import {
  baseProps,
  emojisFromSidebar,
  localization,
  navButtonWithSize,
} from '../converter'
import {PANELS} from '../Panels'

export function convert(template: Template): Townhall {
  return {
    name: DEFAULTS.name,
    version: DEFAULTS.version,
    ...baseProps(template),
    title: title(template),
    mainNav: mainNav(template),
    actionNav: actionNav(template),
    hero: hero(template),
    checkIn: checkIn(template),
    pageBackground: pageBackground(template),
    pageBackgroundPosition: pageBackgroundPosition(template),
    emojiList: emojiList(template),
    leaderboard: leaderboard(template),
    setPasswordForm: setPasswordForm(template),
    faq: faq(template),
    sponsors: sponsors(template),
    speakers: speakers(template),
    textColor: textColor(template),
    linkColor: linkColor(template),
    linkUnderline: linkUnderline(template),
    featuredSpeakers: featuredSpeakers(template),
    footer: footer(template),
    header: header(template),
    logoSize: logoSize(template),
    login: login(template),
    zoomBackgrounds: zoomBackgrounds(template),
    dashboardSections: dashboardSections(template),
    navBar: navBar(template),
    topBar: topBar(template),
    localization: localization(template, createBlank()),
  }
}

function topBar(template: Template): Townhall['topBar'] {
  if ('topBar' in template) {
    return template.topBar
  }

  return DEFAULTS.topBar
}

function navBar(template: Template): Townhall['navBar'] {
  if ('navBar' in template) {
    return template.navBar
  }

  return DEFAULTS.navBar
}

function dashboardSections(template: Template): Townhall['dashboardSections'] {
  if ('dashboardSections' in template) {
    return template.dashboardSections
  }

  return DEFAULTS.dashboardSections
}

function zoomBackgrounds(template: Template): Townhall['zoomBackgrounds'] {
  return template.zoomBackgrounds
}

function login(template: Template): Townhall['login'] {
  return {
    ...DEFAULTS.login,
    ...template.login,
    passwordReset: {
      ...DEFAULTS.login.passwordReset,
      ...template.login.passwordReset,
    },
  }
}

function logoSize(template: Template): Townhall['logoSize'] {
  if ('logoSize' in template) {
    return template.logoSize
  }

  return DEFAULTS.logoSize
}

function featuredSpeakers(template: Template): Townhall['speakers'] {
  if ('featuredSpeakers' in template) {
    return template.featuredSpeakers
  }

  return DEFAULTS.featuredSpeakers
}

function textColor(template: Template): Townhall['textColor'] {
  if ('textColor' in template) {
    return template.textColor ?? DEFAULTS.textColor
  }

  return DEFAULTS.textColor
}

function linkColor(template: Template): Townhall['linkColor'] {
  if ('linkColor' in template) {
    return template.linkColor ?? DEFAULTS.linkColor
  }

  return DEFAULTS.linkColor
}

function linkUnderline(template: Template): Townhall['linkUnderline'] {
  if ('linkUnderline' in template) {
    return template.linkUnderline ?? DEFAULTS.linkUnderline
  }

  return DEFAULTS.linkUnderline
}

function sponsors(template: Template): Townhall['sponsors'] {
  return {
    ...DEFAULTS.sponsors,
    ...template.sponsors,
    spacing: DEFAULTS.sponsors.spacing,
  }
}

function speakers(template: Template): Townhall['speakers'] {
  return {
    ...DEFAULTS.speakers,
    ...template.speakers,
    spacing: DEFAULTS.speakers.spacing,
  }
}

function faq(template: Template): Townhall['faq'] {
  return {
    ...DEFAULTS.faq,
    ...template.faq,
  }
}

function setPasswordForm(template: Template): Townhall['setPasswordForm'] {
  return {
    ...DEFAULTS.setPasswordForm,
    ...template.setPasswordForm,
    button: {
      ...DEFAULTS.setPasswordForm.button,
      ...template.setPasswordForm?.button,
    },
  }
}

function leaderboard(template: Template): Townhall['leaderboard'] {
  return {
    ...DEFAULTS.leaderboard,
    ...template.leaderboard,
  }
}

function emojiList(template: Template): Townhall['emojiList'] {
  if ('sidebarItems' in template) {
    return {
      emojis: emojisFromSidebar(template),
      emojiWidth: DEFAULTS.emojiList.emojiWidth,
    }
  }

  return template.emojiList
}

function pageBackgroundPosition(
  template: Template,
): Townhall['pageBackgroundPosition'] {
  if ('pageBackgroundPosition' in template) {
    return template.pageBackgroundPosition
  }

  return DEFAULTS.pageBackgroundPosition
}

function pageBackground(template: Template): Townhall['pageBackground'] {
  if ('pageBackground' in template) {
    return {
      ...DEFAULTS.pageBackground,
      ...template.pageBackground,
    }
  }

  return DEFAULTS.pageBackground
}

function checkIn(template: Template): Townhall['checkIn'] {
  if ('progressBar' in template) {
    return {
      ...DEFAULTS.checkIn,
      step1Label: template.progressBar.step1Text,
      step2Label: template.progressBar.step2Text,
      step3Label: template.progressBar.step3Text,
    }
  }

  if (template.name === PANELS) {
    return {
      ...DEFAULTS.checkIn,
      step1Label: template.step1Label,
      step1Icon: template.step1Icon ?? DEFAULTS.checkIn.step1Icon,
      step2Label: template.step2Label,
      step2Icon: template.step2Icon ?? DEFAULTS.checkIn.step2Icon,
      step3Label: template.step3Label,
      step3Icon: template.step3Icon ?? DEFAULTS.checkIn.step3Icon,
    }
  }

  return {
    ...template.checkIn,
    page:
      'page' in template.checkIn
        ? template.checkIn.page
        : DEFAULTS.checkIn.page,
  }
}

function actionNav(template: Template): Townhall['actionNav'] {
  if ('actionNav' in template) {
    return {
      ...DEFAULTS.actionNav,
      ...template.actionNav,
    }
  }

  return DEFAULTS.actionNav
}

function hero(template: Template): Townhall['hero'] {
  if ('hero' in template) {
    return {
      ...DEFAULTS.hero,
      ...template.hero,
    }
  }

  return DEFAULTS.hero
}

function title(template: Template): Townhall['title'] {
  if ('title' in template) {
    return template.title
  }

  return DEFAULTS.title
}

function footer(template: Template): Townhall['footer'] {
  if ('footer' in template) {
    return template.footer
  }

  return DEFAULTS.footer
}

function header(template: Template): Townhall['header'] {
  if ('header' in template) {
    return {
      ...DEFAULTS.header,
      ...template.header,
    }
  }

  return DEFAULTS.header
}

function mainNav(template: Template): Townhall['mainNav'] {
  if (template.name === CARDS) {
    return {
      buttons: navButtonWithSize(template.mainNav.buttons),
    }
  }

  return template.mainNav
}
