import React from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import PageHeader from 'lib/ui/PageHeader'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import Title from 'lib/ui/PageHeader/Title'
import Page from 'organization/Event/Page'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import CustomPurchasePageList from 'organization/Marketplace/PurchasePageConfig/CustomPurchasePages/CustomPurchasePageList'

export default function CustomPagesConfig() {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs page="Custom Purchase Pages">
      <Page>
        <PageHeader>
          <Title text="Custom Purchase Pages" />
          <RelativeLink
            disableStyles
            to={eventRoutes.marketplace.custom_purchase_pages.add}
          >
            <NewItemButton
              aria-label="new page"
              text="New page"
              color="primary"
            />
          </RelativeLink>
        </PageHeader>

        <CustomPurchasePageList />
      </Page>
    </PageBreadcrumbs>
  )
}
