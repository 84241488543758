import React from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import ConfigPage from 'organization/Marketplace/config/ConfigPage'
import {orderedIdsByPosition} from 'Event/Marketplace/lib/list'
import PurchasePageButtonConfig from 'organization/Marketplace/config/BlockConfig/PurchasePageButtonConfig'
import {useEvent} from 'Event/EventProvider'
import PurchasePageTemplateSelector from 'organization/Marketplace/config/TemplateSelector/PurchasePageTemplateSelector'
import {Client} from 'lib/ui/api-client'
import MissingRequiredBlocksAlert from 'organization/Marketplace/PurchasePageConfig/MissingRequiredBlocksAlert'
import {
  CustomPurchasePage,
  CustomPurchasePageTemplate,
} from 'Event/Marketplace/custom-purchase-page'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/CustomPurchasePages/CustomPurchasePageTemplateUpdateProvider'
import {useUpdateCustomPurchasePage} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-update-custom-purchase-page'
import CustomPurchasePreviewButton from './CustomPurchasePagePreviewButton'

interface ContentProps {
  customPurchasePage: CustomPurchasePage
  template: CustomPurchasePageTemplate
  client: Client
}

export default function CustomPurchasePageConfigContent(props: ContentProps) {
  const {template, customPurchasePage} = props
  const {event} = useEvent()
  const updateTemplate = useUpdateTemplate()

  const {sections} = template

  const sectionIds = orderedIdsByPosition(sections)
  const hasSections = sectionIds.length > 0

  const updatePurchasePage = useUpdateCustomPurchasePage({customPurchasePage})

  const setEnabled = (enabled: boolean) => {
    updatePurchasePage.mutateAsync({is_enabled: enabled})
  }

  if (!hasSections) {
    return <PurchasePageTemplateSelector updateTemplate={updateTemplate} />
  }

  const marketplaceStoreUrl = process.env.REACT_APP_MARKETPLACE_STORE_APP_URL

  const copyButton = {
    value: `${marketplaceStoreUrl}/${event.slug}/${customPurchasePage.slug}`,
    tooltip: 'Copy Purchase Page URL',
  }

  return (
    <>
      <ConfigPage
        template={template}
        updateTemplate={updateTemplate}
        availableBlocks={[
          'Title',
          'Text',
          'Image',
          'Video',
          'Button',
          'TicketSelector',
          'PurchaseForm',
          'BulletedList',
          'NumberedList',
          'FaqList',
          'CountdownTimer',
          'Icon',
          'Separator',
          'Grid',
          'Spacer',
        ]}
        rightToolbarItems={
          <>
            <CustomPurchasePreviewButton
              customPurchasePage={customPurchasePage}
            />
          </>
        }
        ButtonConfig={PurchasePageButtonConfig}
        copyButton={copyButton}
        enabled={customPurchasePage.is_enabled}
        setEnabled={setEnabled}
        disableEnableToggle={updatePurchasePage.isLoading}
      />
      <MissingRequiredBlocksAlert template={template} />
    </>
  )
}
