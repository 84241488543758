import {useToggleArray} from 'lib/toggle'
import React, {useCallback, useState} from 'react'
import {useDraggable, useDndMonitor} from '@dnd-kit/core'
import {useForm} from 'react-hook-form'
import {ConfigContext} from 'organization/Event/Configurable'
import TextBlockConfig from 'organization/Event/Certificates/CertificateEditor/TextBlockConfig'
import {EditComponentOverlay} from 'Event/Dashboard/editor/views/EditComponent'
import styled from 'styled-components'
import {BOLD, CAPS, ITALIC} from 'lib/ui/typography/FontStyleInput'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import {useUpdateTemplate} from 'organization/Event/Certificates/CertificateEditor'
import {Certificate, TextBlock} from 'lib/event-api/certificates/types'

interface BlockProps {
  id: string
  template: NonNullable<Certificate['template']>
  container: HTMLDivElement | null
}

export default function Block(props: BlockProps) {
  const {id, template, container} = props
  const [showingConfig, toggleConfig] = useToggleArray()
  const [element, setElement] = useState<HTMLDivElement | null>(null)

  const form = useForm()
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id,
  })

  const updateTemplate = useUpdateTemplate()

  const getElementSizeRatio = useCallback(() => {
    if (!element) {
      return null
    }

    if (!container) {
      return null
    }

    const elementWidth = element.getBoundingClientRect().width
    const elementHeight = element.getBoundingClientRect().height
    const {
      width: containerWidth,
      height: containerHeight,
    } = container.getBoundingClientRect()

    const width = 100 * (elementWidth / containerWidth)
    const height = 100 * (elementHeight / containerHeight)

    return {width, height}
  }, [element, container])

  useDndMonitor({
    onDragEnd(event) {
      if (event.active.id !== id) {
        return
      }

      if (!element) {
        return
      }

      if (!container) {
        return
      }

      const {
        width: containerWidth,
        height: containerHeight,
      } = container.getBoundingClientRect()

      const leftDelta = (100 * event.delta.x) / containerWidth
      const topDelta = (100 * event.delta.y) / containerHeight

      const size = getElementSizeRatio()

      if (!size) {
        return
      }

      updateTemplate({
        blocks: {
          [id]: {
            width: size.width,
            height: size.height,
            left: block.left + leftDelta,
            top: block.top + topDelta,
          },
        },
      })
    },
  })

  const block = template.blocks[id]
  const {left, top, text} = block

  const alignCenter = () => {
    const size = getElementSizeRatio()

    if (!size) {
      return
    }
    const left = (100 - size.width) / 2
    updateTemplate({
      blocks: {
        [id]: {
          left,
        },
      },
    })
  }

  if (!block) {
    return null
  }

  if (!container) {
    return null
  }

  const style = {
    left: `${left}%`,
    top: `${top}%`,
    ...(transform && {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    }),
  }

  return (
    <ConfigContext.Provider
      value={{showing: showingConfig, toggle: toggleConfig, form}}
    >
      <TextBlockConfig {...block} />
      <DraggableOverlay ref={setNodeRef} style={style} {...attributes}>
        <div>
          <TextBox {...block}>
            <div>
              <TextBoxContent>
                <EditComponentOverlay
                  onClick={toggleConfig}
                  onMoveCenter={alignCenter}
                  aria-label={`edit block`}
                  additionalButtons={
                    <DragHandleBox
                      aria-label="button drag handle"
                      {...listeners}
                    >
                      <DragHandleIcon />
                    </DragHandleBox>
                  }
                >
                  <div ref={setElement}>{text}</div>
                </EditComponentOverlay>
              </TextBoxContent>
            </div>
          </TextBox>
        </div>
      </DraggableOverlay>
    </ConfigContext.Provider>
  )
}

const TextBox = styled.div<TextBlock>`
  display: flex;
  font-weight: ${(props) =>
    props.font?.styles?.includes(BOLD) ? 'bold' : 'normal'};
  font-family: ${(props) => (props.font ? props.font.family : 'inherit')};
  font-style: ${(props) =>
    props.font?.styles?.includes(ITALIC) ? 'italic' : 'normal'};
  text-transform: ${(props) =>
    props.font && props.font.styles?.includes(CAPS) ? 'none' : 'uppercase'};
  color: ${(props) => (props.color ? props.color : props.color)} !important;
  font-size: ${(props) => props.fontSize}px;
  line-height: 0;
  white-space: nowrap;
  position: relative;
`

const TextBoxContent = styled.div`
  display: block;
  height: auto;
  line-height: 100%;
  bottom: 100%;
  border: 1px dashed #000000;
`

const DragHandleBox = styled.div`
  z-index: 2;
  display: none;
  border-radius: 4px;
  background: white;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const DraggableOverlay = styled.div`
  z-index: 1;
  position: absolute;
  &:hover ${DragHandleBox} {
    display: inline-flex;
  }
`
