import React from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import LinkConfig from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput/LinkConfig'
import {eventRoutes} from 'Event/Routes'
import FormControl from '@material-ui/core/FormControl'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import {Controller, UseFormMethods} from 'react-hook-form'
import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import TagFields from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/TagFields'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import WebhookFields from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/WebhookFields'
import Slider from 'lib/ui/Slider'

export const DEFAULT_BUTTON_WIDTH_PERCENT = 100

export default function Settings(props: {
  button: NavButtonWithSize
  register: UseFormMethods['register']
  watch: UseFormMethods['watch']
  control: UseFormMethods['control']
  setValue: UseFormMethods['setValue']
}) {
  const {button} = props

  const {register, watch, control, setValue} = props

  /**
   * Pages the button can navigate to. We only want to allow tech-check
   * buttons to nativate to self check-in page.
   */
  const pages = {
    [eventRoutes.techCheck]: 'Check-In',
  }

  const revealDelaySecs = watch('revealDelaySecs', button.revealDelaySecs)

  return (
    <>
      <ContentInput
        button={button}
        control={control}
        register={register}
        watch={watch}
      />
      <Controller
        name="isAreaButton"
        defaultValue={button.isAreaButton}
        control={control}
        render={({value, onChange}) => {
          const targetValue = value ? 1 : 0
          return (
            <FormControl>
              <ToggleButtonGroup value={targetValue} exclusive>
                <ToggleButton
                  value={0}
                  onClick={() => {
                    onChange(false)
                  }}
                >
                  Link
                </ToggleButton>
                <ToggleButton
                  value={1}
                  aria-label="set join tech check area"
                  onClick={() => {
                    onChange(true)
                  }}
                >
                  Join Tech Check
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          )
        }}
      />
      <LinkConfig
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        button={button}
        pages={pages}
      />
      <TagFields button={button} control={control} />

      <Controller
        name="revealDelaySecs"
        control={control}
        defaultValue={button.revealDelaySecs ?? null}
        render={({value, onChange}) => (
          <Switch
            checked={value !== null}
            onChange={(_, checked) => onChange(checked ? 30 : null)}
            arial-label="toggle delay reveal"
            labelPlacement="end"
            label="Delay Reveal"
          />
        )}
      />
      {revealDelaySecs !== null && (
        <Controller
          name="revealDelaySecs"
          defaultValue={button.revealDelaySecs || 30}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={300}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Delay time"
              unit="s"
            />
          )}
        />
      )}

      <Controller
        name="confirmRequired"
        control={control}
        defaultValue={button.confirmRequired || false}
        render={({value, onChange}) => (
          <Switch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="toggle confirmation required"
            labelPlacement="end"
            label="Confirmation Required"
          />
        )}
      />
      <WebhookFields
        button={button}
        control={control}
        register={register}
        watch={watch}
      />
    </>
  )
}
