import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useForm} from 'react-hook-form'
import Page from 'organization/Event/Page'
import {ValidationError} from 'lib/ui/api-client'
import {CustomPurchasePageBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import Form, {
  CustomPurchasePageFormData,
} from 'organization/Marketplace/PurchasePageConfig/CustomPurchasePages/Form/Form'
import {
  CreateCustomPageData,
  useCreateCustomPurchasePage,
} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-create-custom-purchase-page'

export default function CreateCustomPurchasePageForm() {
  const history = useHistory()
  const eventRoutes = useEventRoutes()
  const {
    mutate: createCustomPage,
    isLoading: isCreatingCustomPage,
  } = useCreateCustomPurchasePage()
  const {errors: formErrors, handleSubmit, control, setValue} = useForm()
  const [responseError, setResponseError] = useState<
    ValidationError<CreateCustomPageData>
  >(null)

  const submit = (formData: CustomPurchasePageFormData) => {
    createCustomPage(formData, {
      onError: (error: any) => {
        setResponseError(error)
      },
      onSuccess: () => {
        history.push(eventRoutes.marketplace.custom_purchase_pages.root)
      },
    })
  }

  return (
    <CustomPurchasePageBreadcrumbs page="Add">
      <Page>
        <Form
          control={control}
          formErrors={formErrors}
          onSubmit={handleSubmit(submit)}
          responseError={responseError}
          setValue={setValue}
          submitting={isCreatingCustomPage}
        />
      </Page>
    </CustomPurchasePageBreadcrumbs>
  )
}
