import {useEvent} from 'Event/EventProvider'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'
import {Property} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'

export interface CreateTicketData {
  account_id?: number
  name: string
  slug: string
  title: string
  description: string
  tags: string[]
  groups: string[]
  price: number
  available_from: string
  available_to: string
  hidden?: boolean
  active?: boolean
  infusionsoft_tag_id?: number
  infusionsoft_tag_name?: string
  mailchimp_tag_name?: string
  convert_kit_tag_id?: number
  convert_kit_tag_name?: string
  ontraport_tag_id?: number
  ontraport_tag_name?: string
  active_campaign_tag_name?: string
  active_campaign_tag_id?: number
  highlevel_tag_name?: string
  hubspot_tag_value?: string | null
  hubspot_tag_name?: string | null
  hubspot_tag_label?: string | null
  hubspot_tag_type?: Property['type'] | null
}

export function useCreateTicket() {
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: CreateTicketData) => {
      const url = marketplaceApi(`/events/${event.id}/tickets`)
      return client!.post<MarketplaceTicket>(url, data)
    },
    onSuccess: (ticket) => {
      qc.setQueryData<MarketplaceTicket[]>(
        ['events', event.id, 'tickets'],
        (tickets) => {
          if (!tickets) {
            return [ticket]
          }

          return [...tickets, ticket]
        },
      )

      qc.setQueryData<MarketplaceTicket>(['tickets', ticket.id], () => ticket)
    },
  })
}
