import React from 'react'
import {useDebouncedHandler} from 'lib/debounce'
import {onChangeStringHandler} from 'lib/dom'
import {
  FormQuestionProps,
  StyledTextField,
  useObieQuestions,
} from 'organization/Obie/ObieQuestionsProvider'

export default function LongForm(props: FormQuestionProps) {
  const DEBOUNCE_DELAY_MS = 500

  const {question, setDirty} = props
  const {currentAnswer, setCurrentAnswer} = useObieQuestions()

  const handleChange = (answer: string) => {
    setCurrentAnswer(answer)
    setDirty && setDirty()
  }

  const [localAnswer, setLocalAnswer] = useDebouncedHandler(
    currentAnswer || '',
    handleChange,
    DEBOUNCE_DELAY_MS,
  )

  return (
    <StyledTextField
      value={localAnswer}
      fullWidth
      multiline
      onChange={onChangeStringHandler(setLocalAnswer)}
      placeholder={question.example_text}
      required={question.required}
      rows={12}
      variant="filled"
    />
  )
}
