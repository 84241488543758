import ColorPicker from 'lib/ui/ColorPicker'
import Box from '@material-ui/core/Box'
import Slider from 'lib/ui/Slider'
import React, {useEffect, useState} from 'react'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import ComponentConfig, {
  SaveButton,
} from 'organization/Event/DashboardConfig/ConfigDialog'
import {useToggle} from 'lib/toggle'
import {Controller, useForm} from 'react-hook-form'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {Template} from 'Event/template'
import ConfirmUnsavedChangesDialog from 'organization/Event/Configurable/ConfirmUnsavedChangesDialog'

export default function WaiverPostFormStylesConfig() {
  const {flag: showingConfig, toggle: toggleConfig} = useToggle()

  const {waiverPostFormStyles, postFormStyles} = useTemplate()
  const defaultValues = waiverPostFormStyles ?? postFormStyles

  const update = useSaveTemplate()
  const {
    control,
    handleSubmit,
    reset,
    formState: {isDirty},
  } = useForm()
  const [
    showingUnsavedChangesDialog,
    setShowingUnsavedChangesDialog,
  ] = useState(false)

  const submit = (data: Template['waiverPostFormStyles']) => {
    update({
      waiverPostFormStyles: data,
    })

    toggleConfig()
  }

  const onClose = () => {
    if (isDirty) {
      setShowingUnsavedChangesDialog(true)
    } else {
      toggleConfig()
    }
  }

  useEffect(() => {
    if (!showingConfig) {
      reset()
      setShowingUnsavedChangesDialog(false)
    }
  }, [reset, showingConfig])

  return (
    <>
      <StyledEditPostFormStylesButton onClick={toggleConfig} />
      <ComponentConfig
        isVisible={showingConfig}
        onClose={onClose}
        title="Post Form Styles"
      >
        <ConfirmUnsavedChangesDialog
          onDiscard={toggleConfig}
          onCancel={() => setShowingUnsavedChangesDialog(false)}
          open={showingUnsavedChangesDialog}
          onSubmit={handleSubmit(submit)}
        />
        <form onSubmit={handleSubmit(submit)}>
          <Controller
            name="inputStyles.labelColor"
            defaultValue={defaultValues.inputStyles.labelColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Label Text Color"
                color={value}
                onPick={onChange}
                aria-label="label text color"
              />
            )}
          />
          <Controller
            name="inputStyles.borderColor"
            defaultValue={defaultValues.inputStyles.borderColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Input Border Color"
                color={value}
                onPick={onChange}
                aria-label="input box border color"
              />
            )}
          />
          <Controller
            name="inputStyles.backgroundColor"
            defaultValue={defaultValues.inputStyles.backgroundColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Input Background Color"
                color={value}
                onPick={onChange}
                aria-label="input box background color"
              />
            )}
          />
          <Box>
            <Controller
              name="inputStyles.backgroundOpacity"
              defaultValue={defaultValues.inputStyles.backgroundOpacity}
              control={control}
              render={({value, onChange}) => (
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  onChange={onChange}
                  valueLabelDisplay="auto"
                  value={value}
                  label="Input Background Color Opacity"
                  unit="%"
                />
              )}
            />
          </Box>
          <Controller
            name="inputStyles.textColor"
            defaultValue={defaultValues.inputStyles.textColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Input Text Color"
                color={value}
                onPick={onChange}
                aria-label="input box text color"
              />
            )}
          />
          <Controller
            name="inputStyles.helperTextColor"
            defaultValue={defaultValues.inputStyles.helperTextColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Helper Text Color"
                color={value}
                onPick={onChange}
                aria-label="helper text color"
              />
            )}
          />

          <SaveButton />
        </form>
      </ComponentConfig>
    </>
  )
}

function EditPostFormStylesButton(props: {
  onClick: () => void
  className?: string
}) {
  return (
    <Button
      className={props.className}
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      aria-label="style post form"
      onClick={props.onClick}
    >
      Edit Post Form Styles
    </Button>
  )
}

const StyledEditPostFormStylesButton = styled(EditPostFormStylesButton)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
`
