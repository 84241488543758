import React from 'react'
import Button from 'lib/ui/Button'
import styled from 'styled-components'
import Icon from 'lib/ui/Icon'
import {JsonSave} from 'lib/JsonUpdateProvider'
import {NLPurchasePageTemplate} from 'Event/Marketplace/nl-purchase-page'
import {generateHashId} from 'lib/crypto/hash'
import {createSectionBlock} from 'Event/Marketplace/Block/NLSectionBlock/create-section-block'

export default function NLAddSectionButton(props: {
  updateTemplate: JsonSave<NLPurchasePageTemplate>
}) {
  const {updateTemplate} = props
  const addSection = () => {
    generateHashId([
      'section',
      Date.now().toString(),
      Math.random().toString(),
    ]).then((id) => {
      updateTemplate({
        blocks: {
          [id]: createSectionBlock(),
        },
      })
    })
  }

  return (
    <Box>
      <StyledButton
        variant="contained"
        fullWidth
        onClick={addSection}
        size="large"
        startIcon={<Icon className="fa-solid fa-circle-plus" />}
      >
        Add Section
      </StyledButton>
    </Box>
  )
}

const Box = styled.div`
  margin: 3rem 0;
  padding: ${(props) => `${props.theme.spacing[6]} ${props.theme.spacing[3]}`};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  max-width: 300px;
  color: white;
  background-color: #1e4686;
  &:hover {
    background-color: #1e4686;
    opacity: 0.9;
  }
`
