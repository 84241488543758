import {useEvent} from 'Event/EventProvider'
import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

interface DeleteCustomPurchasePageParams {
  customPurchasePage: CustomPurchasePage
}

export function useDeleteCustomPurchasePage(
  params: DeleteCustomPurchasePageParams,
) {
  const {customPurchasePage: removed} = params
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: () => {
      const url = marketplaceApi(`/purchase_pages/${removed.id}`)
      return client!.delete<CustomPurchasePage>(url)
    },
    onSuccess: () => {
      qc.setQueryData<CustomPurchasePage[]>(
        ['events', event.id, 'custom_purchase_pages'],
        (purchasePages) => {
          if (!purchasePages) {
            return []
          }

          return purchasePages.filter(
            (purchasePage) => purchasePage.id !== removed.id,
          )
        },
      )
    },
  })
}
