import React from 'react'
import {Speaker} from 'Event/Speakers'
import SpeakerConfig from 'Event/Speakers/SpeakerConfig'
import {useRemoveTranslations} from 'Event/LanguageProvider'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'

interface NiftyFiftySpeakerConfigProps {
  id: string
  speaker: Speaker
}

export default function NiftyFiftySpeakerConfig(
  props: NiftyFiftySpeakerConfigProps,
) {
  const {id, speaker} = props

  const removeTranslations = useRemoveTranslations()
  const set = useSaveTemplate()
  const template = useNiftyFiftyTemplate()

  const handleRemove = () => {
    set({
      speakers: {
        items: {
          [id]: REMOVE,
        },
      },
      localization: removeTranslations([`${id}_name`, `${id}_text`]),
    })

    // Because NiftyFifty agendas could contain a list of speaker ids, we need
    // to remove the speaker from any agendas too.
    for (const [agendaId, agenda] of Object.entries(
      template.agendaList.items,
    )) {
      if (agenda.speakers.includes(id)) {
        set({
          agendaList: {
            items: {
              [agendaId]: {
                speakers: agenda.speakers.filter((s) => s !== id),
              },
            },
          },
        })
      }
    }
  }
  return <SpeakerConfig speaker={speaker} id={id} onRemove={handleRemove} />
}
