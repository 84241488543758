import React, {useState} from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {fieldError} from 'lib/form'
import {ValidationError} from 'lib/ui/api-client'
import DescribedTextField from 'lib/ui/TextField/DescribedTextField'
import {CreateCustomPageData} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-create-custom-purchase-page'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import PageHeaderButton from 'lib/ui/PageHeader/Button'
import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'

export interface CustomPurchasePageFormData {
  name: string
  slug: string
}

export default function Form(props: {
  control: UseFormMethods['control']
  formErrors: UseFormMethods['errors']
  isEditing?: boolean
  onSubmit: () => void
  responseError: ValidationError<CreateCustomPageData>
  setValue: UseFormMethods['setValue']
  submitting: boolean
  customPage?: CustomPurchasePage
}) {
  const {
    formErrors,
    isEditing,
    onSubmit,
    responseError,
    setValue,
    submitting,
    customPage,
  } = props

  const [slugDirty, setSlugDirty] = useState<boolean>(Boolean(isEditing))

  const error = (key: keyof CreateCustomPageData) =>
    fieldError(key, {
      form: formErrors,
      response: responseError,
    })

  const errors = {
    name: error('name'),
    slug: error('slug'),
  }

  const slugValue = (value: string) => {
    if (slugDirty) {
      return
    }

    const cleanedSlug = value
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^0-9a-z-]/gi, '')

    setValue('slug', cleanedSlug)
  }

  return (
    <>
      <PageHeader>
        <Title text={formTitle(customPage)} />
        <PageHeaderButton
          disabled={submitting}
          onClick={onSubmit}
          text={formButtonText(customPage)}
        />
      </PageHeader>

      <form onSubmit={onSubmit}>
        <Controller
          name="name"
          control={props.control}
          defaultValue={customPage?.name || ''}
          rules={{
            required: 'Name is required',
          }}
          render={({onChange, value}) => (
            <DescribedTextField
              title="Name *"
              aria-label="custom page name"
              description={'Your custom page name'}
              name="name"
              required
              fullWidth
              disabled={submitting}
              value={value}
              onChange={(value) => {
                slugValue(value)
                onChange(value)
              }}
              helperText={errors.name}
              error={Boolean(errors.name)}
            />
          )}
        />
        <Controller
          name="slug"
          defaultValue={customPage?.slug || ''}
          control={props.control}
          rules={{
            required: 'Custom Page Slug is required',
          }}
          render={({onChange, value}) => (
            <DescribedTextField
              title="Slug *"
              aria-label="custom Page slug"
              description={"The value for the custom Page's URL"}
              name="slug"
              required
              fullWidth
              disabled={submitting}
              value={value}
              onChange={(value) => {
                setSlugDirty(true)
                onChange(value)
              }}
              helperText={errors.slug}
              error={Boolean(errors.slug)}
            />
          )}
        />
      </form>
    </>
  )
}

const formTitle = (custom_purchase_page: CustomPurchasePage | undefined) => {
  return custom_purchase_page
    ? 'Modify Custom Purchase Page'
    : 'Add New Custom Purchase Page'
}

const formButtonText = (
  custom_purchase_page: CustomPurchasePage | undefined,
) => {
  return custom_purchase_page
    ? 'Save Custom Purchase Page'
    : 'Create Custom Purchase Page'
}
