import React, {useCallback} from 'react'
import JsonUpdateProvider, {JsonSave} from 'lib/JsonUpdateProvider'
import {Client} from 'lib/ui/api-client'
import {useQueryClient} from 'react-query'
import {useEvent} from 'Event/EventProvider'
import {
  CustomPurchasePage,
  CustomPurchasePageTemplate,
} from 'Event/Marketplace/custom-purchase-page'
import {useUpdateCustomPurchasePageTemplate} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-update-custom-purchase-page-template'

const TemplateUpdateContext = React.createContext<
  JsonSave<CustomPurchasePage['template']> | undefined
>(undefined)

interface CustomPurchasePageTemplateUpdateProviderProps {
  children: (template: CustomPurchasePageTemplate) => JSX.Element
  customPurchasePage: CustomPurchasePage
  client: Client
}

export default function CustomPurchasePageTemplateUpdateProvider(
  props: CustomPurchasePageTemplateUpdateProviderProps,
) {
  const {children, customPurchasePage, client} = props

  const updateTemplate = useUpdateCustomPurchasePageTemplate({
    client,
  })
  const {event} = useEvent()

  const qc = useQueryClient()

  const updateCachedTemplate = useCallback(
    (template: CustomPurchasePageTemplate) => {
      qc.setQueryData<CustomPurchasePage | undefined>(
        ['events', event.id, 'custom_purchase_pages', customPurchasePage.id],
        (customPurchasePage) => {
          if (!customPurchasePage) {
            return
          }

          return {
            ...customPurchasePage,
            template,
          }
        },
      )
    },
    [qc, event, customPurchasePage],
  )

  const template = Array.isArray(customPurchasePage.template.sections)
    ? {
        ...customPurchasePage.template,
        sections: {},
      }
    : customPurchasePage.template

  return (
    <JsonUpdateProvider
      Context={TemplateUpdateContext}
      updatedSocketEventName={`.custom_purchase_page.${customPurchasePage.id}.updated`}
      value={template}
      save={(updates) =>
        updateTemplate.mutateAsync({customPurchasePage, updates})
      }
      onUpdate={updateCachedTemplate}
    >
      {(template) => children(template)}
    </JsonUpdateProvider>
  )
}

export function useUpdateTemplate() {
  const context = React.useContext(TemplateUpdateContext)
  if (context === undefined) {
    throw new Error(
      'useUpdateTemplate must be used within CustomPurchasePageTemplateUpdateProvider.',
    )
  }

  return context
}
