import styled from 'styled-components'
import React from 'react'
import {useLeftyTemplate} from 'Event/template/Lefty'
import Grid from '@material-ui/core/Grid'
import {rgba} from 'lib/color'
import Configurable from 'organization/Event/Configurable'
import RightPanelConfig from 'Event/template/Lefty/Login/RightPanel/RightPanelConfig'

export default function RightPanel(props: {
  children: React.ReactElement | React.ReactElement[]
}) {
  const {login, textColor} = useLeftyTemplate()

  const backgroundColor = rgba(
    login.rightPanel.backgroundColor,
    login.rightPanel.backgroundOpacity / 100,
  )

  return (
    <RightGrid item xs={12} md={6} backgroundColor={backgroundColor}>
      <Configurable aria-label="login right panel config">
        <RightPanelConfig />
        <Container
          textColor={textColor}
          loginTextColor={login.description.color}
        >
          {props.children}
        </Container>
      </Configurable>
    </RightGrid>
  )
}

const Container = styled.div<{
  textColor: string
  loginTextColor: string | null
}>`
  color: ${(props) =>
    props.loginTextColor ? props.loginTextColor : props.textColor} !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
`

const RightGrid = styled((props) => {
  const {backgroundColor: _, ...otherProps} = props
  return <Grid {...otherProps} />
})<{backgroundColor?: string}>`
  display: flex;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ffffff'};
  padding-left: ${(props) => props.theme.spacing[10]};
  padding-right: ${(props) => props.theme.spacing[10]};
  padding-top: ${(props) => props.theme.spacing[10]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 0;
  }
`
