import {PurchasePageTemplate} from '../purchase-page'
import {withDefaults} from './object'

export function fillPurchasePageTemplate(template: PurchasePageTemplate) {
  const backgroundDefaults: PurchasePageTemplate['background'] = {
    image: '',
    color: '',
    opacity: 0,
    position: 'center center',
    repeat: 'none',
  }
  const favicon: PurchasePageTemplate['favicon'] = null as string | null
  const typography: PurchasePageTemplate['typography'] = {
    font: null,
    fontSize: 20,
    textColor: '#000000',
  }

  return withDefaults(
    {
      sections: {},
      blocks: {},
      background: backgroundDefaults,
      padding: {top: 0, right: 0, bottom: 0, left: 0},
      title: '',
      description: '',
      headHtml: '',
      footerHtml: '',
      favicon,
      typography,
      contentMaxWidth: 1280,
    },
    template,
  )
}
