import {onUnknownChangeHandler} from 'lib/dom'
import styled from 'styled-components'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Label} from 'lib/ui/typography'
import ColorPicker from 'lib/ui/ColorPicker'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import BackgroundPositionSelector from 'lib/ui/BackgroundPositionSelector'
import PositionSelectorButton from 'lib/ui/BackgroundPositionSelector/BackgroundPositionSelectorButton'
import {usePruneAssets} from 'lib/asset'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import {
  Footer,
  SaveButton,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import ConfirmRemoveButton from 'lib/ui/Button/ConfirmRemoveButton'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useToggleArray} from 'lib/toggle'
import BackgroundRepeatSelector from 'lib/ui/BackgroundRepeatSelector'
import {useTemplateEditor} from 'organization/Event/TemplateEditor'
import VisibilitySetting from './BlockConfig/VisibilitySettings'
import {NLSectionBlock} from 'Event/Marketplace/Block/NLSectionBlock'
import {TargetBlockConfigBaseProps} from 'organization/Marketplace/config/BlockConfig/NLBlockConfig'

interface SectionConfigProps
  extends NLSectionBlock,
    TargetBlockConfigBaseProps {}

export default function NLSectionConfig(props: SectionConfigProps) {
  const {
    id,
    padding,
    margin,
    minHeight,
    fullWidth,
    background,
    content,
    onSave,
  } = props

  const [visibility, setVisibility] = useState(props.visibility)

  const pruneAssets = usePruneAssets()
  const {
    form: {control, watch},
  } = useConfig()

  const [showingBackgroundOptions, toggleBackgroundOptions] = useToggleArray()
  const [
    showingContentBackgroundOptions,
    toggleContentBackgroundOptions,
  ] = useToggleArray()
  const sectionFullWidth = watch(`fullWidth`, fullWidth)

  const handleSave = (updates: any) => {
    if (typeof updates.background === 'object') {
      pruneAssets({image: updates.background.image}, background)
    }

    updates['visibility'] = visibility
    onSave(updates)
  }

  const handleRemove = () => {
    onSave(REMOVE)
  }

  return (
    <Config
      title="Section"
      onSave={handleSave}
      footer={<ConfigFooter onRemove={handleRemove} />}
    >
      <SettingsPanel>
        <Controller
          name="layoutDirection"
          defaultValue={props.layoutDirection || 'column'}
          control={control}
          render={({value, onChange}) => (
            <Select
              fullWidth
              label="Layout Direction"
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
            >
              <Option value="column">Vertical (Column)</Option>
              <Option value="row">Horizontal (Row)</Option>
            </Select>
          )}
        />
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Content"
          storageKey={`sections-${id}-content`}
        >
          <Controller
            name={`minHeight`}
            defaultValue={minHeight}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Minimum height"
                min={0}
                max={1500}
                step={1}
                onChange={onChange}
                valueLabelDisplay="auto"
                value={value}
                aria-label="minimum height"
                unit="px"
              />
            )}
          />

          <Controller
            name={`fullWidth`}
            defaultValue={fullWidth ?? false}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Content Width"
                value={value === true ? 'full' : 'centered'}
                onChange={onUnknownChangeHandler((width) => {
                  onChange(width === 'full')
                })}
              >
                <Option value="centered">Constrained</Option>
                <Option value="full">Full Width</Option>
              </Select>
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Background"
          storageKey={`sections-${id}-background`}
        >
          <Controller
            name={`background.image`}
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'section background input',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
                additionalActions={
                  <PositionSelectorButton onClick={toggleBackgroundOptions} />
                }
              />
            )}
          />
          <Controller
            name={`background.position`}
            control={control}
            defaultValue={background.position}
            render={({onChange, value}) => (
              <BackgroundPositionSelector
                showing={showingBackgroundOptions}
                onChange={onChange}
                value={value}
                label="Background Position"
              />
            )}
          />
          <Controller
            name={`background.repeat`}
            defaultValue={background.repeat}
            control={control}
            render={({value, onChange}) => (
              <BackgroundRepeatSelector
                showing={showingBackgroundOptions}
                onChange={onChange}
                value={value}
                label="Background Repeat"
              />
            )}
          />
          <Controller
            name={`background.color`}
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="section background color"
              />
            )}
          />
          <Controller
            name={`background.opacity`}
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="section background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Content Background"
          storageKey={`sections-${id}-content-background`}
          hidden={sectionFullWidth}
        >
          <Controller
            name={`content.background.image`}
            control={control}
            defaultValue={content?.background?.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'section content background input',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
                additionalActions={
                  <PositionSelectorButton
                    onClick={toggleContentBackgroundOptions}
                  />
                }
              />
            )}
          />
          <Controller
            name={`content.background.position`}
            control={control}
            defaultValue={content?.background?.position}
            render={({onChange, value}) => (
              <BackgroundPositionSelector
                showing={showingContentBackgroundOptions}
                onChange={onChange}
                value={value}
                label="Background Position"
              />
            )}
          />
          <Controller
            name={`content.background.color`}
            defaultValue={content?.background?.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="section content background color"
              />
            )}
          />
          <Controller
            name={`content.background.opacity`}
            defaultValue={content?.background?.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="section content background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Content Border"
          storageKey={`sections-${id}-content-border`}
          hidden={sectionFullWidth}
        >
          <Controller
            name={`content.border.color`}
            control={control}
            defaultValue={content?.border?.color}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`content.border.width`}
            defaultValue={content?.border?.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Width"
                min={0}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
              />
            )}
          />
          <Controller
            name={`content.border.radius`}
            defaultValue={content?.border?.radius || 0}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Radius"
                min={0}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`sections-${id}-spacing`}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`padding`}
            values={padding}
          />

          <Label>Margin</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`margin`}
            values={margin}
            settings={{min: -100}}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

function ConfigFooter(props: {onRemove: () => void}) {
  const {clear: clearUpdates} = useTemplateEditor()

  return (
    <Footer>
      <SaveButton />
      <StyledRemoveButton
        onClick={() => {
          clearUpdates()
          props.onRemove()
        }}
        fullWidth
        variant="outlined"
        aria-label="remove"
        confirmDescription="Are you sure you want to delete this section? Please note that deleting this section will also permanently remove all the content blocks within it. This action cannot be undone."
      >
        REMOVE
      </StyledRemoveButton>
    </Footer>
  )
}

const StyledRemoveButton = styled(ConfirmRemoveButton)`
  margin-left: ${(props) => props.theme.spacing[2]}!important;
  margin-right: ${(props) => props.theme.spacing[2]}!important;
`
