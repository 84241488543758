import {Client, ValidationError} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import {Organization} from 'organization'
import Page from 'lib/ui/layout/Page'
import {ObvioEvent} from 'Event'
import Layout from 'organization/user/Layout'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import Form, {
  CreateEventData,
} from 'organization/EventList/CreateEventForm/Form'
import {useFileSelect} from 'lib/ui/form/file'
import {usePlanLimits} from 'obvio/PlanLimitsProvider'

export default function CreateEventForm() {
  const {errors: formErrors, handleSubmit, watch, control, register} = useForm()
  const slug = watch('slug')
  const systemDomainId = watch('system_domain_id')
  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState<
    ValidationError<CreateEventData>
  >(null)
  const history = useHistory()
  const {routes, organization, client} = useOrganization()
  const {checkPlanLimits} = usePlanLimits()

  const favicon = useFileSelect(null)
  const logo = useFileSelect(null)

  useBreadcrumbs([
    {title: 'Events', url: routes.events.root},
    {title: 'Create', url: routes.events.create},
  ])

  const goToEvents = () => {
    history.push(routes.events.root)
  }

  const data = (form: CreateEventData) => {
    const formData = new FormData()
    for (const [key, value] of Object.entries(form)) {
      // Skip `null` values, as FormData sends them as
      // strings,
      if (value === null) {
        continue
      }

      formData.set(key, String(value))
    }

    if (favicon.selected) {
      formData.set('favicon', favicon.selected)
    }

    if (logo.selected) {
      formData.set('logo', logo.selected)
    }

    return formData
  }

  const submit = (form: CreateEventData) => {
    setSubmitting(true)
    createEvent(client, organization, data(form))
      .then(() => {
        goToEvents()
      })
      .catch((error) => {
        checkPlanLimits(error)
        setResponseError(error)
        setSubmitting(false)
      })
  }

  return (
    <Layout>
      <Page>
        <Form
          onSubmit={handleSubmit(submit)}
          submitting={submitting}
          slug={slug}
          systemDomainId={systemDomainId}
          formErrors={formErrors}
          responseError={responseError}
          control={control}
          favicon={favicon}
          logo={logo}
          register={register}
        />
      </Page>
    </Layout>
  )
}

function createEvent(
  client: Client,
  organization: Organization,
  data: FormData,
) {
  const url = api(`/organizations/${organization.id}/events`)
  return client.post<ObvioEvent>(url, data)
}
