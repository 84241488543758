import React, {
  useEffect,
  useRef,
  createContext,
  useState,
  useContext,
} from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {orderedIdsByPosition, createPositions} from 'lib/list'
import {ConfigContext} from 'organization/Event/Configurable'
import {useToggleArray} from 'lib/toggle'
import {get, useForm} from 'react-hook-form'
import PageSettingsButton from 'organization/Marketplace/config/PageSettingsButton'
import styled from 'styled-components'
import {Typography} from '@material-ui/core'
import Container from 'Event/Marketplace/Container'
import PageSettings from 'organization/Marketplace/config/PageSettings'
import {JsonSave, REMOVE} from 'lib/JsonUpdateProvider'
import ToggleMobileModeSwitch from 'organization/Marketplace/config/ToggleMobileModeSwitch'
import MobileModeContainer from 'organization/Marketplace/config/MobileModeContainer'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import EnabledSwitch from 'organization/Marketplace/config/EnabledSwitch'
import NLAddSectionButton from 'organization/Marketplace/config/NLAddSectionButton'
import NLConfigurableBlock from 'organization/Marketplace/config/NLConfigurableBlock'
import {
  NLPurchasePageBlock,
  NLPurchasePageTemplate,
} from 'Event/Marketplace/nl-purchase-page'
import {DragProvider} from 'organization/Marketplace/config/DragContext'
import set from 'lodash/set'
import {DeepPartialSubstitute} from 'lib/type-utils'
import {movedBlockBetweenSectionEvent} from 'organization/Marketplace/config/NLConfigurableSection'

// Create a hover context
export interface HoverContextType {
  hoveredBlockId: string | null
  setHoveredBlockId: (id: string | null) => void
}

export const HoverContext = createContext<HoverContextType>({
  hoveredBlockId: null,
  setHoveredBlockId: () => {},
})

export const useHoverContext = () => useContext(HoverContext)

interface ConfigPageProps {
  template: NLPurchasePageTemplate
  updateTemplate: JsonSave<NLPurchasePageTemplate>
  footer?: React.ReactNode
  availableBlocks: Array<NLPurchasePageBlock['type']>
  rightToolbarItems: JSX.Element
  ButtonConfig: React.FC<any>
  copyButton?: {
    value: string
    tooltip: string
  }
  enabled?: boolean
  setEnabled?: (enabled: boolean) => void
  disableEnableToggle?: boolean
  isUpsellPage?: boolean
}

export default function NLConfigPage(props: ConfigPageProps) {
  const {
    template,
    updateTemplate,
    footer,
    availableBlocks,
    rightToolbarItems,
    ButtonConfig,
    copyButton,
    enabled,
    setEnabled,
    disableEnableToggle,
  } = props

  if (!('blocks' in template)) {
    throw new Error(
      'NLConfigPage called with wrong template. It should be NLPurchasePageTemplate',
    )
  }

  const [showingConfig, toggleConfig] = useToggleArray()
  const form = useForm()
  const containerRef = useRef<HTMLDivElement>(null)

  const blocks = orderedIdsByPosition(template.blocks || {})

  // Listen for cross-section block move events
  useEffect(() => {
    const handleMoveBlockBetweenSections = (event: {
      detail: {
        insertIndex: number
        targetSectionPath: string
        sourceBlockPath: string
      }
    }) => {
      const {insertIndex, targetSectionPath, sourceBlockPath} = event.detail

      const sourceBlock = get(template, sourceBlockPath)
      const targetSection = get(template, targetSectionPath)
      const sourceBlockId = sourceBlockPath.split('.').pop()
      if (!sourceBlock || !targetSection || !sourceBlockId) {
        return
      }

      const moved = Array.from(orderedIdsByPosition(targetSection.blocks))
      moved.splice(insertIndex, 0, sourceBlockId)
      const updatedPositions = createPositions(moved)

      const payload: DeepPartialSubstitute<
        NLPurchasePageTemplate,
        typeof REMOVE
      > = {}
      // Remove block from source section
      set(payload, sourceBlockPath, REMOVE)
      // set new positions in target section
      set(payload, `${targetSectionPath}.blocks`, updatedPositions)
      // add block to target section
      set(payload, `${targetSectionPath}.blocks.${sourceBlockId}`, {
        ...sourceBlock,
        position: insertIndex + 1,
      })

      updateTemplate(payload)
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener(
        movedBlockBetweenSectionEvent,
        (handleMoveBlockBetweenSections as unknown) as EventListener,
      )
    }

    return () => {
      if (container) {
        container.removeEventListener(
          movedBlockBetweenSectionEvent,
          (handleMoveBlockBetweenSections as unknown) as EventListener,
        )
      }
    }
  }, [updateTemplate, template])

  const handleCopyUrl = () => {
    if (!copyButton) return
    navigator.clipboard.writeText(copyButton.value)
  }

  // Add hover state management
  const [hoveredBlockId, setHoveredBlockId] = useState<string | null>(null)

  return (
    <Page
      toolbarItems={
        <>
          <EnabledSwitch
            enabled={enabled}
            setEnabled={setEnabled}
            disabled={disableEnableToggle}
          />

          <PageSettingsButton onClick={toggleConfig} />
        </>
      }
      rightToolbarItems={
        <RightToolbarItems>
          <ToggleMobileModeSwitch />
          {copyButton && (
            <StyledCopyIconButton
              aria-label="copy button"
              beforeCopyTooltip={copyButton.tooltip}
              onClick={handleCopyUrl}
            />
          )}
          {rightToolbarItems}
        </RightToolbarItems>
      }
    >
      <ConfigContext.Provider
        value={{showing: showingConfig, toggle: toggleConfig, form}}
      >
        <PageSettings template={template} updateTemplate={updateTemplate} />
        <DragProvider>
          <HoverContext.Provider value={{hoveredBlockId, setHoveredBlockId}}>
            <MobileModeContainer>
              <Container template={template} ref={containerRef}>
                {blocks.map((id) => (
                  <NLConfigurableBlock
                    key={id}
                    id={id}
                    template={template}
                    availableBlocks={availableBlocks}
                    ButtonConfig={ButtonConfig}
                    block={template.blocks[id]}
                    blockPath={`blocks.${id}`}
                    onSave={(data) => {
                      updateTemplate({
                        blocks: {
                          [id]: data,
                        },
                      })
                    }}
                    getPreviewData={(data) => ({
                      blocks: {
                        [id]: data,
                      },
                    })}
                    disableResize
                  />
                ))}
                <NLAddSectionButton updateTemplate={updateTemplate} />
                {footer}
              </Container>
            </MobileModeContainer>
          </HoverContext.Provider>
        </DragProvider>
      </ConfigContext.Provider>
    </Page>
  )
}

const StyledCopyIconButton = styled(CopyIconButton)`
  margin-right: 15px;
  margin-top: 3px;
`

export const ErrorText = styled(Typography)`
  text-align: center;
  background: ${(props) => props.theme.colors.error};
  color: white;
  padding: 4.5px 0px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  left: 232px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    left: 0px;
  }
`

const RightToolbarItems = styled.div`
  display: flex;
  align-items: center;
`
