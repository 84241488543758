import {BaseTemplate, Details} from 'Event/template'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {DeepRequired} from 'lib/type-utils'
import {createBaseTemplate} from 'Event/template/base'
import {Sidebar} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarContainer'
import {SidebarItemProps} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {HashMap} from 'lib/list'
import {InputStyleProps} from 'Event/auth/TextField'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {createNavButtonWithSize} from 'Event/Dashboard/components/NavButton/create'
import {Font} from 'lib/FontSelect'
import {
  WELCOME_TEXT_ALIGN_LEFT,
  WELCOME_TEXT_ALIGN_CENTER,
  WELCOME_TEXT_ALIGN_RIGHT,
} from 'Event/template/Lefty/Dashboard/Hero/HeroConfig/Styling'
import {
  SPEAKER_ALIGN_LEFT,
  SPEAKER_ALIGN_CENTER,
  SPEAKER_ALIGN_RIGHT,
} from 'Event/template/Lefty/Speakers/SpeakersList/SpeakersListConfig/Styling'
import {LeftySponsor} from 'Event/template/Lefty/Sponsors/SponsorList'
import {LeftySpeaker} from 'Event/template/Lefty/Speakers'

export {convert} from 'Event/template/Lefty/converter'

export type WELCOME_TEXT_ALIGN =
  | typeof WELCOME_TEXT_ALIGN_LEFT
  | typeof WELCOME_TEXT_ALIGN_CENTER
  | typeof WELCOME_TEXT_ALIGN_RIGHT

export type SPEAKER_ALIGN =
  | typeof SPEAKER_ALIGN_LEFT
  | typeof SPEAKER_ALIGN_CENTER
  | typeof SPEAKER_ALIGN_RIGHT

/**
 * Template Name (Required)
 * --------------------------------------------------
 * Must be unique, and match the template directory
 * for dynamic imports to work.
 */
export const LEFTY = 'Lefty'

/**
 * Template Details (Required)
 * --------------------------------------------------
 * App-level details shared by every template instance.
 */
export const details: Details = {
  label: 'Lefty',
  previewUrl:
    'https://lefty-demo.obv.io/login?token=9ac96eb7077c8563fc7524f8b08c0c0d',
}

export function useLeftyTemplate() {
  const template = useTemplate()

  if (template.name !== LEFTY) {
    throw new Error('useLefty called with wrong template')
  }

  return template
}

export function useLeftyUpdate() {
  return useSaveTemplate<Lefty>()
}

export type Lefty = BaseTemplate & {
  name: typeof LEFTY
  title: string
  mainNav: {
    buttons: HashMap<NavButtonWithSize>
  }
  welcomeText?: string
  welcomeTextFont?: Font | null
  welcomeTextFontSize?: number
  welcomeTextColor?: string | null
  welcomeTextAlign?: WELCOME_TEXT_ALIGN
  contentOverlap?: number
  heroImageSize?: number
  heroImage: string | null
  sidebar: Sidebar
  sidebarItems: HashMap<SidebarItemProps>
  textColor?: string
  linkColor?: string
  linkUnderline?: boolean
  points_unit: string
  header: LeftyHeader
  background: {
    color: string
    opacity: number
  }
  login: {
    additionalDescription: {
      text: string
      color: string | null
      fontSize: number
    }
    welcome: {
      text: string
      color: string | null
      fontSize: number
      font: Font | null
    }
    passwordReset: {
      forgotPasswordTitle: string
    }
    leftPanel: {
      backgroundOpacity: number
      backgroundColor: string
    }
    rightPanel: {
      backgroundOpacity: number
      backgroundColor: string
    }
  }
  pageBackground?: {
    image: string | null
    color: string
    opacity: number
  }
  footer: {
    image: string | null
    background: string
    textColor: string | null
    termsLink: string | null
    privacyLink: string | null
    copyrightText: string | null
    imageSize?: number
  }
  progressBar: {
    barColor: string
    backgroundColor: string
    textColor: string
    thickness: number
    borderRadius: number
    showing: boolean
    step1Text: string
    step1Percent: number
    step2Text: string
    step2Percent: number
    step3Text: string
    step3Percent: number
  }
  pageBackgroundPosition: 'fixed' | 'bottom'
  logoSize?: number
  logoPadding: {
    top: number
    right: number
    bottom: number
    left: number
  }
  logoIsCenter: boolean
  logoPosition: 'Top' | 'Center' | 'Bottom'
  setPasswordForm?: {
    title?: string
    description?: string
    passwordLabel?: string
    confirmPasswordLabel?: string
    button?: NavButtonWithSize
    input?: InputStyleProps
  }
  leaderboard?: {
    title?: string
    description?: string
    backToDashboardText?: string
    backToDashboardTextColor?: string
    isVisible?: boolean
  }
  sponsors?: {
    spacing?: number
    hasDivider?: boolean
    items: HashMap<LeftySponsor>
    background: {
      color: string
      opacity: number
    }
    text: {
      color: string | null
    }
    border: {
      color: string
      width: number
      radius: number
    }
    separator: {
      color: string
      width: number
    }
    secondaryTitle?: string
    menuTitle?: string
  }
  speakers?: {
    secondaryTitle?: string
    backToDashboardText?: string
    backToDashboardTextColor?: string
    spacing?: number
    showCarousel?: boolean
    otherSpeakersTitle?: string
    carouselArrowColor?: string
    align?: SPEAKER_ALIGN
    items: HashMap<LeftySpeaker>
    menuTitle?: string
  }
  featuredSpeakers: {
    items: HashMap<LeftySpeaker>
  }
  faq?: {
    backToDashboardText?: string
    backToDashboardTextColor?: string
    secondaryTitle?: string
    cardBackgroundColor?: string
    cardTextColor?: string
  }
  zoomBackgrounds?: {
    menuTitle?: string
    secondaryTitle?: string
  }
  countDownTimers?: HashMap<CountDownTimerSettings>
  checkIn: {
    title: string
    titleColor: string
    stepLabelColor: string
    stepIconColor: string
    inActiveColor: string
    step1Label: string
    step1Icon: string
    step2Label: string
    step2Icon: string
    step3Label: string
    step3Icon: string
    contentPanel: {
      backgroundColor: string
      backgroundOpacity: number
      textColor: string | null
    }
    page?: {
      backgroundImage: string | null
      backgroundColor: string
      backgroundOpacity: number
    }
  }
  imageWaterfall: {
    menuTitle?: string
    secondaryTitle?: string
  }
  postStyles?: {
    containerBackgroundColor?: string
    containerBackgroundOpacity?: number
    containerPadding?: {
      left: number
      right: number
      top: number
      bottom: number
    }
    containerBorderWidth?: number
    containerBorderRadius?: number
    containerBorderColor?: string
  }
  featurePageHeader: {
    logoHeight?: number
    menuBarHeight?: number
    menuBarBackgroundColor?: string
    menuBarBackgroundOpacity?: number
    backgroundBarHeight?: number
    logo?: string | null
    backgroundImage?: string | null
    menuIconColor?: string | null
  }
}

export type LeftyHeader = {
  backgroundColor: string
  backgroundOpacity: number
  height: number
  isCollapsed?: boolean
  disableShadow?: boolean
  logo?: string | null
  logoHeight: number
  backgroundImage: string | null
  backToDashboardText?: string
  backToDashboardTextColor?: string
}

/**
 * Create a Template instance with no preset data (required)
 */
export const createBlank = (): DeepRequired<Lefty> => ({
  ...createBaseTemplate(),
  version: 1,
  name: LEFTY,
  title: '',
  mainNav: {
    buttons: {},
  },
  welcomeText: 'WELCOME TO YOUR DASHBOARD',
  welcomeTextFont: null,
  welcomeTextFontSize: 50,
  welcomeTextColor: null,
  welcomeTextAlign: WELCOME_TEXT_ALIGN_LEFT,
  contentOverlap: 32,
  sidebar: {
    backgroundImage: null,
    background: 'blue',
    textColor: null,
    borderRadius: 0,
    borderWidth: 0,
    borderColor: '#000000',
    paddingTop: 48,
    isVisible: true,
    separatorColor: '#FFFFFF',
    separatorStyle: 'solid',
    separatorThickness: 1,
    itemSpacing: 32,
    mobile: {
      icon: {
        color: 'blue',
        background: '#FFFFFF',
      },
    },
  },
  sidebarItems: {},
  pageBackgroundPosition: 'fixed',
  logoPosition: 'Top',
  logoIsCenter: false,
  logoSize: 100,
  logoPadding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  heroImageSize: 50,
  heroImage: null,
  textColor: '#000000',
  linkColor: '#000000',
  linkUnderline: true,
  header: {
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 100,
    height: 150,
    isCollapsed: false,
    disableShadow: false,
    logo: null,
    logoHeight: 110,
    backgroundImage: null,
    backToDashboardText: 'Back',
    backToDashboardTextColor: '#000000',
  },
  footer: {
    image: null,
    background: '#00e0000',
    textColor: '#FFFFFF',
    termsLink: null,
    privacyLink: null,
    copyrightText: null,
    imageSize: 100,
  },
  progressBar: {
    barColor: '#0969d6',
    backgroundColor: '#b1d4f1',
    textColor: '#000000',
    thickness: 15,
    borderRadius: 50,
    showing: true,
    step1Text: 'Step 1',
    step1Percent: 33,
    step2Text: 'Step 2',
    step2Percent: 66,
    step3Text: 'Step 3',
    step3Percent: 100,
  },
  sponsors: {
    ...createBaseTemplate().sponsors,
    spacing: 0,
    hasDivider: true,
    items: {},
    background: {
      color: '#FFFFFF',
      opacity: 0,
    },
    text: {
      color: null,
    },
    border: {
      color: '#edf1f7',
      width: 1,
      radius: 4,
    },
    separator: {
      color: '#edf1f7',
      width: 1,
    },
    secondaryTitle: '',
    menuTitle: 'Our Sponsors',
  },
  speakers: {
    ...createBaseTemplate().speakers,
    secondaryTitle: 'Meet our speakers',
    items: {},
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
    spacing: 0,
    showCarousel: false,
    otherSpeakersTitle: 'Check other speakers',
    carouselArrowColor: '#3490DC',
    align: SPEAKER_ALIGN_LEFT,
    menuTitle: 'Our Speakers',
  },
  featuredSpeakers: {
    items: {},
  },
  zoomBackgrounds: {
    ...createBaseTemplate().zoomBackgrounds,
    menuTitle: 'Backgrounds',
    secondaryTitle: '',
  },
  background: {
    color: '#FFFFFF',
    opacity: 0,
  },
  pageBackground: {
    image: null,
    color: '#FFFFFF',
    opacity: 0,
  },
  login: {
    ...createBaseTemplate().login,
    backgroundColor: '#131E34',
    backgroundOpacity: 100,
    additionalDescription: {
      text: 'First time here or did you forget your password?',
      color: '#000000',
      fontSize: 18,
    },
    welcome: {
      text: 'Welcome',
      color: null,
      fontSize: 24,
      font: null,
    },
    passwordReset: {
      ...createBaseTemplate().login.passwordReset,
      forgotPasswordTitle: 'Password Help',
    },
    leftPanel: {
      backgroundOpacity: 100,
      backgroundColor: '#FFFFFF',
    },
    rightPanel: {
      backgroundOpacity: 100,
      backgroundColor: '#FFFFFF',
    },
  },
  setPasswordForm: {
    title: 'Please set a password to continue',
    description: '',
    passwordLabel: 'Password',
    confirmPasswordLabel: 'Confirm Password',
    button: createNavButtonWithSize({text: 'Submit'}),
    input: {
      labelColor: '#3490DC',
      textColor: '#000000',
      borderColor: '#3490DC',
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
  },
  leaderboard: {
    title: 'Leaderboard',
    description:
      '<p>{{first name}}, you have earned {{leaderboard_points}} {{points_unit}}, and you are currently {{leaderboard_position}}. Great Job!</p><p><i>The list below is the top 200 point earners! If you don’t see your name listed, there’s still time!</i></p><p><br>&nbsp;</p>',
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
    isVisible: false,
  },
  faq: {
    title: 'FAQ',
    description: '',
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
    isEnabled: false,
    items: {},
    secondaryTitle: '',
    cardBackgroundColor: '#f1f1f1',
    cardTextColor: '#000000',
  },
  countDownTimers: {},
  checkIn: {
    title: 'Check In:',
    titleColor: '#000000',
    stepLabelColor: '#07BAB5',
    stepIconColor: '#07BAB5',
    inActiveColor: '#C7C7C7',
    step1Label: 'Step 1',
    step1Icon: 'far fa-lock',
    step2Label: 'Step 2',
    step2Icon: 'far fa-clipboard',
    step3Label: 'Step 3',
    step3Icon: 'far fa-desktop',
    contentPanel: {
      backgroundColor: '#C7C7C7',
      textColor: null,
      backgroundOpacity: 0,
    },
    page: {
      backgroundImage: null,
      backgroundColor: '#FFFFFF',
      backgroundOpacity: 100,
    },
  },
  imageWaterfall: {
    ...createBaseTemplate().imageWaterfall,
    menuTitle: 'Image Waterfall',
    secondaryTitle: '',
  },
  postStyles: {
    ...createBaseTemplate().postStyles,
    containerBackgroundColor: '#ffffff',
    containerBackgroundOpacity: 0,
    containerPadding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    containerBorderWidth: 0,
    containerBorderRadius: 0,
    containerBorderColor: '#ffffff',
  },
  featurePageHeader: {
    logoHeight: 32,
    menuBarHeight: 80,
    menuBarBackgroundColor: '#ffffff',
    menuBarBackgroundOpacity: 100,
    backgroundBarHeight: 80,
    logo: null,
    backgroundImage: null,
    menuIconColor: null,
  },
})

export const DEFAULTS = createBlank()

export const sampleEventURL = 'https://v1.obv.io/events/lefty-demo'
