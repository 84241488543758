import {onChangeCheckedHandler, handleAutocomplete, onChangeDate} from 'lib/dom'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import MuiTextField from '@material-ui/core/TextField'
import {Label} from 'lib/ui/typography'
import {Agenda} from 'Event/template/Panels/Dashboard/AgendaList'
import React from 'react'
import {Controller} from 'react-hook-form'
import {Autocomplete} from '@material-ui/lab'
import withStyles from '@material-ui/core/styles/withStyles'
import styled from 'styled-components'
import {spacing} from 'lib/ui/theme'
import {orderedIdsByPosition} from 'lib/list'
import {useConfig} from 'organization/Event/Configurable'
import {usePanelsTemplate} from 'Event/template/Panels'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {agenda: Agenda; id: string}) {
  const {agenda, id} = props
  const {
    form: {control, register, watch},
  } = useConfig()
  const {
    speakers: {items: allSpeakers},
  } = usePanelsTemplate()
  const v = useAttendeeVariables()

  const availableSpeakerIds = orderedIdsByPosition(allSpeakers)
  const agendaSpeakerIds = agenda.speakers

  const startDate = watch('startDate', agenda.startDate)

  return (
    <>
      <Controller
        name={`agendaList.items.${id}.isVisible`}
        control={control}
        defaultValue={agenda.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config visible switch"
          />
        )}
      />
      <Label>Event</Label>
      <TextField
        name={`agendaList.items.${id}.text`}
        defaultValue={v(agenda.text)}
        aria-label="agenda text"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Label>Description</Label>
      <TextField
        name={`agendaList.items.${id}.description`}
        defaultValue={v(agenda.description || undefined)}
        aria-label="agenda description"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name={`agendaList.items.${id}.startDate`}
        control={control}
        defaultValue={agenda.startDate}
        render={({value, onChange}) => {
          return (
            <LocalizedDateTimePicker
              value={value}
              onChange={onChangeDate(onChange)}
              fullWidth
              label="Start"
              minDate={new Date()}
              inputProps={{
                'aria-label': 'agenda start date',
              }}
            />
          )
        }}
      />
      <Controller
        name={`agendaList.items.${id}.endDate`}
        control={control}
        defaultValue={agenda.endDate}
        render={({value, onChange}) => (
          <LocalizedDateTimePicker
            value={value}
            onChange={onChangeDate(onChange)}
            fullWidth
            label="End"
            minDate={startDate}
            inputProps={{
              'aria-label': 'agenda end date',
            }}
          />
        )}
      />
      <Label>Link</Label>
      <TextField
        name={`agendaList.items.${id}.link`}
        defaultValue={v(agenda.link || '')}
        aria-label="agenda link"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <label>Speakers</label>
      <Controller
        control={control}
        name={`agendaList.items.${id}.speakers`}
        defaultValue={agendaSpeakerIds}
        render={({value, onChange}) => (
          <StyledAutocomplete
            multiple
            filterSelectedOptions
            options={availableSpeakerIds}
            value={value}
            onChange={handleAutocomplete(onChange)}
            aria-label="speaker selector"
            noOptionsText="No Speakers"
            getOptionLabel={(option) => v(allSpeakers[option].name)}
            getOptionSelected={(option) => option === value}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  'aria-label': 'speaker input',
                }}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            renderOption={(id) => <div>{allSpeakers[id].name}</div>}
          />
        )}
      />
    </>
  )
}

const StyledAutocomplete = withStyles({
  root: {
    flex: 1,
    marginRight: spacing[3],
    marginBottom: 0,
  },
})(Autocomplete) as typeof Autocomplete

const StyledTextField = styled(MuiTextField)`
  margin-bottom: 0;
`
