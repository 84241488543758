import React, {useEffect, useImperativeHandle, useState} from 'react'
import {BlockProps, Box} from 'Event/Marketplace/Block'
import {JsonSave, REMOVE} from 'lib/JsonUpdateProvider'
import {ConfigContext} from 'organization/Event/Configurable'
import BlockConfig from 'organization/Marketplace/config/BlockConfig'
import {useToggleArray} from 'lib/toggle'
import {useForm} from 'react-hook-form'
import {EditComponentOverlay} from 'Event/Dashboard/editor/views/EditComponent'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Title from 'Event/Marketplace/Block/Title'
import Text from 'Event/Marketplace/Block/Text'
import Image from 'Event/Marketplace/Block/Image'
import TicketSelector from 'Event/Marketplace/Block/TicketSelector'
import Button from 'Event/Marketplace/Block/Button'
import Video from 'Event/Marketplace/Block/Video'
import Separator from 'Event/Marketplace/Block/Separator'
import CountdownTimer from 'Event/Marketplace/Block/CountdownTimer'
import ConfigurableBulletedList from 'organization/Marketplace/config/ConfigurableBulletedList'
import ConfigurableNumberedList from 'organization/Marketplace/config/ConfigurableNumberedList'
import {uuid} from 'lib/uuid'
import Icon from 'Event/Marketplace/Block/Icon'
import Spacer from 'Event/Marketplace/Block/Spacer'
import ConfigurablePurchaseForm from 'organization/Marketplace/config/ConfigurablePurchaseForm'
import ConfigurableFaqList from 'organization/Marketplace/config/ConfigurableFaqList'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import {useDuplicateAssets} from 'organization/Event/SelectTemplatePage'
import {
  PurchasePageBlock,
  PurchasePageTemplate,
} from 'Event/Marketplace/purchase-page'
import ConfigurableGrid from 'organization/Marketplace/config/ConfigurableGrid'
import {colors} from 'lib/ui/theme'
import CenterBlockButton from 'organization/Marketplace/config/CenterBlockButton'
import {getBlockLayout} from 'organization/Marketplace/config/get-block-layout'
import {
  NumberItem,
  createNumberItem,
} from 'Event/Marketplace/Block/NumberedList'
import {
  BulletItem,
  createBulletItem,
} from 'Event/Marketplace/Block/BulletedList'
import {createFaqItem} from 'Event/Marketplace/Block/FaqList/create-faq-list-block'
import {FaqItem} from 'Event/Marketplace/Block/FaqList'
import {
  ThankYouPageBlock,
  ThankYouPageTemplate,
} from 'Event/Marketplace/thank-you-page'
import {
  UpsellPageBlock,
  UpsellPageTemplate,
} from 'Event/Marketplace/upsell-page'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import {useConfigPage} from 'organization/Marketplace/config/ConfigPageProvider'
import {resizeLayout} from 'Event/Marketplace/lib/use-resize-layout'
import {replaceLayout} from 'organization/Marketplace/config/replace-layout'
import {GridBlock} from 'Event/Marketplace/Block/Grid'
import UpsellDeclineButton from 'Event/Marketplace/Block/UpsellDeclineButton'
import {PageTemplate} from 'Event/Marketplace/page-template'
import ConfigurableUpsellPurchaseButton from 'organization/Marketplace/config/ConfigurableUpsellPurchaseButton'
import UpsellDeclineText from 'Event/Marketplace/Block/UpsellDeclineText'
import {NLPurchasePageBlock} from 'Event/Marketplace/nl-purchase-page'

export const BLOCK_DRAG_HANDLE = 'block-drag-handle'

export const Blocks: ConfigComponentDefinitions = {
  Title,
  Text,
  Image,
  TicketSelector,
  Button,
  Video,
  PurchaseForm: ConfigurablePurchaseForm,
  Separator,
  BulletedList: ConfigurableBulletedList,
  CountdownTimer,
  NumberedList: ConfigurableNumberedList,
  Icon,
  FaqList: ConfigurableFaqList,
  Spacer,
  Grid: ConfigurableGrid,
  UpsellPurchaseButton: ConfigurableUpsellPurchaseButton,
  UpsellDeclineButton,
  UpsellDeclineText,
}

export type ConfigComponentDefinitions = {
  [T in PurchasePageBlock as T['type']]: React.FC<
    T & {id: string} & {
      template: PageTemplate
      updateTemplate: UpdateTemplateFunction
      availableBlocks: Array<
        | PurchasePageBlock['type']
        | ThankYouPageBlock['type']
        | UpsellPageBlock['type']
      >
      ButtonConfig: React.FC<any>
    }
  >
}

type ConfigurableBlockProps = BlockProps & {
  disableDragToOtherSection?: boolean
  draggableHandle?: string
  getPreviewData?: (data: Record<string, any>) => Record<string, any>
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
  updateTemplate: JsonSave<
    PurchasePageTemplate | ThankYouPageTemplate | UpsellPageTemplate
  >
  availableBlocks: Array<
    | PurchasePageBlock['type']
    | ThankYouPageBlock['type']
    | UpsellPageBlock['type']
    | NLPurchasePageBlock['type']
  >
  ButtonConfig: React.FC<any>
  gridId?: string
  isDragging: boolean
}

const ConfigurableBlock = React.forwardRef<
  HTMLDivElement,
  ConfigurableBlockProps
>((props, ref) => {
  const {
    block,
    id,
    template,
    onMoveSectionUp,
    onMoveSectionDown,
    draggableHandle,
    getPreviewData,
    updateTemplate,
    availableBlocks,
    ButtonConfig,
    isDragging,
    ...gridLayoutProps
  } = props

  const {sectionId} = useConfigurableSection()
  const withCopiedAssets = useDuplicateAssets<
    PurchasePageBlock | ThankYouPageBlock | UpsellPageBlock
  >()
  const [copying, setCopying] = useState<boolean>(false)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  // Access ref while forwarding it to outer
  useImperativeHandle(ref, () => container!, [container])

  const {
    style,
    className,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    children,
  } = gridLayoutProps
  const [showingConfig, toggleConfig] = useToggleArray()
  const form = useForm()
  const isEditMode = useEditMode()
  const {isMobileMode} = useConfigPage()
  const {sections} = template
  const section = sections[sectionId]
  const {layouts} = section
  const blockType = block.type

  const Component = Blocks[blockType] ?? null
  const hasItems =
    blockType === 'FaqList' ||
    blockType === 'NumberedList' ||
    blockType === 'BulletedList' ||
    blockType === 'Grid'

  // Remove blocks with missing components. ie. deprecated blocks.
  useEffect(() => {
    // If missing block type, we'll assumed its already being removed (set to `__REMOVE__`), so
    // we'll avoid deleting it again. Fixes an occasionally ocurring inf-loop bug.
    if (!blockType) {
      return
    }

    if (Component === null) {
      updateTemplate({
        sections: {
          [sectionId]: {
            blocks: {
              [id]: REMOVE,
            },
          },
        },
      })
    }
  }, [Component, id, updateTemplate, sectionId, blockType])

  const copy = () => {
    if (copying) {
      return
    }

    setCopying(true)

    const gridSection = props.gridId
      ? (sections[sectionId].blocks[props.gridId] as GridBlock)
      : null

    const desktopLayouts = gridSection
      ? gridSection.layouts.desktop
      : layouts.desktop
    const mobileLayouts = gridSection
      ? gridSection.layouts.mobile
      : layouts.mobile

    // need to get desktop/mobile layouts from block that is being copied
    const copiedDesktopLayout = desktopLayouts.find((layout) => layout.i === id)
    const copiedMobileLayout = mobileLayouts.find((layout) => layout.i === id)

    const newId = uuid()

    // Copy data to modify
    const data = gridSection
      ? {...gridSection.blocks[id]}
      : {...sections[sectionId].blocks[id]}

    // For Grid blocks, give child block new ids to avoid sharing
    // layouts, images, etc.
    if (data.type === 'Grid') {
      let gridLayouts = JSON.stringify(data.layouts)
      const gridBlocks: any = {}
      for (const [key, val] of Object.entries(data.blocks)) {
        const newBlockId = uuid()
        gridBlocks[newBlockId] = val
        gridLayouts = gridLayouts.replaceAll(key, newBlockId) // update layout
      }

      data.blocks = gridBlocks
      data.layouts = JSON.parse(gridLayouts)
    }

    withCopiedAssets(data)
      .then((blockWithCopiedAssets) => {
        const updateData = {
          blocks: {
            [newId]: blockWithCopiedAssets,
          },
          layouts: {
            mobile: [
              ...mobileLayouts,
              {...copiedMobileLayout, i: newId, x: 0, y: 1000000000000},
            ],
            desktop: [
              ...desktopLayouts,
              {...copiedDesktopLayout, i: newId, x: 0, y: 1000000000000},
            ],
          },
        }

        const blockData = props.gridId
          ? {
              blocks: {
                [props.gridId]: updateData,
              },
            }
          : updateData

        updateTemplate({
          sections: {
            [sectionId]: blockData,
          },
        })
      })
      .finally(() => {
        setCopying(false)
      })
  }

  const [showingAddBlockDialog, toggleAddBlockDialog] = useToggleArray()

  const addItem = () => {
    switch (blockType) {
      case 'FaqList':
        addNewListItem(createFaqItem(), id, sectionId, template, updateTemplate)
        break
      case 'NumberedList':
        addNewListItem(
          createNumberItem(),
          id,
          sectionId,
          template,
          updateTemplate,
        )
        break
      case 'BulletedList':
        addNewListItem(
          createBulletItem(),
          id,
          sectionId,
          template,
          updateTemplate,
        )
        break
      case 'Grid':
        toggleAddBlockDialog()
        break
    }
  }

  if (!Component) {
    return null
  }

  // Need to use ConfigContext directly to separate out the config & editable component. If we used
  // Configurable, any clicks (ie. opening the config) would trigger a layout drag.
  return (
    <ConfigContext.Provider
      value={{
        showing: showingConfig,
        toggle: toggleConfig,
        form,
        getPreviewData: (data) => {
          if (getPreviewData) {
            return getPreviewData({
              [id]: data,
            })
          }
          return {
            sections: {
              [sectionId]: {
                blocks: {
                  [id]: data,
                },
              },
            },
          }
        },
      }}
    >
      <BlockConfig
        block={block}
        id={id}
        sectionId={sectionId}
        onMoveSectionUp={onMoveSectionUp}
        onMoveSectionDown={onMoveSectionDown}
        ButtonConfig={ButtonConfig}
      />
      <Box
        style={{
          ...style,
          border: isEditMode ? `1px dashed ${colors.primary}` : 'none',
        }}
        className={className}
        ref={setContainer}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        visibility={block.visibility}
        isMobileMode={isMobileMode}
      >
        <StyledDraggableOverlay>
          <StyledEditComponentOverlay
            onClick={toggleConfig}
            aria-label={`edit ${block.type} block`}
            onCopy={copy}
            onAddItem={hasItems ? addItem : undefined}
            isDragging={isDragging}
            resizable
            additionalButtons={
              <>
                <CenterBlockButton
                  section={sections[sectionId]}
                  sectionId={sectionId}
                  blockId={id}
                  updateTemplate={updateTemplate}
                  availableBlocks={availableBlocks}
                  isMobileMode={isMobileMode}
                  ButtonConfig={ButtonConfig}
                />
                <DragHandle className={draggableHandle} />
              </>
            }
          >
            {/*
             * Setting 'any' type for props is ok here, because we're
             * already verifying the correct component receives the
             * correct type in ComponentDefinitions.
             */}
            <>
              <Component
                {...(block as any)}
                id={id}
                sectionId={sectionId}
                isMobileMode={isMobileMode}
                template={template}
                updateTemplate={updateTemplate}
                showingAddBlockDialog={showingAddBlockDialog}
                toggleAddBlockDialog={toggleAddBlockDialog}
                availableBlocks={availableBlocks}
                isEditMode={props.isEditMode}
                ButtonConfig={ButtonConfig}
              />
            </>
          </StyledEditComponentOverlay>
        </StyledDraggableOverlay>
        {children}
      </Box>
    </ConfigContext.Provider>
  )
})

export default ConfigurableBlock

function DragHandle(props: {className?: string}) {
  return (
    <DragHandleBox
      aria-label="button drag handle"
      className={props.className ?? BLOCK_DRAG_HANDLE}
    >
      <DragHandleIcon />
    </DragHandleBox>
  )
}

export const addNewListItem = (
  newItem: FaqItem | NumberItem | BulletItem,
  blockId: string,
  sectionId: string,
  template: PageTemplate,
  updateTemplate: JsonSave<PageTemplate>,
) => {
  const {sections} = template
  const {layouts, blockSpacing} = sections[sectionId]

  const layout = getBlockLayout({
    sectionId,
    blockId,
    template,
  })

  if (!layout) {
    return
  }

  const getNewPosition = () => {
    const block = template.sections[sectionId].blocks[blockId]

    if ('items' in block) {
      return Object.keys(block.items).length + 1
    }

    return 0
  }

  const newId = uuid()
  updateTemplate({
    sections: {
      [sectionId]: {
        blocks: {
          [blockId]: {
            items: {
              [newId]: {
                ...newItem,
                position: getNewPosition(),
              },
            },
          },
        },
      },
    },
  })

  // Resize layout to make sure new items are showing
  setTimeout(() => {
    const resized = resizeLayout(layouts, 10, blockSpacing)
    const resizedDesktop = resized.desktop.find(
      (desktopLayout) => desktopLayout.i === blockId,
    )
    const resizedMobile = resized.mobile.find(
      (mobileLayout) => mobileLayout.i === blockId,
    )

    if (!resizedDesktop || !resizedMobile) {
      return
    }

    updateTemplate({
      sections: {
        [sectionId]: {
          layouts: {
            desktop: replaceLayout({
              layouts: layouts.desktop,
              index: layout.desktop.index,
              updated: resizedDesktop,
            }),
            mobile: replaceLayout({
              layouts: layouts.mobile,
              index: layout.mobile.index,
              updated: resizedMobile,
            }),
          },
        },
      },
    })
  }, 100) // add delay to make sure rendered
}

const DragHandleBox = styled.div`
  z-index: 4;
  border-radius: 4px;
  background: white;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const DraggableOverlay = styled.div`
  position: relative;
`

const StyledDraggableOverlay = styled(DraggableOverlay)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledEditComponentOverlay = styled(EditComponentOverlay)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
