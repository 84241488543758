import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import {ValidationError} from 'lib/ui/api-client'
import {spacing} from 'lib/ui/theme'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {ObvioEvent} from 'Event'
import {FileSelect} from 'lib/ui/form/file'
import ImageUpload from 'lib/ui/form/ImageUpload'
import Label from 'lib/ui/form/ImageUpload/Label'
import RemoveButton from 'lib/ui/form/ImageUpload/RemoveButton'
import UploadButton from 'lib/ui/form/ImageUpload/UploadButton'
import Image from 'lib/ui/form/ImageUpload/Image'
import Box from '@material-ui/core/Box'
import Cropper from 'lib/ui/form/ImageUpload/Cropper'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import {useEvent} from 'Event/EventProvider'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import Header from 'lib/ui/layout/Header'
import {Title} from 'lib/ui/typography'
import Button from 'lib/ui/Button'
import TextField, {NumberField} from 'lib/ui/TextField'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import TimeZonePicker from 'lib/ui/TimezonePicker'
import {formatDate} from 'organization/EventList/CreateEventForm/Form'
import {getSystemDomain} from 'lib/url'
import {useSystemDomains} from 'lib/domains'
import Tooltip from '@material-ui/core/Tooltip'

export type UpdateEventData = Pick<
  ObvioEvent,
  | 'name'
  | 'slug'
  | 'description'
  | 'start'
  | 'end'
  | 'num_expected_attendees'
  | 'is_online'
  | 'default_attendee_password'
  | 'timezone'
>

export default function Form(props: {
  onSubmit: () => void
  register: UseFormMethods['register']
  errors: UseFormMethods['errors']
  submitting: boolean
  responseError: ValidationError<UpdateEventData>
  slug?: string
  systemDomainId?: number
  submitLabel: string
  control: UseFormMethods['control']
  favicon: FileSelect
  logo: FileSelect
  setValue: UseFormMethods['setValue']
  hasEndDateTimeChange: boolean
  setHasEndDateTimeChange: (flag: boolean) => void
}) {
  const {
    submitting,
    errors,
    responseError,
    slug,
    systemDomainId,
    register,
    onSubmit,
    control,
    submitLabel,
    setValue,
  } = props
  const {event} = useEvent()
  const systemDomains = useSystemDomains()

  const hasRegisteredAttendees = event.num_registered_attendees > 0

  const slugHelperText = () => {
    if (errors.slug) {
      return errors.slug
    }

    const systemDomain = getSystemDomain({
      system_domain_id: systemDomainId ?? 0,
    })

    if (!slug || !systemDomain) {
      return 'Your event slug will be a part of your domain'
    }

    return (
      <Box>
        Your event will be accessible at: <Url>{`${slug}.${systemDomain}`}</Url>
      </Box>
    )
  }

  const handleStartDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      throw new Error('Date is required')
    }
    setValue('start', date.toISOString())
  }

  const handleEndDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      throw new Error('Date is required')
    }

    setValue('end', date.toISOString())
  }

  const eventSystemDomainId = event.system_domain_id ?? 1 // default is 1 (obv.io)

  return (
    <form onSubmit={onSubmit}>
      <Header>
        <Title>Event Settings</Title>
        <Button
          type="submit"
          variant="contained"
          color="success"
          disabled={submitting}
          aria-label="submit"
        >
          {submitLabel}
        </Button>
      </Header>
      <Box mb={1}>
        <Controller
          control={control}
          name="is_online"
          defaultValue={event.is_online}
          render={({value, onChange}) => (
            <StyledSwitch
              label="Online"
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              labelPlacement="top"
              aria-label="is online"
              disabled={submitting}
            />
          )}
        />
      </Box>
      <Box mb={1}>
        <TextField
          label="Event Name"
          variant="outlined"
          name="name"
          required
          fullWidth
          defaultValue={event.name}
          inputProps={{
            ref: register({
              required: 'Name is required',
            }),
            'aria-label': 'event name',
          }}
          error={!!errors.name}
          helperText={errors.name}
          disabled={submitting}
        />
      </Box>
      <Box mb={1}>
        <TextField
          label="Event Description"
          variant="outlined"
          name="description"
          fullWidth
          defaultValue={event.description}
          inputProps={{
            ref: register,
            'aria-label': 'event description',
          }}
          error={!!errors.description}
          helperText={errors.description}
          disabled={submitting}
        />
      </Box>

      <Box mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Tooltip
              title={attendeesAlreadyCreatedMessage}
              placement="bottom-start"
              disableHoverListener={!hasRegisteredAttendees}
              disableFocusListener={!hasRegisteredAttendees}
              disableTouchListener={!hasRegisteredAttendees}
            >
              <div>
                <ReadOnlyField
                  label="Unique URL"
                  name="slug"
                  variant="outlined"
                  required
                  defaultValue={event.slug}
                  fullWidth
                  inputProps={{
                    ref: register({
                      required: 'Slug is required',
                    }),
                    'aria-label': 'domain slug',
                    readOnly: hasRegisteredAttendees,
                  }}
                  error={!!errors.slug}
                  disabled={submitting}
                  helperText={slugHelperText()}
                />
              </div>
            </Tooltip>
          </Grid>
          <UrlContainer item xs={12} md={6}>
            <Controller
              name="system_domain_id"
              control={control}
              defaultValue={eventSystemDomainId}
              render={({onChange, value}) => (
                <Tooltip
                  title={attendeesAlreadyCreatedMessage}
                  placement="bottom-start"
                  disableHoverListener={!hasRegisteredAttendees}
                  disableFocusListener={!hasRegisteredAttendees}
                  disableTouchListener={!hasRegisteredAttendees}
                >
                  <div style={{width: '100%'}}>
                    <Select
                      fullWidth
                      aria-label="domain"
                      value={value}
                      onChange={onChange}
                      disabled={submitting || hasRegisteredAttendees}
                    >
                      {Object.entries(systemDomains).map(([id, name]) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Tooltip>
              )}
            />
          </UrlContainer>
        </Grid>
      </Box>

      <Box mb={1}>
        <Label>Event Time Zone</Label>
        <Controller
          name="timezone"
          control={control}
          defaultValue={event.timezone}
          render={({onChange, value}) => (
            <TimeZonePicker
              value={value}
              onChange={onChange}
              aria-label="pick event time zone"
              disabled={submitting}
              error={errors.timezone}
            />
          )}
        />
      </Box>
      <Box mb={1}>
        <Controller
          name="start"
          control={control}
          defaultValue={event.start}
          rules={{
            required: 'Start is required',
          }}
          render={({onChange, value}) => (
            <LocalizedDateTimePicker
              disabled={submitting}
              value={value}
              labelFunc={formatDate}
              onChange={handleStartDate}
              fullWidth
              label="Start date/time"
              inputProps={{
                'aria-label': 'start',
                onChange,
              }}
              error={Boolean(errors.start)}
              helperText={errors.start}
            />
          )}
        />
      </Box>
      <Box mb={1}>
        <Controller
          name="end"
          control={control}
          defaultValue={event.end}
          rules={{
            required: 'End is required',
          }}
          render={({onChange, value}) => (
            <LocalizedDateTimePicker
              disabled={submitting}
              value={value}
              labelFunc={formatDate}
              onChange={handleEndDate}
              fullWidth
              label="End date/time"
              inputProps={{
                'aria-label': 'end',
                onChange,
              }}
              error={Boolean(errors.end)}
              helperText={errors.end}
            />
          )}
        />
      </Box>
      <Box mb={1}>
        <NumberField
          required
          variant="outlined"
          label="Anticipated amount of attendees"
          type="number"
          name="num_expected_attendees"
          defaultValue={event.num_expected_attendees}
          fullWidth
          inputProps={{
            ref: register({
              required: 'Anticipated amount of attendees is required',
            }),
            'aria-label': 'expected number of attendees',
          }}
          error={!!errors.numAttendees}
          helperText={errors.numAttendees}
          disabled={submitting}
        />
      </Box>

      <Box mb={1}>
        <TextField
          label="Attendee default password"
          variant="outlined"
          name="default_attendee_password"
          fullWidth
          defaultValue={event.default_attendee_password || ''}
          inputProps={{
            ref: register(),
            'aria-label': 'attendee default password',
          }}
          error={!!errors.default_attendee_password}
          helperText={errors.default_attendee_password}
          disabled={submitting}
        />
      </Box>
      <Box mb={1}>
        <Controller
          control={control}
          name="allows_email_only_login"
          defaultValue={event.allows_email_only_login}
          render={({value, onChange}) => (
            <StyledSwitch
              label="Allow Email Only Login"
              checked={value}
              disabled={submitting}
              onChange={onChangeCheckedHandler(onChange)}
              labelPlacement="top"
              aria-label="email only login"
            />
          )}
        />
        <EmailOnlyDescription>
          When this function is enabled, attendees will be able to log into the
          event using only their email address. <br /> While more convenient for
          attendees, it reduces the security level of your event, as attendees
          can no longer prevent access to their dashboard by changing their
          password.
        </EmailOnlyDescription>
      </Box>
      <Box mb={2}>
        <ImageUpload file={props.favicon} disabled={submitting}>
          <Cropper width={32} height={32} />
          <Label>Favicon</Label>
          <Image alt="favicon" width={32} />
          <UploadButton
            inputProps={{
              'aria-label': 'favicon input',
            }}
          />
          <RemoveButton aria-label="remove favicon" />
        </ImageUpload>
      </Box>
      <Box mb={2} pb={2}>
        <ImageUpload file={props.logo} disabled={submitting}>
          <Label>Logo</Label>
          <Image alt="logo" width={250} />
          <UploadButton
            inputProps={{
              'aria-label': 'logo input',
            }}
          />
          <RemoveButton aria-label="remove logo" />
        </ImageUpload>
      </Box>
      <ErrorMessage>{responseError && responseError.message}</ErrorMessage>
    </form>
  )
}

const attendeesAlreadyCreatedMessage = `
Your event URL cannot be modified once attendees have been created/imported in your event. This is due to individual attendee login URLs changing when the event URL is changed.
If you wish to modify your event URL, first clear the attendees from this event by going to the Attendees tab and using the Attendee Tools`

function ErrorMessage(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Typography)

const StyledSwitch = styled(Switch)`
  margin-left: -${(props) => props.theme.spacing[6]};
  & > label {
    align-items: start;
  }
`

const UrlContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Url = styled.span`
  font-weight: 400;
  color: #000000;
`
const EmailOnlyDescription = styled.p`
  font-style: italic;
  color: #939393;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: ${(props) => props.theme.spacing[7]};
`

const ReadOnlyField = styled(TextField)`
  & > div > input:read-only {
    color: rgba(0, 0, 0, 0.38);
  }
`
