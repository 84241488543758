import styled from 'styled-components'

const Page = styled.div`
  background: #131d34;
  width: 100%;
  color: #ffffff;
  overflow-x: hidden;
`

export default Page
