import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useForm} from 'react-hook-form'
import Page from 'organization/Event/Page'
import Form, {
  TicketFormData,
} from 'organization/Marketplace/Tickets/TicketForm/Form'
import {ValidationError} from 'lib/ui/api-client'
import {GroupPair} from 'lib/ui/form/DescribedGroupsInput'
import {TicketsBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {
  CreateTicketData,
  useCreateTicket,
} from 'lib/marketplace-api/tickets/use-create-ticket'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'

export default function CreateTicketForm() {
  const history = useHistory()
  const eventRoutes = useEventRoutes()
  const {mutate: createTicket, isLoading: isCreatingTicket} = useCreateTicket()
  const {errors: formErrors, handleSubmit, control, setValue, watch} = useForm()
  const {data: account} = useMarketplaceAccount()
  const [responseError, setResponseError] = useState<
    ValidationError<CreateTicketData>
  >(null)
  const [groups, setGroups] = useState<GroupPair[]>([])
  const [isMissingStripeAccount, setIsMissingStripeAccount] = useState(false)

  const submit = (data: TicketFormData) => {
    const {
      infusionsoft_tag,
      active_campaign_tag,
      highlevel_tag,
      ontraport_tag,
      convert_kit_tag,
      mailchimp_tag,
      hubspot_tag,
      ...formData
    } = data

    const hasConnectedStripe = account && account.completed_setup
    const isPaidTicket = data.price > 0

    if (isPaidTicket && !hasConnectedStripe) {
      setIsMissingStripeAccount(true)
      return
    }

    setIsMissingStripeAccount(false)

    const groupsAsStrings = groups.map((group) => {
      // Need to convert to unkown first because there is no overlap, we've
      // actually got a GroupPair[] in data.
      const temp = (group as unknown) as GroupPair

      // Backend wants the group attribute to be an array of strings, group:value
      // in format.
      return `${temp.key}:${temp.value}`
    })

    // Put in our massaged string[] back into the data that is being submitted.
    data.groups = groupsAsStrings?.filter((group) => group !== ':')

    const payload: CreateTicketData = {...formData, account_id: account?.id}

    if (infusionsoft_tag) {
      payload['infusionsoft_tag_id'] = infusionsoft_tag.id
      payload['infusionsoft_tag_name'] = infusionsoft_tag.name
    }

    if (mailchimp_tag) {
      payload['mailchimp_tag_name'] = mailchimp_tag.name
    }

    if (convert_kit_tag) {
      payload['convert_kit_tag_id'] = convert_kit_tag.id
      payload['convert_kit_tag_name'] = convert_kit_tag.name
    }

    if (ontraport_tag) {
      payload['ontraport_tag_id'] = ontraport_tag.tag_id
      payload['ontraport_tag_name'] = ontraport_tag.tag_name
    }
    if (active_campaign_tag) {
      payload['active_campaign_tag_id'] = active_campaign_tag.id
      payload['active_campaign_tag_name'] = active_campaign_tag.tag
    }

    if (highlevel_tag) {
      payload['highlevel_tag_name'] = highlevel_tag.name
    }
    if (hubspot_tag) {
      payload['hubspot_tag_value'] = hubspot_tag.property_value
      payload['hubspot_tag_name'] = hubspot_tag.property_name
      payload['hubspot_tag_label'] = hubspot_tag.property_label
      payload['hubspot_tag_type'] = hubspot_tag.property_type
    }

    createTicket(payload, {
      onError: (error: any) => {
        setResponseError(error)
      },
      onSuccess: () => {
        history.push(eventRoutes.marketplace.tickets.root)
      },
    })
  }

  return (
    <TicketsBreadcrumbs page="Add">
      <Page>
        <Form
          control={control}
          formErrors={formErrors}
          onSubmit={handleSubmit(submit)}
          responseError={responseError}
          setValue={setValue}
          submitting={isCreatingTicket}
          newGroups={groups}
          onChangeNewGroups={setGroups}
          watch={watch}
          isMissingStripeAccount={isMissingStripeAccount}
        />
      </Page>
    </TicketsBreadcrumbs>
  )
}
