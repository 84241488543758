import React, {useState, useEffect, useRef} from 'react'
import Markdown from 'marked-react'
import styled from 'styled-components'
import MarkdownEditor from 'lib/ui/form/TextEditor/MarkdownEditor'
import SelectableOptionsList from 'organization/Obie/Blocks/ProcessForm/SelectableOptionsList'
import {useLayout} from 'organization/Obie/Layout'
import {
  OBIE_OPTIONS_LIST,
  splitText,
} from 'organization/Obie/ObieServiceProvider'

const SPEED = 6

export type EffectProps = {
  text: string
  onComplete?: () => void
  containerRef?: React.RefObject<HTMLDivElement>
  autoScroll?: boolean
  updateCompletion?: (newValue: string) => void
  className?: string
  isVisible?: boolean
}

// TypingEffect Component
export function TypingEffect(props: EffectProps) {
  const {text, onComplete, containerRef} = props
  const [displayedText, setDisplayedText] = useState('')

  const startTime = useRef<number | null>(null)
  const animationId = useRef<number | null>(null)
  const finished = useRef<boolean>(false)
  const isTyping = useRef<boolean>(false)

  useEffect(() => {
    // If we've already marked ourselves as finished for this text, short circuit.
    // Don't want to start the animation again for text we've already processed.
    if (finished.current) {
      return
    }

    const animate = (timestamp: number) => {
      if (startTime.current === null) {
        startTime.current = timestamp
      }

      const elapsedTime = timestamp - startTime.current
      const charactersToShow = Math.floor(elapsedTime / SPEED)

      if (charactersToShow >= text.length) {
        setDisplayedText(text)

        requestAnimationFrame(() => {
          if (containerRef?.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight
          }
        })

        cancelAnimationFrame(animationId.current!)
        animationId.current = null

        isTyping.current = false
        finished.current = true
        onComplete && onComplete()

        return
      }

      setDisplayedText(text.substring(0, charactersToShow + 1))

      requestAnimationFrame(() => {
        if (containerRef?.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight
        }
      })

      animationId.current = requestAnimationFrame(animate)
    }

    animationId.current = requestAnimationFrame(animate)

    return () => {
      // If we're not "typing", get out of here, so we don't mess up the state
      // of this animation. If we don't do this, it causes problems... ask me
      // how I know - Tyler.
      if (!isTyping.current) {
        return
      }

      if (animationId.current !== null) {
        cancelAnimationFrame(animationId.current)
      }

      startTime.current = null
    }
  }, [containerRef, text, onComplete])

  if (finished.current && props.updateCompletion) {
    return (
      <TypedText>
        <MarkdownEditor
          data={displayedText}
          onChange={props.updateCompletion}
          theme="Dark"
        />
      </TypedText>
    )
  }
  return (
    <TypedText ref={containerRef} withPadding className={props.className}>
      <Markdown>{displayedText}</Markdown>
    </TypedText>
  )
}

// FirstTextComponent
const FirstTextComponent = (props: EffectProps) => {
  const {className, text, onComplete, containerRef, autoScroll} = props

  return (
    <TypingEffect
      text={text}
      onComplete={onComplete}
      containerRef={containerRef}
      autoScroll={autoScroll}
      className={className}
    />
  )
}

// SecondTextComponent
const SecondTextComponent = (props: EffectProps) => {
  const {isVisible, text, onComplete, containerRef, autoScroll} = props

  const [typingCompleted, setTypingCompleted] = useState<boolean>(false)

  // If we're not supposed to be visible, split. This is usually waiting for some
  // other render to happen, order of operations.
  if (!isVisible) {
    return null
  }

  // If we don't have any text provided to us, try to do any onComplete sent in
  // and get out of here.
  if (!text) {
    onComplete && onComplete()

    return null
  }

  // Normal operation "onComplete". Because we have some paths, we need to make
  // handlers for each case, so that we can easily define them.
  const handleStandardComplete = () => {
    onComplete && onComplete()
  }

  // The "onComplete" handler when there is a SelectableOptionsList.
  const handleTypingCompleted = () => {
    setTypingCompleted(true)
  }

  // Checking for the Selectable Options List identifier, so decisions can be made.
  const optionsListParts = text.split(OBIE_OPTIONS_LIST)

  // If there is a SelectableOptionsList to render, there may or may not be any
  // text in the first element of the (now) array of text.
  const typingText = optionsListParts.length > 1 ? optionsListParts[0] : text

  // If there is a SelectableOptionsList to render, the onComplete for the
  // TypingEffect component in this case will be to toggle the visiblity of the
  // list itself. Otherwise we just need to do the standard complete routine that
  // may have been passed in.
  const handleOnComplete =
    optionsListParts.length > 1 ? handleTypingCompleted : handleStandardComplete

  return (
    <>
      <TypingEffect
        text={typingText}
        onComplete={handleOnComplete}
        containerRef={containerRef}
        autoScroll={autoScroll}
        updateCompletion={props.updateCompletion}
      />
      <SelectableOptionsList
        isVisible={typingCompleted}
        text={optionsListParts[1]}
        onComplete={handleStandardComplete}
        containerRef={containerRef}
        autoScroll={autoScroll}
        updateCompletion={props.updateCompletion}
      />
    </>
  )
}

// ParentComponent
export default function ParentComponent(props: {
  text: string
  onFinish?: () => void
  updateCompletion?: (newValue: string) => void
  className?: string
}) {
  const {className} = props
  const [showSecondText, setShowSecondText] = useState(false)
  const {contentRef: containerRef} = useLayout()

  const userHasScrolled = useRef<boolean>(false)

  const splitTextParts = splitText(props.text)

  const handleFirstTextComplete = () => {
    if (!splitTextParts[1]) {
      props.onFinish && props.onFinish()

      return
    }

    // A little timeout befofe we tell it to show the second text component.
    setTimeout(() => {
      setShowSecondText(true)
    }, 1500)
  }

  useEffect(() => {
    const SCROLL_THRESHOLD = 20
    const container = containerRef.current

    if (!container) return

    const handleScroll = () => {
      const isAtBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + SCROLL_THRESHOLD

      if (!userHasScrolled.current && !isAtBottom) {
        userHasScrolled.current = true
      }

      if (userHasScrolled.current && isAtBottom) {
        userHasScrolled.current = false
      }
    }

    const scrollToBottom = () => {
      if (!userHasScrolled.current && container) {
        requestAnimationFrame(() => {
          container.scrollTop = container.scrollHeight
        })
      }
    }

    // We wanna watch the container for changes so we can do some auto-scrolling.
    const observer = new MutationObserver(() => {
      scrollToBottom()
    })

    observer.observe(container, {childList: true, subtree: true})

    container.addEventListener('scroll', handleScroll)

    // Initial scroll, kick it off.
    scrollToBottom()

    return () => {
      observer.disconnect()
      container.removeEventListener('scroll', handleScroll)
    }
  }, [containerRef])

  return (
    <>
      <FirstTextComponent
        text={splitTextParts[0]}
        onComplete={handleFirstTextComplete}
        className={className}
      />
      <SecondTextComponent
        isVisible={showSecondText}
        text={splitTextParts[1]}
        onComplete={props.onFinish}
        updateCompletion={props.updateCompletion}
      />
    </>
  )
}

// Styled Component for Typed Text
const TypedText = styled.div<{withPadding?: boolean}>`
  color: white;
  margin-bottom: 16px;
  line-height: 1.5;
  padding: 0
    ${(props) => (props.withPadding ? 'var(--ck-spacing-standard)' : 0)};
`
