import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {AgendaSettings} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useAttendeeVariables} from 'Event'
import {onChangeCheckedHandler, onChangeDate} from 'lib/dom'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  agenda: AgendaSettings
  watch: UseFormMethods['watch']
  setValue: UseFormMethods['setValue']
}) {
  const {control, register, agenda, watch} = props
  const v = useAttendeeVariables()

  const startDate = watch('startDate', agenda.startDate)

  return (
    <>
      <Controller
        name="isVisible"
        control={control}
        defaultValue={agenda.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config visible switch"
          />
        )}
      />
      <Label>Event</Label>
      <TextField
        name="text"
        defaultValue={v(agenda.text)}
        aria-label="agenda text"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="startDate"
        control={control}
        defaultValue={agenda.startDate}
        render={({value, onChange}) => {
          return (
            <LocalizedDateTimePicker
              value={value}
              onChange={onChangeDate(onChange)}
              fullWidth
              label="Start"
              minDate={new Date()}
              inputProps={{
                'aria-label': 'agenda start date',
              }}
            />
          )
        }}
      />
      <Controller
        name="endDate"
        control={control}
        defaultValue={agenda.endDate}
        render={({value, onChange}) => (
          <LocalizedDateTimePicker
            value={value}
            onChange={onChangeDate(onChange)}
            fullWidth
            label="End"
            minDate={startDate}
            inputProps={{
              'aria-label': 'agenda end date',
            }}
          />
        )}
      />
      <Label>Link</Label>
      <TextField
        name="link"
        defaultValue={v(agenda.link || '')}
        aria-label="agenda link"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
    </>
  )
}
