import React, {useState} from 'react'
import Button from '@material-ui/core/Button'
import {Agenda} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList'
import {AgendaItemConfig} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaItemConfig'
import {now} from 'lib/date-time'

export default function AddAgendaButton(props: {
  className?: string
  listId: string
  nextPosition: number
}) {
  const {nextPosition} = props
  const [agenda, setAgenda] = useState<Agenda | null>(null)

  const newAgenda = (): Agenda => ({
    text: 'Event',
    startDate: now(),
    endDate: now(),
    link: null,
    isVisible: true,
    position: nextPosition,
  })

  return (
    <>
      <NewAgendaConfig
        agenda={agenda}
        onClose={() => setAgenda(null)}
        listId={props.listId}
      />
      <Button
        fullWidth
        size="large"
        variant="contained"
        color="secondary"
        aria-label="add agenda event"
        onClick={() => setAgenda(newAgenda())}
        className={props.className}
      >
        Add Agenda Event
      </Button>
    </>
  )
}

function NewAgendaConfig(props: {
  agenda: Agenda | null
  onClose: () => void
  listId: string
}) {
  const {agenda, onClose, listId} = props
  if (!agenda) {
    return null
  }

  return (
    <AgendaItemConfig
      onClose={onClose}
      agenda={agenda}
      showing
      listId={listId}
    />
  )
}
