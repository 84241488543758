import React, {useCallback} from 'react'
import {Redirect} from 'react-router-dom'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useParams} from 'react-router-dom'
import {useListCustomPurchasePages} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-list-custom-purchase-pages'
import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'
import TemplateEditor from 'organization/Event/TemplateEditor'
import ConfigPageProvider from 'organization/Marketplace/config/ConfigPageProvider'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import PurchaseProvider from 'Event/Marketplace/PurchaseProvider'
import {useCountries} from 'lib/event-api/countries/use-countries'
import {client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import CountriesProvider, {State} from 'Event/Marketplace/lib/CountriesProvider'
import CustomPurchasePageTemplateUpdateProvider from 'organization/Marketplace/PurchasePageConfig/CustomPurchasePages/CustomPurchasePageTemplateUpdateProvider'
import CustomPurchasePageConfigContent from 'organization/Marketplace/PurchasePageConfig/CustomPurchasePages/CustomPurchasePageConfigContent'
import {ThankYouPageContext} from 'Event/Marketplace/ThankYouPageContext'
import useAutoCreatePurchasePage from 'organization/Marketplace/PurchasePageConfig/use-auto-create-purchase-page'
import {fillCustomPurchasePageTemplate} from 'organization/Marketplace/PurchasePageConfig/CustomPurchasePages/fill-custom-purchase-page-template'
import {PurchasePage} from 'Event/Marketplace/purchase-page'

interface ContentProps {
  customPurchasePage: CustomPurchasePage
}

export default function EditCustomPageTemplate() {
  const {purchasePage, isLoading} = useAutoCreatePurchasePage()

  if (isLoading) {
    return null
  }

  return <Init purchasePage={purchasePage} />
}

function Init(props: {purchasePage?: PurchasePage | null}) {
  const {purchasePage} = props
  const eventRoutes = useEventRoutes()
  const {custom_purchase_pages: routeId} = useParams<{
    custom_purchase_pages: string
  }>()
  const customPurchasePageId = parseInt(routeId)

  const {data: custom_purchase_pages, isLoading} = useListCustomPurchasePages()

  const customPurchasePages = custom_purchase_pages?.find(
    (custom_purchase_page) => custom_purchase_page.id === customPurchasePageId,
  )

  if (isLoading && !customPurchasePages) {
    return null
  }

  if (!customPurchasePages) {
    return <Redirect to={eventRoutes.marketplace.custom_purchase_pages.root} />
  }

  const isTemplateEmpty =
    customPurchasePages.template === null ||
    customPurchasePages.template.sections === undefined

  return (
    <Content
      customPurchasePage={{
        ...customPurchasePages,
        template:
          isTemplateEmpty && purchasePage?.template
            ? {...purchasePage.template, ...customPurchasePages.template}
            : customPurchasePages.template,
      }}
    />
  )
}

function Content(props: ContentProps) {
  const {customPurchasePage: custom_purchase_page} = props

  const {client: marketplaceClient} = useEventMarketplaceClient()

  const {data: countries = []} = useCountries()

  const fetchStates = useCallback((countryId: number) => {
    return client.get<State[]>(api(`/countries/${countryId}/states`))
  }, [])

  if (!marketplaceClient) return null

  return (
    <CountriesProvider countries={countries} fetchStates={fetchStates}>
      <ThankYouPageContext.Provider
        value={{
          loginUrl: null,
        }}
      >
        <ConfigPageProvider page={'custom_purchase_page'}>
          <CustomPurchasePageTemplateUpdateProvider
            customPurchasePage={custom_purchase_page}
            client={marketplaceClient}
          >
            {(template) => (
              <TemplateEditor template={template}>
                {(template) => (
                  <PurchaseProvider
                    availableTicketIds={template.available_tickets}
                  >
                    <CustomPurchasePageConfigContent
                      template={fillCustomPurchasePageTemplate(template)}
                      customPurchasePage={custom_purchase_page}
                      client={marketplaceClient}
                    />
                  </PurchaseProvider>
                )}
              </TemplateEditor>
            )}
          </CustomPurchasePageTemplateUpdateProvider>
        </ConfigPageProvider>
      </ThankYouPageContext.Provider>
    </CountriesProvider>
  )
}
