import {useLeftyTemplate} from 'Event/template/Lefty'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {TemplateSave} from 'Event/TemplateUpdateProvider'
import {Template} from 'Event/template'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ColorPicker from 'lib/ui/ColorPicker'

export default function FaqsHeaderConfig() {
  const save = useSaveTemplate()
  const {faq} = useLeftyTemplate()

  const {
    form: {register, control},
  } = useConfig()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const handleSave: TemplateSave<Template> = (data) => {
    const title = replaceAtPath(data, 'faq.title', '{{faq_title}}')

    const description = replaceAtPath(
      data,
      'faq.description',
      '{{faq_description}}',
    )

    const secondaryTitle = replaceAtPath(
      data,
      'faq.secondaryTitle',
      '{{faq_secondary_title}}',
    )

    save({
      ...data,
      localization: addTranslation({
        faq_title: title || 'Faq',
        faq_description: description || '',
        faq_secondary_title: secondaryTitle || '',
      }),
    })
  }

  return (
    <Config title="Header" onSave={handleSave}>
      <SettingsPanel>
        <TextField
          name="faq.title"
          defaultValue={v(faq.title)}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'faq page title',
            ref: register,
          }}
        />
        <TextField
          name="faq.secondaryTitle"
          defaultValue={v(faq.secondaryTitle)}
          label="Secondary Title"
          fullWidth
          inputProps={{
            'aria-label': 'faq secondary title',
            ref: register,
          }}
        />
        <Title>Description</Title>
        <Controller
          name="faq.description"
          defaultValue={v(faq.description)}
          control={control}
          render={({onChange, value}) => (
            <TextEditorContainer>
              <TextEditor data={value} onChange={onChange} />
            </TextEditorContainer>
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="faq.cardTextColor"
          defaultValue={faq.cardTextColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="FAQ Text Color"
              color={value}
              onPick={onChange}
              aria-label="faq card text color"
            />
          )}
        />
        <Controller
          name="faq.cardBackgroundColor"
          defaultValue={faq.cardBackgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="FAQ Background Color"
              color={value}
              onPick={onChange}
              aria-label="faq card background color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
