import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import CustomPurchasePageItem from 'organization/Marketplace/PurchasePageConfig/CustomPurchasePages/CustomPurchasePageItem'
import {useListCustomPurchasePages} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-list-custom-purchase-pages'

export default function CustomPurchasePageList() {
  return (
    <Box>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Slug</TableCell>
        <MenuTableCell>{/* "More Menu" Cell */}</MenuTableCell>
      </TableRow>
      <Content />
    </Box>
  )
}

function Content() {
  const eventRoutes = useEventRoutes()
  const {data: customPurchasePages} = useListCustomPurchasePages()

  if (!customPurchasePages || customPurchasePages.length === 0) {
    return (
      <TableRow>
        <AddNewTableCell>
          <StyledRelativeLink
            disableStyles
            to={eventRoutes.marketplace.custom_purchase_pages.add}
          >
            <NewItemButton text="New page" color="primary" />
          </StyledRelativeLink>
        </AddNewTableCell>
      </TableRow>
    )
  }

  return (
    <>
      {customPurchasePages.map((customPurchasePage, index) => (
        <CustomPurchasePageItem
          key={index}
          customPurchasePage={customPurchasePage}
          index={index}
        />
      ))}
    </>
  )
}

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`
export const TableCell = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 62px;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  vertical-align: inherit;

  button {
    text-align: left;
  }
`
export const DateTableCell = styled(TableCell)`
  display: none;
  flex: unset;
  width: 150px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: flex;
  }
`
export const PriceTableCell = styled(TableCell)`
  flex: unset;
  width: 110px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 160px;
  }
`
export const IndicatorTableCell = styled(TableCell)`
  flex: unset;
  width: 90px;
`
export const MenuTableCell = styled(TableCell)`
  flex: unset;
  width: 50px;
`
export const CopyCell = styled(TableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing[2]};
`
const AddNewTableCell = styled(TableCell)`
  justify-content: center;
  border-bottom: none !important;
  text-align: center;
`
const StyledRelativeLink = styled(RelativeLink)`
  display: inline-block;
`
