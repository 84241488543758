import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CustomButtonLinkEditing from './custombuttonlinkediting';
import CustomButtonLinkUI from './custombuttonlinkui';
import { isCustomButtonWidget } from '../utils';

export default class CustomButtonLink extends Plugin {
	static get requires() {
		return [ CustomButtonLinkEditing, CustomButtonLinkUI ];
	}

	static get pluginName() {
		return 'CustomButtonLink';
	}

	init() {
		const editor = this.editor;
		const viewDocument = editor.editing.view.document;

		// Prevent <a> click event on editor.
		this.listenTo( viewDocument, 'click', ( evt, data ) => {
			const isEditable = isCustomButtonWidget( viewDocument.selection.getSelectedElement()! );
			const isEditableParent = isCustomButtonWidget( data.target.parent );

			if ( isEditable || isEditableParent ) {
				data.preventDefault();
			}
		} );
	}
}
