import {GridSize, GridSpacing} from '@material-ui/core/Grid'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {BaseTemplate, Details} from 'Event/template'
import {EmojiList} from 'Event/template/NiftyFifty/Dashboard/EmojiList'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {HashMap} from 'lib/list'
import {Column} from 'lib/ui/layout'
import {colors} from 'lib/ui/theme'
import {DeepRequired} from 'lib/type-utils'
import {InputStyles} from 'Event/Question'
import {Font} from 'lib/FontSelect'
import {ResourceListSettings} from 'Event/template/NiftyFifty/Dashboard/Resources/ResourceList'
import {InputStyleProps} from 'Event/auth/TextField'
import {rgba} from 'lib/color'
import {createBaseTemplate} from 'Event/template/base'
import {createNavButtonWithSize} from 'Event/Dashboard/components/NavButton/create'
import {AgendaListSettings} from 'Event/template/NiftyFifty/Dashboard/AgendaList'
import {LoginButtonProps} from 'Event/auth/Login/SubmitButton/Button'
import {ENGLISH} from 'Event/LanguageProvider/language'
export {convert} from 'Event/template/NiftyFifty/converter'

/**
 * Template Name (Required)
 * --------------------------------------------------
 * Must be unique, and match the template directory
 * for dynamic imports to work.
 */
export const NIFTY_FIFTY = 'NiftyFifty'

export const MAX_LOGO_SIZE_PERCENT = 100
export const MIN_LOGO_SIZE_PERCENT = 20

export const details: Details = {
  label: 'NiftyFifty',
  previewUrl:
    'https://nifty-demo.obv.io/login?token=1a01826354e8b46ee35b895a7e107caf',
}

export function useNiftyFiftyTemplate() {
  const template = useTemplate()

  if (template.name !== NIFTY_FIFTY) {
    throw new Error('useNiftyFifty called with wrong template')
  }

  return template
}

export function useNiftyFiftyUpdate() {
  return useSaveTemplate<NiftyFifty>()
}

/**
 * Determine the background depending the panel specific
 * props.
 *
 * @param props
 * @returns
 */
export function background(props: {
  enabled: boolean
  color: string
  opacity: number
  image: string | null
}) {
  const rgbaColor = rgba(props.color, props.opacity)

  if (!props.enabled) {
    return rgbaColor
  }

  if (props.image) {
    return `url(${props.image}) no-repeat center`
  }

  return rgbaColor
}

/**
 * Whether the template has other tabs/sections to navigate to. Useful
 * to check for cases where we want to hide something when there is
 * only one tab. ie. home button.
 *
 * @returns
 */
export function useHasMultipleTabs() {
  const {
    resourceList: {isVisible: showingResources},
    leaderboard: {isVisible: showingPoints},
    speakers: {isEnabled: showingSpeakers},
    sponsors: {isEnabled: showingSponsors},
    imageWaterfall: {isVisible: showingImageWaterfall},
    faq: {isEnabled: showingFaqs},
  } = useNiftyFiftyTemplate()

  return (
    showingSpeakers ||
    showingSponsors ||
    showingResources ||
    showingPoints ||
    showingImageWaterfall ||
    showingFaqs
  )
}

export type NiftyFifty = BaseTemplate & {
  name: typeof NIFTY_FIFTY
  accentColor: string
  menu: {
    backgroundColor: string
    backgroundOpacity: number
    iconColor: string
    textColor: string
  }
  backgroundPosition: 'fixed' | 'bottom'
  mainNav: {
    buttons: HashMap<NavButtonWithSize>
    spacing: number
  }
  welcomeText: string
  welcomeTextFont?: Font | null
  welcomeTextFontSize?: number
  welcomeTextColor?: string | null
  welcomeTextJustify?: string
  welcomeTextWidth?: number
  homeMenuTitle: string
  emojiList: EmojiList
  pageTitleColor?: string | null
  textColor: string
  linkColor: string
  linkUnderline: boolean
  leftPanel: {
    backgroundHidden: boolean
    backgroundColor: string
    backgroundOpacity: number
    arrowColor?: string | null
  }
  rowBackgroundPrimary: string | null
  rowBackgroundSecondary: string | null
  dashboardLogo?: {
    image: string | null
    enabled?: boolean
    size: number
  }
  dashboardBackground: {
    image: string | null
    enabled?: boolean
  }
  checkInLogo: {
    image: string | null
    enabled: boolean
    size: number
  }
  checkInBackground: {
    image: string | null
    enabled?: boolean
  }
  rightPanel: {
    barBackgroundColor: string
    barTextColor: string
    tabUnderlineColor: string
    backgroundColor: string
    backgroundOpacity: number
    indicatorWidth: number
    padding: number
  }
  resourceList: ResourceListSettings
  agendaList?: AgendaListSettings
  checkInRightPanel: {
    backgroundColor: string
    backgroundOpacity: number
    textColor: string | null
    contentAlign?: string
  }
  checkInLeftPanel: {
    backgroundColor: string
    backgroundOpacity: number
  }
  imageWaterfall: BaseTemplate['imageWaterfall'] & {
    menuTitle?: string
  }
  leaderboard: {
    title: string
    description: string
    menuTitle: string
    isVisible: boolean
  }
  faq: {
    menuTitle: string
  }
  waiver: {
    buttonText: string
    buttonBackground: string
    buttonHoverBackground: string
    buttonTextColor: string
    buttonBorderRadius: number
    buttonBorderWidth: number
    buttonBorderColor: string
    buttonWidth: GridSize
    formStyles: InputStyles
    input?: InputStyleProps
    firstNameInputLabel?: string
    lastNameInputLabel?: string
  }
  login: {
    submitButton: LoginButtonProps
    description: {
      text: string
      color: string | null
      fontSize: number
    }
    passwordReset: {
      linkLabel: string
      buttonText: string
      description: string
      successMessage: string
    }
    logoSize: number
    input: InputStyleProps
    emailLabel: string
    passwordLabel: string
    loginRightPanel: {
      backgroundColor: string
      backgroundOpacity: number
    }
  }
  setPasswordForm: {
    title: string
    description: string
    passwordLabel: string
    confirmPasswordLabel: string
    button: NavButtonWithSize
    input?: InputStyleProps
  }
  sponsors: {
    imageSize?: Column
    spacing?: GridSpacing
    menuTitle?: string
  }
  speakers: {
    spacing: GridSpacing
    menuTitle: string
  }
  countDownTimers: HashMap<CountDownTimerSettings>
  progressBar: {
    barColor: string
    backgroundColor: string
    textColor: string
    thickness: number
    borderRadius: number
    showing: boolean
    step1Text: string
    step1Percent: number
    step2Text: string
    step2Percent: number
    step3Text: string
    step3Percent: number
    progressBar: any
  }
}

/**
 * Create a Template instance with no preset data (required)
 */
export const createBlank = (): DeepRequired<NiftyFifty> => ({
  ...createBaseTemplate(),
  version: 1,
  name: NIFTY_FIFTY,
  accentColor: '#B8FFF7',
  menu: {
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
    iconColor: '#000000',
    textColor: '#000000',
  },
  backgroundPosition: 'fixed',
  mainNav: {
    buttons: {},
    spacing: 10,
  },
  rowBackgroundPrimary: null,
  rowBackgroundSecondary: null,
  welcomeText: '{{welcome_text}}',
  welcomeTextFont: null,
  welcomeTextFontSize: 50,
  welcomeTextColor: null,
  welcomeTextJustify: 'left',
  welcomeTextWidth: 100,
  homeMenuTitle: '{{home_menu_title}}',
  emojiList: {
    emojis: [],
    emojiWidth: null,
    rules: [],
    showingFrom: null,
    showingUntil: null,
  },
  imageWaterfall: {
    ...createBaseTemplate().imageWaterfall,
    menuTitle: '{{image_waterfall_menu_title}}',
  },
  pageTitleColor: null,
  textColor: '#000000',
  linkColor: '#000000',
  linkUnderline: true,
  leftPanel: {
    backgroundHidden: false,
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
    arrowColor: '#000000',
  },
  dashboardLogo: {
    image: null,
    enabled: true,
    size: 20,
  },
  dashboardBackground: {
    image: null,
    enabled: true,
  },
  checkInLogo: {
    image: null,
    enabled: true,
    size: 20,
  },
  checkInBackground: {
    image: null,
    enabled: true,
  },
  rightPanel: {
    barBackgroundColor: '#FFFFFF',
    barTextColor: '#000000',
    tabUnderlineColor: '#B8FFF7',
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
    indicatorWidth: 50,
    padding: 0,
  },
  resourceList: {
    title: '{{resources_list_title}}',
    resources: [],
    menuTitle: '{{resources_list_menu_title}}',
    isVisible: true,
    color: null,
    linkColor: 'blue',
  },
  agendaList: {
    title: '{{agenda_list_title}}',
    menuTitle: '{{agenda_list_menu_title}}',
    items: {},
    isVisible: false,
    footer: '',
    footerFontStyles: [],
    description: '',
    descriptionFontStyles: [],
    color: '',
    font: null,
    separator: {
      color: '#ffffff',
    },
  },
  checkInRightPanel: {
    backgroundColor: '#07BAB5',
    backgroundOpacity: 100,
    textColor: null,
    contentAlign: 'flex-start',
  },
  checkInLeftPanel: {
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
  },
  leaderboard: {
    title: '{{leaderboard_title}}',
    description: '{{leaderboard_description}}',
    menuTitle: '{{leaderboard_menu_title}}',
    isVisible: false,
  },
  faq: {
    title: '{{faq_title}}',
    description: '{{faq_description}}',
    menuTitle: '{{faq_menu_title}}',
    isEnabled: false,
    items: {},
  },
  waiver: {
    checkboxColor: '',
    button: createNavButtonWithSize({text: 'submit'}),
    targets: {},
    isEnabled: false,
    buttonText: '{{waiver_button_text}}',
    buttonBackground: colors.primary,
    buttonHoverBackground: colors.secondary,
    buttonTextColor: '#FFFFFF',
    buttonBorderRadius: 0,
    buttonBorderWidth: 0,
    buttonBorderColor: colors.primary,
    buttonWidth: 12,
    formStyles: {
      labelColor: '#7d7a7a',
      borderColor: '#7d7a7a',
      backgroundColor: '#7d7a7a',
      backgroundOpacity: 0,
      textColor: '#7d7a7a',
      helperTextColor: '#7d7a7a',
    },
    skipRules: [],
    input: {
      labelColor: colors.primary,
      textColor: '#000000',
      borderColor: colors.primary,
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
    firstNameInputLabel: '{{waiver_first_name_input_label}}',
    lastNameInputLabel: '{{waiver_last_name_input_label}}',
    signature: {
      typeButtonText: '{{waiver_signature_button_text}}',
      typeButtonColor: '#000000',
      typeInputLabel: '{{waiver_signature_input_label}}',
    },
    signatureRequired: true,
    formEnabled: true,
    submittingText: '{{waiver_submitting_text}}',
  },
  login: {
    ...createBaseTemplate().login,
    input: {
      borderRadius: 5,
      labelColor: '',
      textColor: '',
      borderColor: '',
      borderWidth: 0,
      backgroundColor: '',
    },
    logoEnabled: true,
    submitButton: {
      backgroundColor: colors.primary,
      textColor: '#FFFFFF',
      hoverBackgroundColor: colors.primary,
      label: '{{login_submit_button_label}}',
      borderRadius: 56,
      font: null,
      fontSize: 18,
      disableHover: true,
      hoverTextColor: '#FFFFFF',
      height: 64,
      width: 12,
      borderColor: colors.primary,
      hoverBorderColor: colors.primary,
      borderWidth: 0,
    },
    description: {
      text: '',
      color: null,
      fontSize: 18,
    },
    passwordReset: {
      linkLabel: '{{password_reset_link_label}}',
      buttonText: '{{password_reset_button_text}}',
      description: '{{password_reset_description}}',
      successMessage: '{{password_reset_success_message}}',
      iconName: '',
      iconColor: '',
    },
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
    logoSize: 40,
    backgroundEnabled: true,
    emailLabel: '{{login_email_label}}',
    passwordLabel: '{{login_password_label}}',
    loginRightPanel: {
      backgroundColor: '#FFFFFF',
      backgroundOpacity: 0,
    },
  },
  setPasswordForm: {
    title: '{{set_password_form_title}}',
    description: '{{set_password_form_description}}',
    passwordLabel: '{{set_password_label}}',
    confirmPasswordLabel: '{{set_password_confirm_label}}',
    button: createNavButtonWithSize({text: '{{set_password_button_text}}'}),
    input: {
      labelColor: '#3490DC',
      textColor: '#000000',
      borderColor: '#3490DC',
      borderWidth: 0,
      backgroundColor: '#FFFFFF',
      borderRadius: 5,
    },
  },
  sponsors: {
    ...createBaseTemplate().sponsors,
    imageSize: 6,
    spacing: 0,
    menuTitle: '{{sponsors_menu_title}}',
  },
  speakers: {
    ...createBaseTemplate().speakers,
    spacing: 0,
    menuTitle: '{{speakers_menu_title}}',
  },
  countDownTimers: {},
  progressBar: {
    barColor: '#0969d6',
    backgroundColor: '#b1d4f1',
    textColor: '#000000',
    thickness: 30,
    borderRadius: 50,
    showing: true,
    step1Text: '{{check_in_step1_label}}',
    step1Percent: 33,
    step2Text: '{{check_in_step2_label}}',
    step2Percent: 66,
    step3Text: '{{check_in_step3_label}}',
    step3Percent: 100,
    progressBar: {},
  },

  localization: {
    languages: [{name: ENGLISH, rules: []}],
    translations: {
      English: {
        welcome_text: 'Welcome!',
        home_menu_title: 'Home',
        image_waterfall_menu_title: 'Image Waterfall',
        resources_list_title: 'Resources',
        resources_list_menu_title: 'Resources',
        agenda_list_title: 'Agendas',
        agenda_list_menu_title: 'Agendas',
        leaderboard_title: 'Leaderboard',
        leaderboard_description:
          '<p>{{first name}}, you have earned {{leaderboard_points}} {{points_unit}}, and you are currently {{leaderboard_position}}. Great Job!</p><p><i>The list below is the top 200 point earners! If you don’t see your name listed, there’s still time!</i></p><p><br>&nbsp;</p>',
        leaderboard_menu_title: 'Points',
        faq_title: 'FAQ',
        faq_description: '',
        faq_menu_title: 'Faqs',
        waiver_button_text: 'Submit',
        waiver_first_name_input_label: 'First Name',
        waiver_last_name_input_label: 'Last Name',
        waiver_signature_button_text: 'Click here to type signature instead',
        waiver_signature_input_label: 'Signature',
        waiver_submitting_text: 'Generating...',
        login_submit_button_label: 'Login',
        password_reset_link_label: 'Forgot Password?',
        password_reset_button_text: 'Send Reset Password Link',
        password_reset_description: 'Send password reset link.',
        password_reset_success_message:
          "Password reset link sent! Check your spam folder if you don't see it after a couple minutes.",
        login_email_label: 'Email',
        login_password_label: 'Password',
        set_password_form_title: "You're almost ready",
        set_password_form_description:
          "First, you'll need to create a password below:",
        set_password_label: 'Enter your password',
        set_password_confirm_label: 'Re-Enter Your Password',
        set_password_button_text: 'Submit',
        sponsors_menu_title: 'Sponsors',
        speakers_menu_title: 'Speakers',
        check_in_step1_label: 'Step 1',
        check_in_step2_label: 'Step 2',
        check_in_step3_label: 'Step 3',
      },
    },
    isAdvancedMode: false,
    defaultLanguage: ENGLISH,
    translationsEnabled: false,
  },
})

export const DEFAULTS = {...createBlank(), localization: null}

export const sampleEventURL = 'https://v1.obv.io/events/nifty-demo'
