import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {DeepRequired} from 'lib/type-utils'
import {colors} from 'lib/ui/theme'

export function createNavButtonWithSize(
  overrides?: Partial<NavButtonWithSize>,
): DeepRequired<NavButtonWithSize> {
  return {
    text: 'Button',
    textSecond: null,
    link: '',
    rules: [],
    isAreaButton: false,
    isImageUpload: false,
    isFormButton: false,
    isCertificateButton: false,
    areaId: null,
    formId: null,
    actionId: null,
    certificateId: null,
    certificate: {
      emailSentBackgroundColor: null,
      emailSentTextColor: null,
      emailSentText: null,
      generatingText: null,
      generatingImage: null,
    },
    isVisible: true,
    infusionsoftTag: null,
    hubspotTag: null,
    activeCampaignTag: null,
    convertKitTag: null,
    ontraportTag: null,
    page: null,
    mailchimpTag: null,
    fontSize: 16,
    font: null,
    padding: 8,
    height: 34,
    zapierTag: null,
    highLevelTag: null,
    image: '',
    backgroundColor: colors.primary,
    hoverBackgroundColor: colors.primary,
    textColor: '#FFFFFF',
    hoverTextColor: '#FFFFFF',
    newTab: false,
    'aria-label': 'button',
    newLine: false,
    className: '',
    size: 12,
    width: 100,
    borderRadius: 0,
    borderWidth: 0,
    borderColor: '#FFFFFF',
    hoverBorderColor: '#FFFFFF',
    icon: '',
    iconSize: 16,
    iconStacked: false,
    disableHover: false,
    position: 0,
    showingFrom: null,
    showingUntil: null,
    onClick: null,
    webhook: null,
    confirmRequired: false,
    revealDelaySecs: null,
    confirmDescription: '',
    confirmButtonLabel: '',
    confirmButtonFontSize: 12,
    confirmButtonBackgroundColor: '',
    confirmButtonTextColor: '',
    ButtonProps: {
      Content: null,
    },
    typographySecond: {
      font: null,
      fontSize: 16,
      textColor: '#FFFFFF',
      hoverTextColor: '#FFFFFF',
    },
    iconAlign: 'left',
    ...overrides,
  }
}
