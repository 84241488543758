import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React, {useState} from 'react'
import TextEditor, {
  ObvioVariable,
  TextEditorContainer,
} from 'lib/ui/form/TextEditor'
import {Controller} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Label} from 'lib/ui/typography'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/config/BlockConfig/VisibilitySettings'
import {TextBlock} from 'Event/Marketplace/Block/Text'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import MoveSectionButtons from 'organization/Marketplace/config/BlockConfig/MoveSectionButtons'
import {useSection} from 'Event/Marketplace/Section'
import {defaultToolbar} from 'lib/ui/form/TextEditor/toolbar'
import {
  EVENT_NAME,
  FIRST_NAME,
  LAST_NAME,
  LOGIN_URL,
  ORDER_TOTAL,
  TICKET_NAME,
  TIME,
  TODAY,
} from 'lib/ui/form/TextEditor/variables'
import {
  useConfigPage,
  ConfigPage,
} from 'organization/Marketplace/config/ConfigPageProvider'

interface TextConfigProps extends TextBlock {
  id: string
  sectionId: string
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
}

const variables: {
  [key in ConfigPage]: ObvioVariable[]
} = {
  purchase_page: [EVENT_NAME, TODAY, TIME],
  custom_purchase_page: [EVENT_NAME, TODAY, TIME],
  upsell_page: [
    FIRST_NAME,
    LAST_NAME,
    EVENT_NAME,
    TODAY,
    TIME,
    TICKET_NAME,
    ORDER_TOTAL,
    LOGIN_URL,
  ],
  thank_you_page: [
    FIRST_NAME,
    LAST_NAME,
    EVENT_NAME,
    TODAY,
    TIME,
    TICKET_NAME,
    ORDER_TOTAL,
    LOGIN_URL,
  ],
}

const links: {
  [key in ConfigPage]: string[]
} = {
  purchase_page: [],
  custom_purchase_page: [],
  upsell_page: ['Login'],
  thank_you_page: ['Login'],
}

export default function TextConfig(props: TextConfigProps) {
  const {
    text,
    padding,
    alignment,
    background,
    id,
    onMoveSectionUp,
    onMoveSectionDown,
  } = props
  const {update} = useConfigurableSection()
  const {calculateVisibility} = useSection()
  const {page} = useConfigPage()
  const {
    form: {control},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    update({
      blocks: {
        [id]: REMOVE,
      },
    })
    calculateVisibility()
  }

  const save = (data: any) => {
    data.visibility = visibility
    update({
      blocks: {
        [id]: data,
      },
    })
    calculateVisibility()
  }

  return (
    <Config title="Text Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`text`}
          defaultValue={text}
          control={control}
          render={({value, onChange}) => (
            <TextEditorContainer>
              <TextEditor
                data={value}
                onChange={onChange}
                toolbar={[...defaultToolbar, 'obvioVariable']}
                variables={variables[page]}
                customLinks={links[page]}
              />
            </TextEditorContainer>
          )}
        />
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <MoveSectionButtons
          onMoveUp={onMoveSectionUp}
          onMoveDown={onMoveSectionDown}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${id}-background`}
        >
          <Controller
            name={`background.image`}
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'block background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`background.color`}
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`background.opacity`}
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection label="Border" storageKey={`blocks-${id}-border`}>
          <Controller
            name={`border.width`}
            defaultValue={props.border.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Thickness"
              />
            )}
          />
          <Controller
            name={`border.radius`}
            defaultValue={props.border.radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
          <Controller
            name={`border.color`}
            defaultValue={props.border.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-spacing`}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`padding`}
            values={padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}
