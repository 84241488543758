import React, {useState} from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {TextFieldProps} from '@material-ui/core/TextField'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import {formatPrice} from 'lib/currency'
import {onChangeCheckedHandler} from 'lib/dom'
import {fieldError} from 'lib/form'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {ValidationError} from 'lib/ui/api-client'
import DescribedGroupsInput, {GroupPair} from 'lib/ui/form/DescribedGroupsInput'
import DescribedTagsInput from 'lib/ui/form/DescribedTagsInput'
import DescribedSwitch from 'lib/ui/form/DescribedSwitch'
import PageHeader from 'lib/ui/PageHeader'
import PageHeaderButton from 'lib/ui/PageHeader/Button'
import Title from 'lib/ui/PageHeader/Title'
import DescribedTextArea from 'lib/ui/TextField/DescribedTextArea'
import DescribedTextField from 'lib/ui/TextField/DescribedTextField'
import {CreateTicketData} from 'lib/marketplace-api/tickets/use-create-ticket'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'
import Visible from 'lib/ui/layout/Visible'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {useOrganization} from 'organization/OrganizationProvider'
import {Link} from 'obvio/auth/Page'
import styled from 'styled-components'
import DescribedConvertKitTagInput from 'lib/ui/form/DescribedConvertKitTagInput'
import DescribedOntraportTagInput from 'lib/ui/form/DescribedOntraportTagInput'
import DescribedActiveCampaignTagInput from 'lib/ui/form/DescribedActiveCampaignTagInput'
import DescribedInfusionsoftTagInput from 'lib/ui/form/DescribedInfusionsoftTagInput'
import {ActiveCampaignTag} from 'Event/activeCampaign'
import DescribedHighlevelTagInput from 'lib/ui/form/DescribedHighlevelTagInput'
import {InfusionsoftTag} from 'Event/infusionsoft'
import DescribedMailchimpTagInput from 'lib/ui/form/DescribedMailchimpTagInput'
import {MailchimpTag} from 'Event/mailchimp'
import {ConvertKitTag} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import {OntraportTag} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'
import {HighLevelTag} from 'Event/highlevel'
import {Description as DescriptionText} from 'lib/ui/typography'
import Label from 'lib/ui/TextField/Label'
import Checkbox from 'lib/ui/Checkbox'
import {HubspotTag} from 'Event/hubspot'
import DescribedHubspotTagInput from 'lib/ui/form/DescribedHubspotTagInput'

export interface TicketFormData {
  account_id?: number
  name: string
  slug: string
  title: string
  description: string
  tags: string[]
  groups: string[]
  price: number
  available_from: string
  available_to: string
  hidden?: boolean
  active?: boolean
  infusionsoft_tag?: InfusionsoftTag
  mailchimp_tag?: MailchimpTag
  convert_kit_tag?: ConvertKitTag
  ontraport_tag?: OntraportTag
  active_campaign_tag?: ActiveCampaignTag
  highlevel_tag?: HighLevelTag
  hubspot_tag?: HubspotTag
}

export default function Form(props: {
  control: UseFormMethods['control']
  formErrors: UseFormMethods['errors']
  isEditing?: boolean
  onSubmit: () => void
  responseError: ValidationError<CreateTicketData>
  setValue: UseFormMethods['setValue']
  submitting: boolean
  ticket?: MarketplaceTicket
  existingGroups?: GroupPair[]
  onChangeExistingGroups?: (groups: GroupPair[]) => void
  newGroups: GroupPair[]
  onChangeNewGroups: (groups: GroupPair[]) => void
  watch: UseFormMethods['watch']
  isMissingStripeAccount: boolean
}) {
  const {
    formErrors,
    isEditing,
    onSubmit,
    responseError,
    setValue,
    submitting,
    ticket,
    existingGroups,
    onChangeExistingGroups,
    newGroups,
    onChangeNewGroups,
    watch,
    isMissingStripeAccount,
  } = props

  const {routes} = useOrganization()

  const [slugDirty, setSlugDirty] = useState<boolean>(Boolean(isEditing))
  // If we're in edit mode and don't have a ticket value yet, null-out.
  if (isEditing && !ticket) {
    return null
  }

  const billingAddressEnabled = watch(
    `billing_address_enabled`,
    ticket?.billing_address_enabled,
  )
  const shippingAddressEnabled = watch(
    `shipping_address_enabled`,
    ticket?.shipping_address_enabled,
  )

  const error = (key: keyof CreateTicketData) =>
    fieldError(key, {
      form: formErrors,
      response: responseError,
    })

  const errors = {
    name: error('name'),
    slug: error('slug'),
    title: error('title'),
    description: error('description'),
    // start: error('start'),
    // end: error('end'),
    price: error('price'),
  }

  const handleFromDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      return
    }
    setValue('available_from', date.toISOString())
  }

  const handleToDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      return
    }
    setValue('available_to', date.toISOString())
  }

  const slugValue = (value: string) => {
    // If a true value for slugDirty, means that user has manually adjusted the
    // slug value and we don't want to auto-compute it for them.
    if (slugDirty) {
      return
    }

    const cleanedSlug = value
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^0-9a-z-]/gi, '')

    // Set the computed value into Control
    setValue('slug', cleanedSlug)
  }

  const formatTicketPrice = (value: string) => {
    setValue('price', value)
  }

  return (
    <>
      <StyledPageHeader hasError={isMissingStripeAccount}>
        <Title text={formTitle(ticket)} />
        <PageHeaderButton
          disabled={submitting}
          onClick={onSubmit}
          text={formButtonText(ticket)}
        />
      </StyledPageHeader>
      {isMissingStripeAccount && (
        <StyledErrorAlert>
          <>
            In Order to Sell Tickets, you must first connect Stripe to your
            Organization <Link to={routes.settings}>on the settings page</Link>.
          </>
        </StyledErrorAlert>
      )}

      <form onSubmit={onSubmit}>
        <Controller
          name="name"
          control={props.control}
          defaultValue={ticket?.name || ''}
          rules={{
            required: 'Name is required',
          }}
          render={({onChange, value}) => (
            <DescribedTextField
              title="Ticket Name*"
              aria-label="ticket name"
              description={'Your ticket name'}
              name="name"
              required
              fullWidth
              disabled={submitting}
              value={value}
              onChange={(value) => {
                slugValue(value)
                onChange(value)
              }}
              helperText={errors.name}
              error={Boolean(errors.name)}
            />
          )}
        />
        <Controller
          name="slug"
          defaultValue={ticket?.slug || ''}
          control={props.control}
          rules={{
            required: 'Ticket Slug is required',
          }}
          render={({onChange, value}) => (
            <DescribedTextField
              title="Ticket Slug*"
              aria-label="ticket slug"
              description={"The value for the ticket's URL"}
              name="slug"
              required
              fullWidth
              disabled={submitting}
              value={value}
              onChange={(value) => {
                setSlugDirty(true)
                onChange(value)
              }}
              helperText={errors.slug}
              error={Boolean(errors.slug)}
            />
          )}
        />
        <Controller
          name="description"
          defaultValue={ticket?.description || ''}
          control={props.control}
          render={({onChange, value}) => (
            <DescribedTextArea
              title="Description"
              aria-label="ticket description"
              description="A short blurb to describe this Ticket to new Attendees"
              name="description"
              rows={3}
              fullWidth
              disabled={submitting}
              value={value}
              onChange={onChange}
              helperText={errors.description}
              error={Boolean(errors.description)}
            />
          )}
        />
        <Controller
          name="available_from"
          control={props.control}
          defaultValue={ticket?.available_from ?? null}
          render={({onChange, value}) => (
            <LocalizedDateTimePicker
              disabled={submitting}
              value={value}
              onChange={handleFromDate}
              fullWidth
              label="Available From"
              TextFieldComponent={(props: TextFieldProps) => (
                <DescribedTextField
                  aria-label="ticket available from"
                  description="Optional date/time when this ticket will start being available"
                  name="available_from"
                  fullWidth
                  disabled={submitting}
                  onChange={onChange}
                  value={props.value as string}
                  onClick={props.onClick}
                />
              )}
            />
          )}
        />
        <Controller
          name="available_to"
          control={props.control}
          defaultValue={ticket?.available_to ?? null}
          render={({onChange, value}) => (
            <LocalizedDateTimePicker
              disabled={submitting}
              value={value}
              onChange={handleToDate}
              fullWidth
              label="Available To"
              TextFieldComponent={(props: TextFieldProps) => (
                <DescribedTextField
                  aria-label="ticket available to"
                  description="Optional date/time when this ticket will no longer be available"
                  name="available_to"
                  fullWidth
                  disabled={submitting}
                  onChange={onChange}
                  value={props.value as string}
                  onClick={props.onClick}
                />
              )}
            />
          )}
        />
        <Controller
          name="tags"
          control={props.control}
          defaultValue={ticket?.tags || ''}
          render={({onChange, value}) => (
            <DescribedTagsInput
              value={value}
              title="Applied Tags"
              description="Any tags listed will be applied to the Attendee after a successful purchase of this ticket"
              fullWidth
              name="tags"
              aria-label="ticket tags"
              onChange={onChange}
              disabled={submitting}
            />
          )}
        />

        <Controller
          name="convert_kit_tag"
          defaultValue={{
            id: ticket?.convert_kit_tag_id,
            name: ticket?.convert_kit_tag_name,
          }}
          control={props.control}
          render={({value, onChange}) => (
            <DescribedConvertKitTagInput
              title="Convert Kit tag"
              description="Convert Kit tag info"
              fullWidth
              aria-label="Convert Kit tag"
              disabled={submitting}
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="ontraport_tag"
          defaultValue={{
            tag_id: ticket?.ontraport_tag_id,
            tag_name: ticket?.ontraport_tag_name,
          }}
          control={props.control}
          render={({value, onChange}) => (
            <DescribedOntraportTagInput
              title="Ontraport tag"
              description="Ontraport tag info"
              fullWidth
              aria-label="Ontraport tag"
              disabled={submitting}
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="active_campaign_tag"
          defaultValue={{
            tag: ticket?.active_campaign_tag_name,
            id: ticket?.active_campaign_tag_id,
          }}
          control={props.control}
          render={({value, onChange}) => (
            <DescribedActiveCampaignTagInput
              title="Actice Campaign tag"
              description="Actice Campaign tag info"
              fullWidth
              aria-label="Actice Campaign tag"
              disabled={submitting}
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="highlevel_tag"
          defaultValue={{
            name: ticket?.highlevel_tag_name,
          }}
          control={props.control}
          render={({value, onChange}) => (
            <DescribedHighlevelTagInput
              title="Highlevel tag"
              description="Highlevel tag info"
              fullWidth
              aria-label="ticket groups"
              disabled={submitting}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name="hubspot_tag"
          defaultValue={{
            property_value: ticket?.hubspot_tag_value,
            property_name: ticket?.hubspot_tag_name,
            property_label: ticket?.hubspot_tag_label,
            property_type: ticket?.hubspot_tag_type,
          }}
          control={props.control}
          render={({value, onChange}) => (
            <DescribedHubspotTagInput
              title="Hubspot tag"
              description="Hubspot tag info"
              fullWidth
              aria-label="hubspot tag"
              disabled={submitting}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name="infusionsoft_tag"
          defaultValue={{
            id: ticket?.infusionsoft_tag_id,
            name: ticket?.infusionsoft_tag_name,
          }}
          control={props.control}
          render={({value, onChange}) => (
            <DescribedInfusionsoftTagInput
              title="Keap tag"
              description="Keap teag info"
              fullWidth
              aria-label="ticket groups"
              disabled={submitting}
              value={value}
              onChange={onChange}
              autoSet
            />
          )}
        />

        <Controller
          name="mailchimp_tag"
          defaultValue={{
            name: ticket?.mailchimp_tag_name,
          }}
          control={props.control}
          render={({value, onChange}) => (
            <DescribedMailchimpTagInput
              title="Mailchimp tag"
              description="Mailchimp tag info"
              fullWidth
              aria-label="Mailchimp tag"
              disabled={submitting}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <DescribedGroupsInput
          title="Applied Groups"
          description="Any groups listed will be applied to the Attendee after a successful purchase of this ticket"
          fullWidth
          name="groups"
          aria-label="ticket groups"
          existingGroups={existingGroups ?? []}
          onChangeExistingGroups={(groups) =>
            onChangeExistingGroups && onChangeExistingGroups(groups)
          }
          newGroups={newGroups}
          onChangeNewGroups={onChangeNewGroups}
          disabled={submitting}
        />

        <Controller
          name="price"
          defaultValue={formatPrice(ticket?.price || 0)}
          control={props.control}
          rules={{
            required: 'Ticket Price is required',
          }}
          render={({onChange, value}) => (
            <DescribedTextField
              title="Ticket Price*"
              aria-label="ticket price"
              description={'The price an Attendee will pay for this ticket'}
              name="price"
              required
              fullWidth
              disabled={submitting}
              value={value}
              onChange={(value) => {
                onChange(value)
                formatTicketPrice(value)
              }}
              helperText={errors.price}
              error={Boolean(errors.price)}
            />
          )}
        />

        <Label>Collect Attendee Address?</Label>
        <Box>
          <TopDescriptionBox>
            <DescriptionText>
              Would you like to collect your attendee billing and/or shipping
              addresses? If so, toggle this on. Shipping address can be enabled
              if you wish, as can whether or not the address types are required.
            </DescriptionText>
          </TopDescriptionBox>
          <AddressContainer>
            <div></div>
            <CenterLabel>Collect</CenterLabel>
            <CenterLabel>Required</CenterLabel>
            <AddressLabel>Billing Address</AddressLabel>
            <CheckboxContainer>
              <Controller
                name={`billing_address_enabled`}
                control={props.control}
                defaultValue={ticket?.billing_address_enabled || false}
                render={({value, onChange}) => (
                  <StyledCheckBox
                    checked={value}
                    aria-label="toggle billing addresses"
                    onChange={onChange}
                    disabled={submitting}
                  />
                )}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <Visible when={Boolean(billingAddressEnabled)}>
                <Controller
                  name={`billing_address_required`}
                  control={props.control}
                  defaultValue={ticket?.billing_address_required || false}
                  render={({value, onChange}) => (
                    <StyledCheckBox
                      checked={value}
                      arial-label="toggle billing addresses required"
                      onChange={onChange}
                      disabled={submitting}
                    />
                  )}
                />
              </Visible>
            </CheckboxContainer>
            <AddressLabel>Shipping Address</AddressLabel>
            <CheckboxContainer>
              <Controller
                name={`shipping_address_enabled`}
                control={props.control}
                defaultValue={ticket?.shipping_address_enabled || false}
                render={({value, onChange}) => (
                  <StyledCheckBox
                    checked={value}
                    arial-label="toggle shipping addresses"
                    onChange={onChange}
                    disabled={submitting}
                  />
                )}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <Visible when={Boolean(shippingAddressEnabled)}>
                <Controller
                  name={`shipping_address_required`}
                  control={props.control}
                  defaultValue={ticket?.shipping_address_required || false}
                  render={({value, onChange}) => (
                    <StyledCheckBox
                      checked={value}
                      arial-label="toggle shipping addresses required"
                      onChange={onChange}
                      disabled={submitting}
                    />
                  )}
                />
              </Visible>
            </CheckboxContainer>
          </AddressContainer>
          <RightDescriptionBox>
            <DescriptionText>
              Would you like to collect your attendee billing and/or shipping
              addresses? If so, toggle this on. Shipping address can be enabled
              if you wish, as can whether or not the address types are required.
            </DescriptionText>
          </RightDescriptionBox>
        </Box>

        <Controller
          name={`phone_number_enabled`}
          control={props.control}
          defaultValue={ticket?.phone_number_enabled || false}
          render={({value, onChange}) => (
            <DescribedSwitch
              title="Collect Attendee Phone Number?"
              description="Would you like to collect your attendee's phone number?  If so, toggle this on."
              arial-label="toggle phone number"
              disabled={submitting}
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
            />
          )}
        />
        <Controller
          name="hidden"
          defaultValue={ticket ? ticket.hidden : false}
          control={props.control}
          render={({onChange, value}) => (
            <DescribedSwitch
              title="Hidden"
              description="When a ticket is hidden, it is only available via it's direct URL using the Ticket Slug and does not show up in the Event's ticket listing"
              aria-label="ticket hidden"
              name="hidden"
              disabled={submitting}
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
            />
          )}
        />
        <Controller
          name="active"
          defaultValue={ticket ? ticket.active : true}
          control={props.control}
          render={({onChange, value}) => (
            <DescribedSwitch
              title="Active"
              description="A ticket is only available for purchase if it is active"
              aria-label="ticket active"
              name="active"
              disabled={submitting}
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
            />
          )}
        />
      </form>
    </>
  )
}

const formTitle = (ticket: MarketplaceTicket | undefined) => {
  return ticket ? 'Modify Ticket' : 'Add New Ticket'
}

const formButtonText = (ticket: MarketplaceTicket | undefined) => {
  return ticket ? 'Save Ticket' : 'Create Ticket'
}

const StyledPageHeader = styled(PageHeader)<{hasError: boolean}>`
  margin-bottom: ${(props) =>
    props.hasError
      ? props.theme.spacing[9]
      : props.theme.spacing[21]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: ${(props) =>
      props.hasError
        ? props.theme.spacing[9]
        : props.theme.spacing[19]} !important;
  }
`

const StyledErrorAlert = styled(ErrorAlert)`
  margin-bottom: ${(props) => props.theme.spacing[11]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: ${(props) => props.theme.spacing[9]} !important;
  }
`

const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: 150px repeat(2, 100px);
  gap: 10px;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[8]};
  margin-top: ${(props) => props.theme.spacing[5]};
  width: 100%;
`

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledCheckBox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.spacing[7]};
`

const CenterLabel = styled(Label)`
  text-align: center;
  margin-right: ${(props) => props.theme.spacing[2]};
`

const AddressLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const TopDescriptionBox = styled.div`
  padding: 0;
  border: none;
  margin-left: 0;
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const RightDescriptionBox = styled(TopDescriptionBox)`
  display: none;
  flex: 1 0 40%;
  padding: ${(props) => `${props.theme.spacing[1]} ${props.theme.spacing[7]}`};
  border-left: 1px solid ${(props) => props.theme.colors.primary};
  margin-left: ${(props) => props.theme.spacing[27]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: inline;
  }
`

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[10]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    align-items: center;
    margin-bottom: ${(props) => props.theme.spacing[5]};
  }
`
