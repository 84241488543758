import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {useMutation, useQueryClient} from 'react-query'
import {useEvent} from 'Event/EventProvider'

export function useUpdateCustomPurchasePageTemplate(params: {client: Client}) {
  const {client} = params
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: {
      customPurchasePage: CustomPurchasePage
      updates: Record<string, any>
    }) => {
      const {customPurchasePage, updates} = data

      const url = marketplaceApi(
        `/purchase_pages/${customPurchasePage.id}/template`,
      )
      return client.put<CustomPurchasePage>(url, {
        template: updates,
      })
    },
    onSuccess: (updated) => {
      qc.setQueryData<CustomPurchasePage[]>(
        ['events', event.id, 'custom_purchase_pages'],
        (purchasePages) => {
          if (!purchasePages) {
            return []
          }

          return purchasePages.map((page) =>
            page.id === updated.id ? updated : page,
          )
        },
      )
    },
  })
}
