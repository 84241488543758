import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import React from 'react'
import {useEditMode, useSetEditMode} from 'Event/EditModeProvider'

export default function DefaultRightToolbarItems() {
  const isEditMode = useEditMode()

  const setIsEditMode = useSetEditMode()

  const toggle = () => setIsEditMode(!isEditMode)

  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={!isEditMode} onChange={toggle} color="primary" />
        }
        label="Preview"
      />
    </>
  )
}
