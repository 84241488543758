import React from 'react'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

export type OptionProps = {
  dark?: boolean
  value?: any
  selected?: any
  children?: string | number | React.ReactNode
  disabled?: boolean
  'aria-label'?: string
  type?: 'list' | 'checkbox'
  checked?: boolean
}

export default React.forwardRef<HTMLLIElement, OptionProps>((props, ref) => {
  if (props.type === 'checkbox') {
    return (
      <MenuItem key={props.value} value={props.value} {...props} ref={ref}>
        <Checkbox checked={props.checked} />
        <ListItemText primary={props.children} />
      </MenuItem>
    )
  }

  return <StyledMenuItem {...props} ref={ref} />
})

const StyledMenuItem = styled(MenuItem)``
