import React from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import Page from 'organization/Event/Page'
import Form, {
  CustomPurchasePageFormData,
} from 'organization/Marketplace/PurchasePageConfig/CustomPurchasePages/Form/Form'
import {CustomPurchasePageBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useParams} from 'react-router-dom'
import {useValidatedForm} from 'lib/form'
import {useListCustomPurchasePages} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-list-custom-purchase-pages'
import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'
import {
  UpdateCustomPurchasePageData,
  useUpdateCustomPurchasePage,
} from 'lib/marketplace-api/purchase-page/custom-purchase-page/use-update-custom-purchase-page'

interface ContentProps {
  custom_purchase_page: CustomPurchasePage
}

export default function EditCustomPurchasePageForm() {
  const eventRoutes = useEventRoutes()
  const {custom_purchase_pages: routeId} = useParams<{
    custom_purchase_pages: string
  }>()
  const customPurchasePageId = parseInt(routeId)

  const {data: custom_purchase_pages, isLoading} = useListCustomPurchasePages()

  const custom_purchase_page = custom_purchase_pages?.find(
    (custom_purchase_page) => custom_purchase_page.id === customPurchasePageId,
  )

  if (isLoading && !custom_purchase_page) {
    return null
  }

  if (!custom_purchase_page) {
    return <Redirect to={eventRoutes.marketplace.custom_purchase_pages.root} />
  }

  return <Content custom_purchase_page={custom_purchase_page} />
}

function Content(props: ContentProps) {
  const {custom_purchase_page} = props
  const {
    mutate: updateCustomPurchasePage,
    isLoading: isUpdatingCustomPurchasePage,
  } = useUpdateCustomPurchasePage({
    customPurchasePage: custom_purchase_page,
  })

  const history = useHistory()
  const {
    errors: formErrors,
    handleSubmit,
    control,
    setValue,
    responseError,
    setResponseError,
  } = useValidatedForm()

  const eventRoutes = useEventRoutes()

  const submit = (formData: CustomPurchasePageFormData) => {
    const payload: UpdateCustomPurchasePageData = {
      ...custom_purchase_page,
      ...formData,
    }

    updateCustomPurchasePage(payload, {
      onError: (error: any) => {
        setResponseError(error)
      },
      onSuccess: () => {
        history.push(eventRoutes.marketplace.custom_purchase_pages.root)
      },
    })
  }

  return (
    <CustomPurchasePageBreadcrumbs page={custom_purchase_page.name}>
      <Page>
        <Form
          control={control}
          formErrors={formErrors}
          onSubmit={handleSubmit(submit)}
          responseError={responseError}
          setValue={setValue}
          submitting={isUpdatingCustomPurchasePage}
          customPage={custom_purchase_page}
        />
      </Page>
    </CustomPurchasePageBreadcrumbs>
  )
}
