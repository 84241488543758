import {isAttendee, useEventAuth} from 'Event/auth'
import {replace, parseVariables} from 'lib/template'
import {time, today} from 'lib/date-time'
import {useCallback} from 'react'
import {Score, usePoints} from 'Event/PointsProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useEvent} from 'Event/EventProvider'

/**
 * Dynamically replace text with data from the currently authenticated
 * Attendee. Variables are defined using {{double braces}}.
 *
 * @returns
 */

export function useWithAttendeeData() {
  const {user} = useEventAuth()
  const {
    event: {name: eventName},
  } = useEvent()

  return useCallback(
    (text: string) => {
      const hasText = Boolean(text)

      // If editing event (ie. host) we won't have an attendee user
      if (!hasText || !isAttendee(user)) {
        return text
      }

      /**
       * Known variables that we'll try to replace first. Anything
       * not defined here we'll assume are custom group
       * keys.
       */

      const baseAttributes = {
        // For backwards compatibility. At some point in the future we can remove
        // this "spaced" replacement, once we feel content that has been created
        // with the "spaced" version is no longer relevant.
        'first name': user.first_name,
        'last name': user.last_name,
        // Consistent name formats.
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        today: today(),
        time: time(),
        event: eventName,
        login_url: user.login_url,
      }

      /**
       * Check if a key is a base attribute
       */

      const isBaseAttribute = (key: string) =>
        Object.keys(baseAttributes).includes(key)

      /**
       * Parse custom variables keys within a text
       */

      const customVariables = (text: string) => {
        return parseVariables(text).filter((v) => !isBaseAttribute(v))
      }

      /**
       * The result with replaced variables
       */

      let result = text

      /**
       * Replace base attributes
       */

      for (const [key, value] of Object.entries(baseAttributes)) {
        result = replace(key, value, result)
      }

      /**
       * Replace group values
       */

      for (const key of customVariables(result)) {
        const groupKey = Object.keys(user.groups).find(
          (userGroupKey) => userGroupKey.toLowerCase() === key.toLowerCase(),
        )

        if (!groupKey) {
          continue
        }

        const value = user.groups[groupKey]
        if (!value) {
          continue
        }

        result = replace(key, String(value), result)
      }

      return result
    },
    [user, eventName],
  )
}

/**
 *
 * @returns
 */
export function useWithPoints() {
  const {score} = usePoints()
  return useWithStaticPoints(score)
}

export function useWithStaticPoints(score: Score) {
  const template = useTemplate()
  const {points_unit} = template

  return useCallback(
    (text: string) => {
      if (!text) {
        return text
      }

      const variables = {
        leaderboard_points: String(score.points),
        leaderboard_position: String(score.position),
        points_unit,
      }

      let result = text

      for (const [key, value] of Object.entries(variables)) {
        result = replace(key, value, result)
      }

      return result
    },
    [score, points_unit],
  )
}
