import {useEvent} from 'Event/EventProvider'
import {CustomPurchasePage} from 'Event/Marketplace/custom-purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

export interface CreateCustomPageData {
  name: string
  slug: string
}

export function useCreateCustomPurchasePage() {
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: CreateCustomPageData) => {
      const url = marketplaceApi(`/events/${event.id}/purchase_page`)
      return client!.post<CustomPurchasePage>(url, data)
    },
    onSuccess: (customPage) => {
      qc.setQueryData<CustomPurchasePage[]>(
        ['events', event.id, 'custom_purchase_pages'],
        (customPages) => {
          if (!customPages) {
            return [customPage]
          }

          return [...customPages, customPage]
        },
      )
    },
  })
}
