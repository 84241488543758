import React, {createContext, useContext, useState, ReactNode} from 'react'

interface DragContextType {
  draggedBlockId: string | null
  draggedSectionId: string | null
  draggedBlockPath: string | null
  isDragging: boolean
  setDraggedBlock: (
    blockId: string | null,
    sectionId: string | null,
    path: string | null,
  ) => void
  setIsDragging: (isDragging: boolean) => void
  resetDrag: () => void
}

const DragContext = createContext<DragContextType | undefined>(undefined)

export const DragProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [draggedBlockId, setDraggedBlockId] = useState<string | null>(null)
  const [draggedSectionId, setDraggedSectionId] = useState<string | null>(null)
  const [draggedBlockPath, setDraggedBlockPath] = useState<string | null>(null)
  const [isDragging, setIsDragging] = useState(false)

  const setDraggedBlock = (
    blockId: string | null,
    sectionId: string | null,
    path: string | null,
  ) => {
    setDraggedBlockId(blockId)
    setDraggedSectionId(sectionId)
    setDraggedBlockPath(path)
  }

  const resetDrag = () => {
    setDraggedBlockId(null)
    setDraggedSectionId(null)
    setDraggedBlockPath(null)
    setIsDragging(false)
  }

  return (
    <DragContext.Provider
      value={{
        draggedBlockId,
        draggedSectionId,
        draggedBlockPath,
        isDragging,
        setDraggedBlock,
        setIsDragging,
        resetDrag,
      }}
    >
      {children}
    </DragContext.Provider>
  )
}

export const useDrag = () => {
  const context = useContext(DragContext)
  if (!context) {
    throw new Error('useDrag must be used within a DragProvider')
  }
  return context
}
