import React, {useState} from 'react'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {onChangeCheckedHandler} from 'lib/dom'
import Header from 'Event/Reports/Header'
import Footer from 'Event/Reports/Footer'
import Body from 'Event/Reports/Body'
import FormsConfig from 'Event/Reports/Form/FormsConfig'
import {useReportsConfig} from 'organization/Event/Reports'
import styled from 'styled-components'
import grey from '@material-ui/core/colors/grey'

export const DEFAULT_TITLE = ''
export const DEFAULT_TITLE_COLOR = '#000000'
export const DEFAULT_FOOTER_TEXT = ''
export const DEFAULT_FOOTER_BACKGROUND_COLOR = '#FFFFFF'
export const DEFAULT_FOOTER_TEXT_COLOR = '#000000'
export const DEFAULT_BODY_BACKGROUND_COLOR = '#FFFFFF'
export const DEFAULT_BODY_BACKGROUND_OPACITY = 0

export default function ReportConfig() {
  const [isPreview, setIsPreview] = useState(false)
  const {report, update, processing} = useReportsConfig()

  const togglePreview = () => setIsPreview(!isPreview)

  const setIsActive = (is_active: boolean) => {
    update({
      is_active,
    })
  }

  return (
    <>
      <Box>
        <RightItems>
          <FormControlLabel
            control={
              <Switch
                checked={report.is_active}
                onChange={onChangeCheckedHandler(setIsActive)}
                color="primary"
                disabled={processing}
              />
            }
            label="Active"
          />
        </RightItems>
        <FormControlLabel
          control={<Switch onChange={togglePreview} color="primary" />}
          label="Preview"
        />
      </Box>
      <Header isPreview={isPreview} />
      <Body isPreview={isPreview} />
      <FormsConfig isPreview={isPreview} />
      <Footer isPreview={isPreview} />
    </>
  )
}

const Box = styled.div`
  position: sticky;
  top: 0;
  height: 50px;
  border-bottom: 1px solid ${grey[300]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  background: #ffffff;
  z-index: 50;
`
const RightItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
