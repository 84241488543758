import React, {forwardRef} from 'react'
import {rgba, isValidColor} from './lib/color'
import {backgroundRepeat, backgroundSize} from './lib/background'
import {getFontStyles} from './lib/font'
import {PageTemplate} from './page-template'

interface PurchasePageContainerProps {
  template: PageTemplate
  children: React.ReactNode
  className?: string
}

const Container = forwardRef<HTMLDivElement, PurchasePageContainerProps>(
  (props, ref) => {
    const {template, children, className} = props
    const styles = getStyles(template)

    return (
      <div ref={ref} className={className} style={styles}>
        {children}
      </div>
    )
  },
)

export default Container

function getStyles(template: PageTemplate): React.CSSProperties {
  return {
    backgroundImage: template.background.image
      ? `url(${template.background.image})`
      : undefined,
    backgroundColor: isValidColor(template.background.color)
      ? rgba(template.background.color, template.background.opacity / 100)
      : undefined,
    backgroundBlendMode: 'multiply',
    backgroundAttachment:
      template.background.repeat === 'full-center-parallax'
        ? 'fixed'
        : undefined,
    backgroundPosition:
      template.background.repeat === 'full-center-parallax'
        ? 'center'
        : template.background.position,
    backgroundSize: backgroundSize(template.background.repeat),
    backgroundRepeat: backgroundRepeat(template.background.repeat),
    paddingTop: `${template.padding.top}px`,
    paddingRight: `${template.padding.right}px`,
    paddingBottom: `${template.padding.bottom}px`,
    paddingLeft: `${template.padding.left}px`,
    width: '100%',
    height: '100%',
    color: template.typography.textColor,
    ...getFontStyles(template.typography.font),
  }
}
