import Checkbox from 'lib/ui/Checkbox'
import React, {useState} from 'react'
import styled from 'styled-components'
import {useLanguages} from 'Event/LanguageProvider'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import {useTemplate} from 'Event/TemplateProvider'

interface TargetLanguagesSelectorProps {
  value: string[]
  onChange: (languages: string[]) => void
}

export default function TargetLanguagesSelector(
  props: TargetLanguagesSelectorProps,
) {
  const {value, onChange} = props
  const languages = useLanguages()
  const [isEnabled, setIsEnabled] = useState(value.length > 0)
  const {localization} = useTemplate()

  const availableLanguages = languages || []

  if (!localization?.translationsEnabled) {
    return null
  }

  return (
    <div>
      <StyledSwitch
        checked={isEnabled}
        onChange={onChangeCheckedHandler((enabled) => {
          onChange([])
          setIsEnabled(enabled)
        })}
        label="Only Show for Selected Languages"
        labelPlacement="end"
      />
      {isEnabled && (
        <LanguageList>
          {availableLanguages.map((language) => (
            <Checkbox
              key={language.name}
              checked={value.includes(language.name)}
              onChange={(checked) => {
                if (checked) {
                  onChange([...value, language.name])
                } else {
                  onChange(value.filter((l) => l !== language.name))
                }
              }}
              label={language.name}
            />
          ))}
        </LanguageList>
      )}
    </div>
  )
}

const StyledSwitch = styled(Switch)`
  padding-left: 0;
`

const LanguageList = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 20px;
`
