import React from 'react'
import styled from 'styled-components'
import ButtonBase from 'lib/ui/Button/CustomButton'
import {Column} from 'lib/ui/layout'
import {HasRules} from 'Event/attendee-rules'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import {usePoints} from 'Event/PointsProvider'
import {Publishable} from 'Event/Dashboard/editor/views/Published'
import {
  InfusionsoftTag,
  useAddTag as useAddInfusionsoftTag,
} from 'Event/infusionsoft'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {areaRoutes} from 'Event/Routes'
import {useAttendeeVariables} from 'Event'
import {Icon, IconProps} from 'lib/fontawesome/Icon'
import ImageEntryUpload from 'Event/Dashboard/components/NavButton/ImageEntryUpload'
import {MailchimpTag, useAddTag as useAddMailchimpTag} from 'Event/mailchimp'
import {ZapierTag, useAddTag as useAddZapierTag} from 'Event/zapier'
import {HubspotTag, useAddTag as useAddHubspotTag} from 'Event/hubspot'
import {Ordered} from 'lib/list'
import {Font, fontStyles, useLoadFont} from 'lib/FontSelect'
import {HasSchedule} from 'lib/ui/layout/Scheduled'
import FormButton from 'Event/Dashboard/components/NavButton/FormButton'
import CertificateButton from 'Event/Dashboard/components/NavButton/CertificateButton'
import {
  ButtonWebhook,
  useFireWebhook,
} from 'organization/Event/WebhooksProvider'
import {useEditMode} from 'Event/EditModeProvider'
import {useHistory} from 'react-router'
import ConfirmedButton from 'Event/Dashboard/components/NavButton/ConfirmedButton'
import {useEvent} from 'Event/EventProvider'
import {
  ActiveCampaignTag,
  useAddTag as useActiveCampaignTag,
} from 'Event/activeCampaign'
import {HighLevelTag, useAddTag as useAddHighLevelTag} from 'Event/highlevel'
import {
  ConvertKitTag,
  useAddTag as useAddConvertKitTag,
} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import {
  OntraportTag,
  useAddTag as useAddOntraportTag,
} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'

export const NAV_BUTTON = 'NAV_BUTTON'

export interface NavButtonAreaConfig {
  areaId: string | null
}

export interface NavButtonProps
  extends HasRules,
    Publishable,
    Ordered,
    HasSchedule {
  text: string
  textSecond?: string | null
  typographySecond?: {
    font: Font | null
    fontSize: number
    textColor: string
    hoverTextColor: string
  }
  link: string
  backgroundColor?: string
  textColor?: string
  hoverTextColor?: string
  newTab?: boolean
  'aria-label'?: string
  className?: string
  hoverBackgroundColor?: string
  borderRadius?: number
  borderWidth?: number
  borderColor?: string
  height?: number
  hoverBorderColor?: string
  isAreaButton: boolean
  isFormButton: boolean
  isCertificateButton: boolean
  isImageUpload: boolean
  confirmRequired?: boolean
  revealDelaySecs?: number | null
  confirmDescription?: string
  confirmButtonLabel?: string
  confirmButtonFontSize?: number
  confirmButtonBackgroundColor?: string
  confirmButtonTextColor?: string
  areaId: string | null
  formId: number | null
  certificateId: string | null
  certificate?: {
    emailSentBackgroundColor: string | null
    emailSentTextColor: string | null
    emailSentText: string | null
    generatingText: string | null
    generatingImage: string | null
  }
  actionId: string | null
  infusionsoftTag: InfusionsoftTag | null
  page?: string | null
  fontSize?: number
  padding?: number
  width?: number
  icon?: string | null
  iconAlign?: 'left' | 'right'
  iconSize?: number
  iconStacked?: boolean
  mailchimpTag: MailchimpTag | null
  zapierTag: ZapierTag | null
  highLevelTag: HighLevelTag | null
  hubspotTag: HubspotTag | null
  activeCampaignTag: ActiveCampaignTag | null
  convertKitTag: ConvertKitTag | null
  ontraportTag: OntraportTag | null
  image?: string
  font?: Font | null
  disableHover?: boolean
  onClick?: (() => void) | null
  webhook: ButtonWebhook | null
  ButtonProps?: {
    Content: React.FC<ButtonBaseProps> | null
  }
}

export type NavButtonWithSize = NavButtonProps & {
  size: Column
  newLine?: boolean
}
export const DEFAULT_BUTTON_HEIGHT = 64
export const DEFAULT_FONT_SIZE = 29
export const DEFAULT_ICON_COLOR = '#ffffff'
export const DEFAULT_ICON_ALIGN = 'left'

export default function NavButton(props: NavButtonProps) {
  const {
    newTab,
    isAreaButton,
    isImageUpload,
    isFormButton,
    isCertificateButton,
    page,
    confirmRequired,
    link,
  } = props
  const submitAction = useSubmitAction(props.actionId)
  const addInfusionsoftTag = useAddInfusionsoftTag()
  const addMailchimpTag = useAddMailchimpTag()
  const addZapierTag = useAddZapierTag()
  const addHubspotTag = useAddHubspotTag()
  const addActiveCampaignTag = useActiveCampaignTag()
  const addHighLevelTag = useAddHighLevelTag()
  const addConvertKitTag = useAddConvertKitTag()
  const addOntraportTag = useAddOntraportTag()
  const fireWebhook = useFireWebhook()
  const isEditMode = useEditMode()
  const history = useHistory()
  const v = useAttendeeVariables()

  const relativePath = useParseRelativePath(v(props.link))
  const isElementLink = v(props.link).startsWith('#')
  const isJavascriptLink = v(props.link).startsWith('javascript')

  const handleClicked = () => {
    submitAction()

    if (props.infusionsoftTag) {
      addInfusionsoftTag(props.infusionsoftTag)
    }

    if (props.mailchimpTag) {
      addMailchimpTag(props.mailchimpTag)
    }

    if (props.zapierTag) {
      addZapierTag(props.zapierTag)
    }

    if (props.hubspotTag) {
      addHubspotTag(props.hubspotTag)
    }

    if (props.activeCampaignTag) {
      addActiveCampaignTag(props.activeCampaignTag)
    }

    if (props.highLevelTag) {
      addHighLevelTag(props.highLevelTag)
    }

    if (props.convertKitTag) {
      addConvertKitTag(props.convertKitTag)
    }

    if (props.ontraportTag) {
      addOntraportTag(props.ontraportTag)
    }

    if (props.webhook) {
      fireWebhook(props.webhook)
    }
  }

  // Button may have been removed, but a property was set
  // after the fact. This would show up as an empty
  // button so let's just render null.
  const wasRemoved = Object.keys(props).length === 1
  if (wasRemoved) {
    return null
  }

  if (isImageUpload) {
    return (
      <FlexBox>
        <ImageEntryUpload {...props} />
      </FlexBox>
    )
  }

  if (isAreaButton && props.areaId) {
    return (
      <JoinAreaButton {...props} areaId={props.areaId} onJoin={handleClicked} />
    )
  }

  if (isFormButton && props.formId) {
    return (
      <FlexBox>
        <FormButton {...props} />
      </FlexBox>
    )
  }

  if (isCertificateButton && props.certificateId) {
    return (
      <FlexBox>
        <CertificateButton {...props} />
      </FlexBox>
    )
  }

  if (confirmRequired) {
    const target = page || v(link)
    return (
      <ConfirmedButton
        {...props}
        onConfirm={() => {
          handleClicked()
          history.push(target)
        }}
      />
    )
  }

  if (page) {
    return (
      <StyledRelativeLink
        to={page}
        disableStyles
        aria-label={props['aria-label']}
        newTab={props.newTab}
        onClick={handleClicked}
        disabled={isEditMode}
      >
        <Button {...props} />
      </StyledRelativeLink>
    )
  }

  // If the user supplied a url that's still a part of obv.io, we'll
  // treat it as a relative link
  if (relativePath) {
    return (
      <StyledRelativeLink
        to={relativePath}
        disableStyles
        aria-label={props['aria-label']}
        newTab={newTab}
        onClick={handleClicked}
        disabled={isEditMode}
      >
        <Button {...props} />
      </StyledRelativeLink>
    )
  }

  // If this button is a link to an #element, or `javascript:` then we'll disable
  // any new tab settings, and also always enable the link.
  if (isElementLink || isJavascriptLink) {
    return (
      <StyledAbsoluteLink
        to={v(props.link)}
        disableStyles
        aria-label={props['aria-label']}
        newTab={false}
        onClick={handleClicked}
      >
        <Button {...props} />
      </StyledAbsoluteLink>
    )
  }

  return (
    <StyledAbsoluteLink
      to={v(props.link)}
      disableStyles
      aria-label={props['aria-label']}
      newTab={true}
      onClick={handleClicked}
      disabled={isEditMode}
    >
      <Button {...props} />
    </StyledAbsoluteLink>
  )
}

function useSubmitAction(actionKey: NavButtonProps['actionId']) {
  const {submit} = usePoints()

  return () => {
    if (!actionKey) {
      return
    }

    submit(actionKey)
  }
}

function JoinAreaButton(
  props: NavButtonProps & {
    areaId: string
    onJoin: () => void
  },
) {
  const {areaId, onJoin, confirmRequired} = props
  const joinLink = areaRoutes(areaId).root
  const isEditMode = useEditMode()
  const history = useHistory()

  if (confirmRequired) {
    return (
      <ConfirmedButton
        {...props}
        onConfirm={() => {
          onJoin()
          history.push(joinLink)
        }}
      />
    )
  }

  if (isEditMode) {
    return (
      <StyledRelativeLink disabled to={joinLink} newTab disableStyles>
        <Button {...props} />
      </StyledRelativeLink>
    )
  }

  return (
    <StyledRelativeLink to={joinLink} newTab disableStyles>
      <Button {...props} onClick={props.onJoin} />
    </StyledRelativeLink>
  )
}

/**
 * Button props without all the action & side-effect triggers. Only what's required
 * to render a button.
 */
export type ButtonBaseProps = {
  disabled?: boolean
  isPending?: boolean
  type?: 'submit' | 'button' | 'reset'
} & Omit<
  NavButtonProps,
  | 'link'
  | 'isVisible'
  | 'isAreaButton'
  | 'isFormButton'
  | 'isImageUpload'
  | 'isCertificateButton'
  | 'areaId'
  | 'formId'
  | 'actionId'
  | 'certificateId'
  | 'certificate'
  | 'infusionsoftTag'
  | 'mailchimpTag'
  | 'zapierTag'
  | 'hubspotTag'
  | 'highLevelTag'
  | 'activeCampaignTag'
  | 'convertKitTag'
  | 'ontraportTag'
  | 'webhook'
>

export function Button(props: ButtonBaseProps) {
  const ContentComponent = props.ButtonProps?.Content || Content
  const opacity = props.isPending ? 0.8 : 1
  const fontSize = props.fontSize || DEFAULT_FONT_SIZE

  // Remove padding for image buttons, so that the image goes all
  // the way to the edges. This assumes a button can NOT include
  // both text, and an image.
  const textButtonPadding = props.padding ? `${props.padding}px` : '15px 30px'
  const padding = props.image ? 0 : textButtonPadding

  // If there is no custom hover styles, we'll keep the default
  // of lightening the opacity.
  const hoverOpacity = props.disableHover ? 0.8 : 1

  useLoadFont(props.font)

  return (
    <StyledButton
      disabled={props.disabled}
      fullWidth
      textTransform="uppercase"
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      hoverTextColor={props.hoverTextColor}
      className={`${props.className || ''} nav-button`}
      hoverBackgroundColor={props.hoverBackgroundColor}
      disableHover={props.disableHover}
      borderRadius={props.borderRadius}
      borderWidth={props.borderWidth}
      borderColor={props.borderColor}
      hoverBorderColor={props.hoverBorderColor}
      hoverOpacity={hoverOpacity}
      minHeight={props.height}
      onClick={props.onClick}
      opacity={opacity}
      padding={padding}
      width={props.width}
      fontSize={fontSize}
      font={props.font}
      type={props.type}
      secondHoverTextColor={props.typographySecond?.hoverTextColor}
    >
      <ContentComponent {...props} />
    </StyledButton>
  )
}

function Content(props: ButtonBaseProps) {
  const {iconStacked, icon, image, text, textSecond} = props
  const iconSize = props.iconSize || props.fontSize || DEFAULT_FONT_SIZE
  const iconColor = props.textColor || DEFAULT_ICON_COLOR
  const v = useAttendeeVariables()

  const iconAlign = props.iconAlign || DEFAULT_ICON_ALIGN
  const fontSize = props.typographySecond?.fontSize || DEFAULT_FONT_SIZE
  const font = props.typographySecond?.font ?? null
  useLoadFont(font)

  if (image) {
    return <Image image={image} />
  }

  return (
    <>
      <FirstRow iconAlign={iconAlign}>
        <ButtonIcon
          stacked={iconStacked}
          iconClass={icon}
          color={iconColor}
          iconSize={iconSize}
        />
        {v(text)}
      </FirstRow>
      <SecondRow
        fontSize={fontSize}
        font={font}
        className="secondText"
        textColor={props.typographySecond?.textColor}
      >
        {v(textSecond ?? '')}
      </SecondRow>
    </>
  )
}

export const FirstRow = styled.div<{
  iconAlign: 'left' | 'right'
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.iconAlign === 'left' ? 'row' : 'row-reverse'};
`

export const SecondRow = styled.div<{
  fontSize: number
  font: Font | null
  textColor: string | undefined
}>`
  ${(props) => fontStyles(props.font)}
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.textColor};
`

export function ButtonIcon(
  props: IconProps & {stacked?: boolean; iconSize?: number},
) {
  if (!props.iconClass) {
    return null
  }

  const {stacked, iconClass, color, iconSize} = props

  return (
    <IconBox stacked={stacked} iconSize={iconSize}>
      <StyledIcon iconClass={iconClass} color={color} iconSize={iconSize} />
    </IconBox>
  )
}

const StyledAbsoluteLink = styled(AbsoluteLink)`
  display: flex;
  justify-content: center;
`

const StyledRelativeLink = styled(RelativeLink)`
  display: flex;
  justify-content: center;
`

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledButton = styled(
  ({padding: _padding, hoverOpacity: _hoverOpacity, ...otherProps}) => (
    <ButtonBase {...otherProps} />
  ),
)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => fontStyles(props.font)}
  padding: ${(props) => props.padding}!important;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  flex-direction: column;

  &:hover {
    border-color: ${(props) => props.hoverBorderColor};
    opacity: ${(props) => props.hoverOpacity};
    div i {
      color: ${(props) => props.hoverTextColor};
    }
  }

  &:hover .secondText {
    color: ${(props) => props.secondHoverTextColor};
  }
`

export const IconBox = styled.div<{
  stacked?: boolean
  iconSize?: number
}>`
  margin-right: ${(props) => (props.stacked ? 0 : props.theme.spacing[4])};
  margin-left ${(props) => (props.stacked ? 0 : props.theme.spacing[4])};
  margin-bottom: ${(props) => (props.stacked ? props.theme.spacing[2] : 0)};
  display: ${(props) => (props.stacked ? 'block' : 'inline-block')} !important;
  ${(props) => (props.iconSize ? `width: ${props.iconSize}px;` : '')}
  ${(props) => (props.iconSize ? `height: ${props.iconSize}px;` : '')}
`

const StyledIcon = styled(
  (
    props: IconProps & {
      iconSize?: number
    },
  ) => {
    const {iconSize: _, ...otherProps} = props
    return <Icon {...otherProps} />
  },
)`
  font-size: ${(props) => props.iconSize}px !important;
  width: ${(props) => props.iconSize}px;
  height: ${(props) => props.iconSize}px;
`

export function Image(props: {image?: string}) {
  const {image} = props

  if (!image) {
    return null
  }

  return <StyledImage src={image} />
}

/**
 * Attempts to parse a relative path from a url IF the url is
 * an obv.io url.
 * i.e. https://event.obv.io/leaderboard -> /leaderboard
 * @param url
 * @returns
 */
export function useParseRelativePath(url: string) {
  const isExternalLink = useIsExternalLinkUrl(url)
  if (isExternalLink) {
    return null
  }

  try {
    const {pathname, search} = new URL(url)
    return `${pathname}${search}`
  } catch {
    return null
  }
}

export function useIsExternalLinkUrl(url: string) {
  const {url: eventUrl} = useEvent()

  const baseUrl = new URL(eventUrl)

  try {
    const compareUrl = new URL(url)
    return baseUrl.host !== compareUrl.host
  } catch {
    // If url is not a valid url we'll just return false here rather
    // than crash the app.
    return false
  }
}

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`
