import React from 'react'
import Button from 'lib/ui/Button'
import {now} from 'lib/date-time'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {generateHashId} from 'lib/crypto/hash'

export default function AddAgendaButton(props: {
  className?: string
  sectionId: string
  nextPosition: number
}) {
  const {sectionId, nextPosition} = props

  const save = useTownhallUpdate()

  const addAgenda = () => {
    generateHashId([
      'townhall_',
      'agenda_item',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      save({
        dashboardSections: {
          [sectionId]: {
            items: {
              [id]: {
                text: 'Event',
                description: '',
                startDate: now(),
                endDate: now(),
                link: null,
                isVisible: true,
                speakers: [],
                position: nextPosition,
              },
            },
          },
        },
      })
    })
  }

  return (
    <Button
      size="large"
      variant="outlined"
      color="secondary"
      fullWidth
      aria-label="add agenda event"
      onClick={addAgenda}
      className={props.className}
    >
      Add Agenda Event
    </Button>
  )
}
